const state = {
  vendorId: '',
  vendorName: '',
  businessModel: '',
  vendorCheck: '',
  storeId: '',
  accountId: '',
  reservationId: '',
  storeCheck: '',
  isEdit:false,
}
const getters = {
  getVendorId: state => state.vendorId,
  getVendorName: state => state.vendorName,
  getBusinessModel: state => state.businessModel,
  getVendorCheck: state => state.vendorCheck,
  getStoreId: state => state.storeId,
  getAccountId: state => state.accountId,
  getReservationId: state => state.reservationId,
  getStoreCheck: state => state.storeCheck,
  isEdit:state=>state.isEdit
}

const mutations = {
  updateParams: (state, { key, value }) => (state[key] = value)
}
const actions = {}

export default {
  namespaced: true,
  state,
  getters,
  mutations,
  actions
}
