<template>
  <!-- 		 

		 -->
  <div
    :class="{ dragOverStyle: dragOverStatus, drag: dragStatus }"
    class="adornDrag"
    draggable="true"
    @dragstart="dragStart($event)"
    @dragenter="dragenter($event)"
    @dragover="dragover($event)"
    @dragleave="dragleave($event)"
    @drop="dragDrop($event)"
    @drag="drag($event)"
    @dragend="dragend($event)"
  >
    <slot></slot>
  </div>
</template>
<script>
import { log } from '@/util/log'
export default {
  name: '',
  props: {
    index: {
      type: Number,
      default: -1
    },
    data: {
      type: Array,
      default: []
    }
  },
  data() {
    return {
      dragOverStatus: false,
      dragStatus: false
    }
  },
  created() {},
  methods: {
    dragStart(e) {
      this.clearData(e)
      e.dataTransfer.setData('text', this.index)
    },
    drag(e) {
      this.dragStatus = true
    },
    dragend(e) {
      this.dragStatus = false
    },

    dragenter(e) {
      console.log('enter' + this.index)
    },
    dragover(e) {
      e.preventDefault()
      this.dragOverStatus = true
    },
    dragleave(e) {
      this.dragOverStatus = false
    },
    dragDrop(e) {
      e.preventDefault()
      let startIndex = e.dataTransfer.getData('text')
      let stopIndex = this.index
      this.sort(startIndex, stopIndex)
      this.dragOverStatus = false
    },

    clearData(e) {
      e.dataTransfer.clearData()
    },
    sort(start, stop) {
      let dragitem = this.data[start]
      this.data.splice(start, 1)
      this.data.splice(stop, 0, dragitem)
    }
  }
}
</script>
<style lang='less' scoped>
.drag {
  opacity: 0.5;
}
.dragOverStyle {
  border: 1px solid rgba(187, 168, 168, 0.5);
}
</style>