const state = {
  currentId: 0
}
const mutations = {
  updateCurId(state, payload) {
    console.log('更新id')
    console.log(payload)
    state.currentId = payload
  }
}

export default {
  namespaced: true,
  state,
  mutations
}
