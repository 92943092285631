import http from '@/plugin/axios'
import $api from '@/plugin/api'
import { Message } from 'element-ui'
import store from '@/plugin/store'
const state = {
  cache: {
    productId: '',
    productName: '',
    productDescription: '',
    productKeyword: '',
    productUnit: '',
    packageHtml: '',
    purchaseInstructions: '',
    productDetailContent: '',
    allowUpdateAll: true,
    businessModel: 0,
    syncWriteOffTime: 0, // 编辑核销的时候确认0-否，1-是
    // 使用说明
    useTips: '',
    // 销售类型，1-普通商品、2-导码商品、 3-无码商品、 4-限时商品
    saleType: 1,
    // 每人限购数量 0为不限购数量
    buyLimit: 0,
    // 海报二维码跳转方式，1.公众号 2.小程序
    posterQrCodeSet: 1,
    onSaleNotify: 1,
    posterTemplateId: '',
    // 海报模板编号
    posterTemplateNo: 1,
    // 是否弹出购买须知协议 0关闭 1开启
    switchShowPurchaseInstructions: 1,
    // 显示分段库存开关 0关闭 1开启
    switchSectionStock: 0,
    // 商品上架开关 0->下架；1->上架
    switchPublish: 0,
    // 退款类型设置，1.不支持退款 2.支持退款 3.过期不退款，现在默认是3 这个字段为预留字段
    refundType: 2,

    // 退款协议是否显示，默认1显示
    switchRefundAgreement: 1,
    cooperationTypeList: [],
    // 虚拟浏览基础数据，新的浏览人数将在此基础上进行叠加
    baseVirtualViewCount: 0,
    // 商品关联销售员集合
    productSalesmanList: [],
    // 商品推广信息集合
    productPromotionList: [],
    // 商品素材集合
    productMaterialsList: [],
    //分享文案
    shareContent: '',
    ffffffffff: 0,
    //分享图片列表
    shareImageList: [],
    // 服务说明id集合
    serviceDescriptionIds: [],
    // 商品分类id集合
    productCategoryIds: [],
    skuList: [],
    switchExternal: 0, //是否关联券 1=是，0=否
    externalTableId: '', //关联外部券的id
    couponId: '', //券id
    oaIssueId: '',
    oaCommodityId: ''
  },
  businessInfo: {
    id: '',
    name: '',
    logo: '',
    businessModel: ''
  },
  productCategoryInfo: {
    main: '',
    sub: []
  },
  productStoreList: [],
  writeOffTimeEnd: 0,
  hasSubMerchant: true, //如果是没有绑定的就是false
  pickedServiceTagList: [],
  saleDateRange: [],
  writeOffDateRange: [],
  storeList: [],
  loading: false,
  saleOptions: [
    {
      value: 0,
      label: '全部'
    },
    {
      value: 1,
      label: '出售中'
    },
    {
      value: 2,
      label: '待上架'
    },
    {
      value: 3,
      label: '已下架'
    },
    {
      value: 4,
      label: '即将下架（距离下架7天内）'
    },
    {
      value: 5,
      label: '即将过期（距离过期7天内）'
    },
    {
      value: 6,
      label: '售罄'
    },
    {
      value: 7,
      label: '已过期'
    },
    { value: 8, label: '库存预警' }
  ],
  currentSkuIndex: 0,
  commodityData: {
    package: [],
    tagList: [],
    disableTime: '',
    _id: ''
  }
}
const getters = {
  commodityData: state => state.commodityData,
  isShowCommodityPackage: state =>
    state.commodityData && state.commodityData.package && state.commodityData.package.length,
  oaIssueId: state => state.cache.oaIssueId,
  oaCommodityId: state => state.cache.oaCommodityId,
  saleType: state => state.cache.saleType,
  saleOptions: state => state.saleOptions,
  colorList: state => state.colorList,
  cache: state => state.cache,
  productCategoryInfo: state => state.productCategoryInfo,
  pickedServiceTagList: state => state.pickedServiceTagList,
  businessInfo: state => state.businessInfo,
  hasSubMerchant: state => state.hasSubMerchant,
  productImage: state => state.cache.productMaterialsList.map(i => i.fileUrl),
  shareImageList: state => state.cache.shareImageList,
  storeList: state => state.storeList,
  saleDateRange: state => state.saleDateRange,
  allowUpdateAll: state => state.cache.allowUpdateAll,
  writeOffDateRange: state => state.writeOffDateRange,
  writeOffTimeEnd: state => state.writeOffTimeEnd,
  productStoreList: state => state.productStoreList,
  loading: state => state.loading,
  // 组合生成用于新增提交表单的数据
  cacheDataForSubmit: state => {
    let exist = {}
    let arr = state.productStoreList.map(i => {
      let r = {
        storeId: i.storeId
      }
      exist[i.storeId] = true
      if (i.bookingRuleId) {
        r.bookingRuleId = i.bookingRuleId
      }
      return r
    })

    state.storeList
      .filter(i => i.isEnable)
      .map(i => {
        if (!exist[i.storeId]) {
          let r = {
            storeId: i.storeId || i.id
          }
          if (i.bookingRuleId) {
            r.bookingRuleId = i.bookingRuleId
          }
          arr.push(r)
        }
        let r = {
          storeId: i.storeId || i.id
        }
        if (i.bookingRuleId) {
          r.bookingRuleId = i.bookingRuleId
        }
        return r
      })
    let storeList = arr
    let result = {
      ...state.cache,
      productPromotionList: state.cache.productPromotionList.map(item => {
        if (Array.isArray(item.promotionAccountId)) {
          item.promotionAccountId = item.promotionAccountId[1]
        }
        return item
      }),
      storeList: arr,
      skuList: state.cache.skuList.map(i => {
        // i.id = ''
        return {
          ...i,
          settlementList: i.settlementList.map(j => {
            // j.id = ''
            if (i.settlementType === 1) {
              const { platformShareMoney, ...r } = j
              return {
                ...r,
                sellerShareRatio: 100 - j.platformShareRatio,
                settlementType: i.settlementType
              }
            } else {
              const { platformShareRatio, ...r } = j
              return {
                ...r,
                sellerShareMoney: (i.salePrice - j.platformShareMoney).toFixed(2),
                settlementType: i.settlementType
              }
            }
          })
        }
      }),
      serviceDescriptionIds: state.pickedServiceTagList.map(i => i.id),
      businessId: state.businessInfo.id,
      saleTimeStart: state.saleDateRange[0],
      saleTimeEnd: state.saleDateRange[1],
      writeOffTimeStart: state.writeOffDateRange[0],
      writeOffTimeEnd: state.writeOffDateRange[1],
      syncWriteOffTime: state.cache.syncWriteOffTime,
      productCategoryIds: state.productCategoryInfo.sub.map(items => {
        let len = items.length
        return items[len - 1]
      })
    }
    // 没有关联工单必须删除id
    if (!result.oaCommodityId) {
      Reflect.deleteProperty(result, 'oaIssueId')
      Reflect.deleteProperty(result, 'oaCommodityId')
    }
    if (!result.ffffffffff) {
      delete result.ffffffffff
      result.shareContent = ''
      result.shareImageList = []
    } else {
      delete result.ffffffffff
    }
    return result
  },
  // 组合生成用于编辑提交表单的数据
  cacheDataForEdit: (state, getters) => {
    return {
      ...getters.cacheDataForSubmit,
      productCategoryList: getters.cacheDataForSubmit.productCategoryIds.map(i => ({ id: i }))
    }
  },

  skuFormRule: state => {
    return {
      skuImageUrl: [{ required: true, message: '请上传款式图片', trigger: 'change' }],
      skuName: [{ required: true, message: '请输入款式名称', trigger: 'blur' }],
      salePrice: [{ required: true, message: '请输入售卖价格', trigger: 'blur' }],
      originalPrice: [{ required: true, message: '请输入原价', trigger: 'blur' }],
      totalStock: [{ required: true, message: '请输入签约总库存', trigger: 'blur' }],
      lowStockRation: [{ required: true, message: '请输入库存预警比例', trigger: 'blur' }],
      baseVirtualSaleCount: [{ required: true, message: '请输入基础销量数', trigger: 'blur' }]
    }
  },
  // 缓冲表单的验证规则
  cacheFormRule: state => {
    return {
      productName: [
        { required: true, message: '请输入商品名称', trigger: 'blur' },
        { min: 1, max: 60, message: '长度在 1 到 60 个字符', trigger: 'blur' }
      ],
      productDescription: [
        { required: true, message: '请输入商品描述', trigger: 'blur' },
        { min: 1, max: 60, message: '长度在 1 到 20 个字符', trigger: 'blur' }
      ],
      productKeyword: [
        { required: true, message: '请输入商品搜索关键字', trigger: 'blur' },
        { min: 1, max: 60, message: '长度在 1 到 60 个字符', trigger: 'blur' }
      ],
      productUnit: [{ required: true, message: '请选择商品单位', trigger: 'change' }],
      purchaseInstructions: [
        { required: true, message: '请输入服务说明', trigger: 'blur' },
        { min: 16, max: 6000000, message: '长度在 16 到 6000000 个字符', trigger: 'blur' }
      ],
      productDetailContent: [
        { required: true, message: '请输入商品详情', trigger: 'blur' },
        { min: 16, max: 6000000, message: '长度在 16 到 6000000 个字符', trigger: 'blur' }
      ],
      useTips: [
        { required: true, message: '请输入使用说明', trigger: 'blur' },
        { min: 4, max: 35, message: '长度在 4 到 35 个字符', trigger: 'blur' }
      ]
    }
  },
  validateCacheForm: state => {
    const cache = state.cache
    if (!cache.productUnit) {
      return '请输入或选择商品单位'
    } else if (!state.productCategoryInfo.main) {
      return '请选择一级分类'
    } else if (!state.productCategoryInfo.sub || !state.productCategoryInfo.sub.length) {
      return '请选择二级分类'
    } else if (!cache.productMaterialsList || !cache.productMaterialsList.length) {
      return '请上传商品相册'
    } else if (cache.ffffffffff === 1 && (!cache.shareImageList || !cache.shareImageList.length)) {
      return '请上传分享图片'
    } else if (cache.ffffffffff === 1 && !cache.shareContent) {
      return '请输入分享文案'
    } else if (!cache.skuList.length) {
      return '商品SKU不能为空'
    } else if (!cache.purchaseInstructions.length || cache.purchaseInstructions.length < 16) {
      return '购买须知不能小于16个字'
    } else if (cache.purchaseInstructions.length > 6000000) {
      return '购买须知不能大于6000000个字'
    } else if (!cache.productDetailContent.length || cache.productDetailContent.length < 16) {
      return '商品详情不能小于16个字'
    } else if (cache.productDetailContent.length > 6000000) {
      return '商品详情不能大于6000000个字'
    } else if (
      cache.saleType === 2 &&
      (!cache.useTips.length || cache.useTips.length < 4 || cache.useTips.length > 35)
    ) {
      return '使用说明需要4~35个字'
    } else if (!state.pickedServiceTagList || !state.pickedServiceTagList.length) {
      return '服务说明不能为空'
    } else if (cache.posterTemplateId == '') {
      return '需要选择分享海报'
    } else if (cache.saleType !== 5) {
      // 物流商品不需要核销时间
      if (!state.writeOffDateRange || state.writeOffDateRange.length !== 2) {
        return '核销时间不能为空'
      }
    } else if (!state.saleDateRange || state.saleDateRange.length !== 2) {
      return '售卖时间不能为空'
    } else if (state.saleDateRange[0] > state.writeOffDateRange[0] && cache.saleType !== 5) {
      return '售卖开始时间不能晚于核销开始时间'
    } else if (state.saleDateRange[1] > state.writeOffDateRange[1] && cache.saleType !== 5) {
      return '售卖结束时间不能晚于核销结束时间'
    } else if (state.saleDateRange[1] > state.writeOffDateRange[1] && cache.saleType !== 5) {
      return '商品售卖结束时间不可晚于核销结束时间'
    } else if (state.writeOffDateRange[1] < state.saleDateRange[1] && cache.saleType !== 5) {
      return '商品核销结束时间不可早于售卖结束时间'
    } else {
      return ''
    }
  },
  currentSkuIndex: state => state.currentSkuIndex
}
const mutations = {
  productStoreList(state, payload) {
    state.productStoreList = payload
  },
  update: (state, { key, value }) => {
    state[key] = value
  },
  clearCache: state => {
    state.cache = {
      productId: '',
      productName: '',
      productDescription: '',
      productKeyword: '',
      productUnit: '',
      purchaseInstructions: '',
      productDetailContent: '',
      useTips: '',
      saleType: 1,
      buyLimit: 0,
      posterQrCodeSet: 1,
      onSaleNotify: 1,
      posterTemplateId: '',
      posterTemplateNo: 1,
      switchShowPurchaseInstructions: 1,
      switchSectionStock: 0,
      switchPublish: 0,
      businessModel: 0,
      refundType: 2,
      allowUpdateAll: true,
      syncWriteOffTime: 0,
      switchRefundAgreement: 1,
      cooperationTypeList: [],
      baseVirtualViewCount: 0,
      productSalesmanList: [],
      productPromotionList: [],
      productMaterialsList: [],
      shareContent: '',
      ffffffffff: 0,
      shareImageList: [],
      serviceDescriptionIds: [],
      productCategoryIds: [],
      skuList: [],
      switchExternal: 0,
      externalTableId: '',
      couponId: '',
      oaIssueId: '',
      oaCommodityId: ''
    }
    state.businessInfo = {
      id: '',
      name: '',
      logo: '',
      businessModel: ''
    }
    state.productCategoryInfo = {
      main: '',
      sub: []
    }
    state.pickedServiceTagList = []
    state.saleDateRange = []
    state.writeOffDateRange = []
    state.storeList = []
    state.commodityData = {
      package: [],
      tagList: [],
      disableTime: '',
      _id: ''
    }
  },
  // handleDistributionRewardAmountChange: () => {
  //   if (this.skuForm.distributionRewardAmount > this.skuForm.salePrice) {
  //     this.skuForm.distributionRewardAmount = ''
  //     this.$message.error('分销金额不能高于售价')
  //   }
  // },
  updateCache: (state, { key, value }) => {
    state.cache[key] = value
  },
  setShareContent(state, payload) {
    if (payload === 1) {
      state.cache['shareContent'] = state.cache.productName
      state.cache['shareImageList'] = state.cache.productMaterialsList.map(item => {
        return item.fileUrl
      })
    }
    console.log(state.cache.productMaterialsList)
  },

  updateSyncWriteOffTime(state, payload) {
    state.cache.syncWriteOffTime = payload
  },
  updateSaleDateRange(state, payload) {
    state.saleDateRange = payload
  },
  updateWriteOffTimeEnd(state, payload) {
    state.writeOffTimeEnd = payload
  },
  updateWriteOffDateRange(state, payload) {
    state.writeOffDateRange = payload
  },
  toggleStore(state, storeIndex) {
    state.storeList[storeIndex].isEnable = !state.storeList[storeIndex].isEnable
    state.hasSubMerchant = true
    state.storeList.forEach(item => {
      if (item.isEnable && item.bindedSubMerchant !== 1) {
        state.hasSubMerchant = false
      }
    })

    if (!state.storeList[storeIndex].isEnable) {
      state.storeList[storeIndex].bookingRuleId = ''
    }
  },
  updateStoreBookingRule(state, { storeIndex, value }) {
    state.storeList[storeIndex].bookingRuleId = value
  },
  addServiceTag: (state, payload) => {
    if (!state.pickedServiceTagList.find(i => i.id === payload.id)) {
      state.pickedServiceTagList.push(payload)
    }
  },
  deleteServiceTag: (state, payload) => {
    const n = state.pickedServiceTagList.findIndex(i => i.id === payload.id)
    if (n >= 0) {
      state.pickedServiceTagList.splice(n, 1)
    }
  },
  pickNote(state, payload) {
    if (payload) {
      state.cache.purchaseInstructions = $api.product.getTemplate(payload - 1)
    } else {
      state.cache.purchaseInstructions = ''
    }
  },
  addNewSeller(state, payload) {
    if (!state.cache.productSalesmanList.length) {
      state.cache.productSalesmanList.push({ oaId: '', userName: '', saleRatio: 100 })
    } else {
      const a = state.cache.productSalesmanList.map(i => i.saleRatio).reduce((a, b) => a + b)
      state.cache.productSalesmanList.push({ oaId: '', userName: '', saleRatio: 100 - a })
    }
  },
  deleteSeller(state, index) {
    state.cache.productSalesmanList.splice(index, 1)
  },
  updateSeller(state, { index, value }) {
    state.cache.productSalesmanList.splice(index, 1, {
      ...state.cache.productSalesmanList[index],
      oaId: value._id,
      userName: value.trueName
    })
  },
  updateSellerRatio(state, { index, value }) {
    state.cache.productSalesmanList.splice(index, 1, {
      ...state.cache.productSalesmanList[index],
      saleRatio: value
    })
  },
  addNewPromo(state, payload) {
    state.cache.productPromotionList.push({ editionNumber: '', promotionAccountId: '', promotionTime: '' })
  },
  deletePromo(state, index) {
    state.cache.productPromotionList.splice(index, 1)
  },
  updatePromo(state, { index, key, value }) {
    if (key === 'promotionAccountId' && value.length === 1 && +value[0] === 1) {
      try {
        let defaultId = store.state.product.basic.promoList[1].children[0].id
        state.cache.productPromotionList.splice(index, 1, {
          ...state.cache.productPromotionList[index],
          [key]: ['1', defaultId]
        })
      } catch (err) {
        console.log(err)
      }
    } else if (key == 'editionNumber' && state.cache.productPromotionList[index].promotionAccountId[0] == 1) {
      state.cache.productPromotionList.splice(index, 1, {
        ...state.cache.productPromotionList[index],
        editionNumber: 1
      })
    } else {
      state.cache.productPromotionList.splice(index, 1, { ...state.cache.productPromotionList[index], [key]: value })
    }
  },
  handlePickBusiness(state, payload) {
    state.businessInfo = {
      id: payload.id,
      name: payload.businessTitle,
      logo: payload.businessLogo,
      businessModel: payload.businessModel
    }
    state.cache.businessModel = payload.businessModel
  },
  clearPickBusiness(state) {
    state.businessInfo = {
      id: '',
      name: '',
      logo: '',
      businessModel: ''
    }
  },
  updateProductCategoryInfo(state, { key, value }) {
    console.log(value)
    state.productCategoryInfo = {
      ...state.productCategoryInfo,
      [key]: value
    }
  },
  updateProductImage(state, payload) {
    state.cache = {
      ...state.cache,
      productMaterialsList: payload.map(i => ({ fileType: 1, fileUrl: i }))
    }
  },
  updateShareImageList(state, payload) {
    state.cache = {
      ...state.cache,
      shareImageList: payload
    }
  },
  deleteSku: (state, n) => {
    state.cache.skuList.splice(n, 1)
  },
  addSku: (state, payload) => {
    state.cache.skuList.push({
      ...payload
    })
  },
  editSku(state, payload) {
    const n = state.currentSkuIndex
    state.cache.skuList.splice(n, 1, { ...state.cache.skuList[n], ...payload })
  },
  updateProductDetail(state, params) {
    let { payload, productCategoryList } = params
    state.writeOffTimeEnd = payload.writeOffTimeEnd
    state.cache.allowUpdateAll = payload.allowUpdateAll
    state.cache = { ...payload, useTips: payload.useTips || '' }
    state.businessInfo = {
      id: payload.business.id,
      name: payload.business.businessTitle,
      logo: payload.business.businessLogo,
      businessModel: payload.business.businessModel
    }

    let stack = {}
    let temp = []
    let result = []
    let main = payload.productCategoryList.find(item => +item.parentId === 0)
    state.productCategoryInfo.main = main.id
    let subList = payload.productCategoryList.filter(item => +item.parentId !== 0)
    for (let i = 0; i < subList.length; i++) {
      let p = subList.find(item => subList[i].parentId === item.id)
      if (!temp.includes(subList[i].id)) {
        if (p) {
          stack[subList[i].id] = [p, subList[i]]
        } else {
          stack[subList[i].id] = [subList[i]]
        }
      }
      temp.push(subList[i].parentId)
    }

    let boole = true
    // for (let k in stack) {

    //   productCategoryList.forEach(item => {

    //     if (item.childList) {
    //       item.childList.forEach(childItem => {

    //         if (childItem.id == k && childItem.childList) {
    //           childItem.childList.forEach(thirdItem => {
    //             let a = []
    //             console.log(thirdItem.id)
    //             a.push(childItem.id, thirdItem.id)
    //             result.push(a)
    //           })
    //         } else {
    //           boole = true
    //         }

    //       })
    //     }

    //   })

    // }
    if (boole) {
      for (let k in stack) {
        result.push(stack[k].map(item => item.id))
      }
    }

    state.cache.skuList.map(i => {
      i.settlementType = i.settlementList[0].settlementType
      return i
    })

    state.productCategoryInfo.sub = result
    state.pickedServiceTagList = payload.serviceDescriptionList
    state.saleDateRange = [payload.saleTimeStart, payload.saleTimeEnd]
    if (payload.writeOffTimeStart === -1) {
      state.writeOffDateRange = []
    } else {
      state.writeOffDateRange = [payload.writeOffTimeStart, payload.writeOffTimeEnd]
    }
    state.storeList = payload.storeList.map(i => {
      i.id = i.storeId
      i.disabled = true
      if (i.bookingRuleId === '0') {
        i.bookingRuleId = ''
      }
      return i
    })
  }
}
const actions = {
  handleUpdateSeller(store, { index, value }) {
    if (!value) return
    if (typeof value !== 'string') return
    const seller = store.rootGetters['product/basic/sellerList'].find(i => i._id === value)
    store.commit('updateSeller', { index, value: seller })
  },
  getData(store, id = 0) {
    store.commit('update', { key: 'loading', value: true })
    console.log('>>>>>>> get data in vuex')
    store.commit('update', { key: 'loading', value: false })
    return 'ok'
  },
  getProductDetail(store, { id = 0, isCopy = false }) {
    return http
      .get(`/homebrew-api/product/${id}`)
      .then(async res => {
        store.commit('productStoreList', [])
        if (sessionStorage.areaId == -1) {
          store.commit('productStoreList', res.storeList)
        }
        if (isCopy) {
          res.allowUpdateAll = true
          res.productId = ''
          res.writeOffTimeStart = -1
          res.skuList.map(item => {
            delete item.id
            item.settlementList.map(item2 => {
              item2.id = ''
            })
          })
          store.commit('productStoreList', [])
        }

        if (res.shareContent) {
          res.ffffffffff = 1
        } else {
          res.ffffffffff = 0
        }

        // await store.dispatch('getCategory3', getCategory2)

        await store.dispatch('product/basic/getProductCategory', {}, { root: true })
        let productCategoryList = store.rootGetters['product/basic/productCategoryList']

        let ress = res
        store.commit('updateProductDetail', { payload: ress, productCategoryList: productCategoryList })

        console.log(res.businessModel)
        store.dispatch('product/basic/mallShareRatio', {}, { root: true })
        return store.dispatch('getStoreList', res)
      })
      .then(res => {
        return store.dispatch('product/basic/getBookingRuleList', {}, { root: true })
      })
      .catch(err => {
        console.log(err)
        Message.error(err.msg)
      })
  },
  getStoreList(store, productInfo = {}) {
    return http
      .get(`/boom-center-product-service/sysAdmin/store/all/mini?businessId=${store.getters.businessInfo.id}`)
      .then(ress => {
        function compare(p) {
          //这是比较函数
          return function(m, n) {
            var a = m[p]
            var b = n[p]
            return a - b //升序
          }
        }
        let res = ress.sort(compare('areaId'))
        if (!store.getters.cache.productId) {
          store.commit('update', {
            key: 'storeList',
            value: res.map(i => {
              i.isEnable = true
              i.bookingRuleId = ''
              i.bookingRuleName = ''
              return i
            })
          })
        } else if (productInfo && productInfo.storeList) {
          productInfo.storeList.map(item => {
            res.forEach(i => {
              if (item.id === i.id) {
                item.bindedSubMerchant = i.bindedSubMerchant
              }
            })
            return item
          })

          store.commit('update', {
            key: 'storeList',
            value: res.map(i => {
              const c = productInfo.storeList.find(j => j.storeId === i.id)

              if (c) {
                return { ...c, areaId: i.areaId, isEnable: true }
              } else {
              }
              return {
                ...i,
                bookingRuleId: '',
                bookingRuleName: '',
                isEnable: false
              }
            })
          })
        }

        let value = true
        state.storeList.forEach(item => {
          if (item.isEnable && item.bindedSubMerchant === 0) {
            value = false
          }
        })
        store.commit('update', {
          key: 'hasSubMerchant',
          value: value
        })

        return res
      })
  },
  submit(store, { copy = false }) {
    if (store.getters.validateCacheForm) {
      return Promise.reject({ msg: store.getters.validateCacheForm })
    }
    if (copy) {
      return store.dispatch('handleAdd')
    } else {
      if (store.getters.cache.productId) {
        return store.dispatch('handleEdit')
      } else {
        return store.dispatch('handleAdd')
      }
    }
  },
  handleEdit(store) {
    return http.put(`/homebrew-api/product/`, store.getters.cacheDataForEdit)
  },
  handleAdd(store) {
    return http.post(`/homebrew-api/product/`, store.getters.cacheDataForSubmit)
  }
}

export default {
  namespaced: true,
  state,
  getters,
  mutations,
  actions
}
