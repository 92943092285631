<!--
    秒杀组件 类型2  两列 右边上下分布
-->

<template>
  <div class="seckill-2" @click="edit">
    <div id="point_wrapper">
      <div class="s-2-header">
        <span>限时秒杀</span>
        <p>
          <span class="time">05</span>
          <span>时</span>
          <span class="time">06</span>
          <span>分</span>
          <span class="time">60</span>
          <span>秒</span>
        </p>
        <span>更多》</span>
      </div>

      <el-row>
        <el-col :span="12">
          <div class="s-2-left">
            <img :src="defaultImg" alt="" />
          </div>
        </el-col>
        <el-col :span="12">
          <div class="s-2-right">
            <img :src="defaultImg" alt="" />
            <img :src="defaultImg" alt="" />
          </div>
        </el-col>
      </el-row>
    </div>
  </div>
</template>

<script>
export default {
  props: {
    cptData: {
      type: Object,
      default: {
        componentName: 'seckill_1',
        selectStatus: false,
        data: {}
      }
    }
  },
  data() {
    return {
      defaultImg: require('@/asset/icon/saoma@2x.png')
    }
  },
  methods: {
    edit() {
      this.$emit('edit')
    }
  }
}
</script>

<style lang="less" scoped>
@import './com.less';
.seckill-2 {
  padding: 10px 5px;
  background-color: @color-background;

  &:hover {
    cursor: pointer;
    box-shadow: 0 0 2px @color-border;
  }

  & > div {
    font-size: 14px;
    .s-2-header {
      padding: 0 10px 10px;
      p {
        display: inline-block;
        margin-left: 10px;
        span {
          display: inline-block;
          width: 18px;
          height: 18px;
        }
        .time {
          background-color: rgba(000, 000, 000, 0.8);
          color: @color-background;
        }
      }
      span:last-of-type {
        float: right;
      }
    }
    .s-2-left,
    .s-2-right {
      text-align: center;
      img {
        width: 90%;
      }
    }
    .s-2-left {
      text-align: center;
      img {
        height: 280px;
      }
    }
  }
}
</style>
