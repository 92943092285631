<template>
  <div class="foot-bar">
    <template v-if="!canEdit">
      <div class="bar-body" :class="{ currentselect: cptData.selectStatus && !canEdit }">
        <div class="bar-content">
          <template v-for="(d, i) in cptData.data">
            <div
              class="bar-item"
              v-if="i < currentActBarsIndex && d.hideStatus == 0"
              :class="{ baritemclick: barClickIndex == i }"
              :key="'bar-body' + i"
              @click="barItemClick(i)"
            >
              <img v-if="barClickIndex == i" :src="d.selectedImageUrl" alt="" />
              <img v-else :src="d.unselectedImageUrl" alt="" />
              <p>{{ d.name }}</p>
            </div>
          </template>
        </div>
      </div>
    </template>
    <template v-else>
      <div class="foot-bar-edit">
        <p class="c-e-header">底部栏</p>
        <el-button type="primary" class="add_btn" @click="addNav">添加导航</el-button>
        <div>
          <el-collapse v-model="activeName" :accordion="true">
            <template v-for="(classifyData, i) in cptData.data">
              <el-collapse-item
                :name="cptData.componentName + i"
                :key="'footbar' + i"
                :class="{ forbid: classifyData.hideStatus == 1 }"
              >
                <template slot="title">
                  <span style="margin-left: 10px; font-weight: 700">{{
                    i + 1 + (classifyData.name ? '-' + classifyData.name : '')
                  }}</span>
                  <span style="font-size: 12px">
                    {{ '（' + (classifyData.hideStatus == 0 ? '√' : '×') + '）' }}
                  </span>
                </template>
                <el-form
                  ref="classifyData"
                  :rules="rules"
                  :model="classifyData"
                  class="vendorFrom"
                  label-position="right"
                  label-width="100px"
                >
                  <el-form-item label="状态">
                    <el-switch
                      v-model="classifyData.hideStatus"
                      :active-value="0"
                      :inactive-value="1"
                      @change="handleChangeState(i, classifyData.hideStatus)"
                    ></el-switch>
                  </el-form-item>
                  <el-form-item label="图标" prop="bannerImageUrl">
                    <table>
                      <tr>
                        <td>
                          <el-form-item prop="unselectedImageUrl" label-width="0">
                            <bm-upload
                              v-model="classifyData.unselectedImageUrl"
                              type="system"
                              @change="handleCheckSelectedImg(i)"
                            ></bm-upload>
                            <p>未选中状态</p>
                          </el-form-item>
                        </td>
                        <td>
                          <el-form-item prop="selectedImageUrl" label-width="0">
                            <bm-upload
                              v-model="classifyData.selectedImageUrl"
                              type="system"
                              @change="handleCheckUnselectedImg(i)"
                            ></bm-upload>
                            <p>选中状态</p>
                          </el-form-item>
                        </td>
                      </tr>
                      <tr>
                        <td colspan="2" style="text-align: center"><span class="assist">建议尺寸80*80</span></td>
                      </tr>
                    </table>
                  </el-form-item>
                  <el-form-item label="导航名称" prop="name">
                    <el-input
                      v-model="classifyData.name"
                      type="string"
                      :maxlength="4"
                      placeholder="自定义导航名称"
                    ></el-input>
                  </el-form-item>
                  <!-- <el-form-item label="导航排序" prop="bannerSort">
                    <el-input-number
                      v-model="classifyData.bannerSort"
                      controls-position="right"
                      :min="0"
                      :step="1"
                      :max="9999"
                    ></el-input-number>
                  </el-form-item> -->
                  <el-form-item label="跳转路径" prop="linkUrl">
                    <el-input v-model="classifyData.linkUrl" placeholder="输入跳转链接"></el-input>
                  </el-form-item>
                  <el-form-item v-loading="loading">
                    <!-- <el-button type="primary" @click="list">列表</el-button> -->
                    <el-button type="primary" v-if="cptData.data.length < 6" @click="save(i)">保存</el-button>
                    <el-button type="danger" @click="remove(i)">删除</el-button>
                  </el-form-item>
                </el-form>
              </el-collapse-item>
            </template>
          </el-collapse>
        </div>
      </div>
    </template>
  </div>
</template>

<script>
import { log } from '@/util/log'
export default {
  name: 'foot-bar',
  props: {
    cptData: {
      type: Object,
      default: {
        componentName: 'footBar',
        selectStatus: false,
        data: ['首页', '首页', '首页', '首页']
      }
    },
    canEdit: {
      type: Boolean,
      default: false,
      comment: '是否编辑状态， true是，会在装修页面右侧调用'
    }
  },
  data() {
    return {
      loading: false,
      barClickIndex: 0,
      activeName: this.cptData.componentName + 0,
      rules: {
        unselectedImageUrl: [{ required: true, message: '请上传未选中状态图片', trigger: 'blur' }],
        selectedImageUrl: [{ required: true, message: '请上传选中状态图片', trigger: 'blur' }],
        name: [{ required: true, message: '请输入导航名称', trigger: 'blur' }],
        linkUrl: [{ required: true, message: '请输入跳转路径', trigger: 'blur' }]
      },
      model: {
        areaId: 0,
        hideStatus: 0,
        id: 0,
        linkUrl: '',
        name: '',
        selectedImageUrl: '',
        unselectedImageUrl: ''
      },
      currentActBarsIndex: 4, //设置底部栏显示的给菜单个数
      areaId: this.$store.getters['malls/getCurrentEditAreaId'],
      curIndex: 0
    }
  },
  created() {},
  methods: {
    openCurIndex(index) {
      let len = this.cptData.data.length
      if (index > len - 1) {
        this.curIndex = len - 1
      } else {
        this.curIndex = index
      }
      this.activeName = this.cptData.componentName + this.curIndex
    },
    handleCheckSelectedImg(index) {
      this.$refs['classifyData'][index].validateField('selectedImageUrl')
    },
    handleCheckUnselectedImg(index) {
      this.$refs['classifyData'][index].validateField('unselectedImageUrl')
    },
    async remove(index) {
      let res = await this.$confirm('此操作将永久删除该分类, 是否继续?', '提示', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning'
      })
      if (res == 'confirm') {
        this.loading = true
        if (this.cptData.data[index].id) {
          this.$api.mall
            .mall('deleteBottomBar', this.cptData.data[index].id)
            .then(() => {
              this.cptData.data.splice(index, 1)
              this.openCurIndex(index)
              this.$message.success('移除成功')
              setTimeout(() => {
                this.loading = false
              }, 200)
            })
            .catch(err => {
              this.$message.error(err)
            })
            .finally(() => {
              setTimeout(() => {
                this.loading = false
              }, 200)
            })
        } else {
          this.cptData.data.splice(index, 1)
          this.openCurIndex(index)
          setTimeout(() => {
            this.loading = false
          }, 200)
        }
      } else {
        this.$message({
          type: 'info',
          message: '已取消删除'
        })
      }
    },
    addNav() {
      if (this.cptData.data.length >= 5) {
        this.$message.info('导航至多只能添加5个')
        return
      }
      this.cptData.data.push({ ...this.model })
      this.openCurIndex(this.cptData.data.length - 1)
    },
    save(index) {
      this.openCurIndex(index)
      this.$refs['classifyData'][index].validate(valid => {
        if (valid) {
          this.loading = true
          this.$api.mall
            .mall('postBottomBar', this.cptData.data, {
              headers: {
                ['Bm-Area-Id']: this.areaId
              }
            })
            .then(res => {
              this.cptData.data = [...res]
              this.$message.success('保存成功')
            })
            .catch(err => {
              this.$message.error(err.msg || '操作失败')
            })
            .finally(() => {
              setTimeout(() => {
                this.loading = false
              }, 200)
            })
        }
      })
    },
    barItemClick(index) {
      this.barClickIndex = index
    }
  },
  watch: {
    'cptData.data': {
      handler(n) {
        let count = 0
        for (let i in n) {
          if (n[i].hideStatus == 0) {
            count++
            if (count == 5) {
              this.currentActBarsIndex = Number(Number(i) + 1)
              break
            }
          }
        }
      },
      deep: true
    }
  }
}
</script>

<style lang="less" scoped>
@import './com.less';
.baritemclick {
  color: #e7141a;
}
.forbid {
  background-color: @color-error;
}
.currentselect {
  border: red 1px solid !important;
  .radius(6px);
}
.foot-bar {
  .bar-body {
    position: absolute;
    bottom: 50px;
    width: 385px;
    height: 50px;
    margin: 0 -6px;
    z-index: 1000;
    background-color: #f7f7f7;
    padding: 0 6px;
    .bar-content {
      display: flex;
      justify-content: space-around;
      border: 1px solid transparent;
      padding: 5px 0;
      .bar-item {
        vertical-align: top;
        text-align: center;
        cursor: pointer;
        padding: 0 15px;
        img {
          width: 23px;
          height: 23px;
        }
        p {
          height: 11px;
          font-size: 11px;
          margin: 0;
        }
      }
    }
  }

  .foot-bar-edit {
    padding: 25px;
    background-color: @color-background;
    .c-e-header {
      margin-bottom: 20px;
      font-weight: 700;
    }

    table {
      td {
        p {
          text-align: center;
        }
      }
    }
  }
}
</style>
