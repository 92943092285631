const state = {
  editAccountData: {
    time: '',
    data: ''
  }
}
const getters = {
  editAccountData: state => {
    if (state.editAccountData.time < Date.now() + 1000 * 60 * 60 * 24 * 3) {
      try {
        const d = JSON.parse(state.editAccountData.data)
        if (Object.keys(d).length) {
          return d
        } else {
          return ''
        }
      } catch (e) {
        return ''
      }
    } else {
      return ''
    }
  }
}
const mutations = {
  add: (state, { key, value }) =>
    (state[key] = { data: JSON.stringify(value), time: Date.now() }),
  clear: (state, key) => (state[key] = { time: '', data: '' }),
  update: (state, { key, value }) => (state[key] = value)
}
const actions = {}

export default {
  namespaced: true,
  state,
  getters,
  mutations,
  actions
}
