<!--
    纵向两列
-->

<template>
  <div class="waterfall_2">
    <div>
      <template v-if="!canEdit">
        <div class="header-img">
          <img :src="cptData.bgImage" alt="" />
        </div>
        <el-row :gutter="10">
          <template v-for="(d, i) in productList">
            <el-col :span="12" :key="'shop-card' + i">
              <div class="shop-card">
                <img :src="d.productImageUrl" alt="" />
                <p>{{ d.productName }}</p>
                <p>
                  <span>￥{{ d.firstSkuSalePrice }}</span>
                  <span>￥{{ d.firstSkuOriginalPrice }}</span>
                  <span>已售{{ d.saleCount }}</span>
                </p>
              </div>
            </el-col>
          </template>
        </el-row>
      </template>
      <template v-else>
        <div class="waterfall-edit">
          <p class="c-e-header">纵向两列</p>
          <!-- <el-button type="primary" class="add_btn" @click="addActiveItem">添加活动</el-button> -->
          <div>
            <template v-for="(classifyData, i) in cptData.data">
              <!-- :rules="rules" -->
              <el-form
                :ref="'classifyRef' + i"
                :model="classifyData"
                :rules="rules"
                class="vendorFrom"
                label-position="right"
                label-width="100px"
                :key="i"
              >
                <el-form-item label="状态">
                  <el-switch
                    v-model="classifyData.hideStatus"
                    :active-value="0"
                    :inactive-value="1"
                    @change="handleChangeState"
                  ></el-switch>
                </el-form-item>
                <el-form-item label="选择活动" prop="productGroupId">
                  <activitySelect v-model="classifyData.productGroupId"></activitySelect>
                </el-form-item>
                <el-form-item label="效果设置" prop="productGroupId"
                  ><el-radio v-model="classifyData.textOrImage" label="image">活动背景图</el-radio>
                  <el-radio v-model="classifyData.textOrImage" label="text">文字标题</el-radio>
                </el-form-item>
                <el-form-item v-loading="loading">
                  <el-button type="primary" @click="save(i)">保存</el-button>
                  <el-button type="danger" @click="remove(i)">删除</el-button>
                </el-form-item>
              </el-form>
            </template>
          </div>
        </div>
      </template>
    </div>
  </div>
</template>

<script>
import { log } from '@/util/log'
import activitySelect from './__com__/activitySelect'
export default {
  name: 'waterfall_2',
  inject: ['addidtocomponent'],
  components: { activitySelect },

  props: {
    cptData: {
      type: Object,
      default: {
        componentName: 'waterfall_1',
        selectStatus: false,
        bgImage: '',
        data: {}
      }
    },
    canEdit: {
      type: Boolean,
      default: false,
      require: false,
      comment: '是否编辑状态， true是，会在装修页面右侧调用'
    }
  },
  data() {
    return {
      loading: false,
      defaultImg: '',
      productList: [],
      model: {
        areaId: '',
        hideStatus: 0,
        id: '',
        // productGroupId: '1385871735215230977',
        textOrImage: 'image',
        productGroupId: '',
        showEndTime: '',
        showStartTime: ''
      },

      rules: {
        productGroupId: [{ required: true, message: '请选择活动', trigger: 'change' }]
      },
      areaId: this.$store.getters['malls/getCurrentEditAreaId'],
      loading: false
    }
  },
  created() {
    if (!this.cptData.data.length) {
      this.cptData.data.push({ ...this.model })
    }
  },
  methods: {
    activityChange(index) {
      for (let v of this.activityList) {
        if (v.id == this.cptData.data[index].productGroupId) {
          this.cptData.data[index].waterfallTitle = v.activityName
          break
        }
      }
    },
    getProductList() {
      let flag = false
      for (let i of this.cptData.data) {
        if (i.hideStatus == 0 && i.productGroupId) {
          this.$api.mall.mall('getActivityDetail', i.productGroupId).then(detail => {
            this.cptData.bgImage = detail.backgroundImageUrl
            this.$api.mall
              .mall('getActivityProduct2', {
                params: {
                  id: i.productGroupId,
                  textOrImage: i.textOrImage,
                  limitNum: detail.productShowNum,
                  hideSoldOutProduct: detail.hideSoldOutProduct,
                  sortAsc: detail.productSortAsc,
                  sortType: detail.productSortType,
                  size: 1000
                }
              })
              .then(res => {
                this.productList = [...res.list]
              })
          })
          flag = true
          break
        }
      }
      if (!flag) {
        this.productList = []
        this.cptData.bgImage = ''
      }
    },
    addActiveItem() {
      this.cptData.data.push({ ...this.model })
    },
    save(index) {
      this.$refs['classifyRef' + index] &&
        this.$refs['classifyRef' + index][0].validate(valid => {
          if (valid) {
            this.loading = true
            this.$api.mall
              .mall('postWaterfall', this.cptData.data[index], {
                headers: {
                  ['Bm-Area-Id']: this.areaId
                }
              })
              .then(res => {
                this.addidtocomponent({
                  id: res.id,
                  activeStatus: res.hideStatus == 0 ? true : false,
                  productGroupId: res.productGroupId,
                  textOrImage: res.textOrImage
                })
                this.$message.success('保存成功')
              })
              .catch(err => {
                this.$message.error(err.msg || '操作失败')
              })
              .finally(() => {
                setTimeout(() => {
                  this.loading = false
                }, 200)
              })
          }
        })
    },
    remove(index) {
      this.$confirm('确定删除该内容吗?', '提示', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning'
      })
        .then(() => {
          this.loading = true
          if (this.cptData.data[index].id) {
            this.$api.mall
              .mall('deleteWaterfall', this.cptData.data[index].id, {
                headers: {
                  ['Bm-Area-Id']: this.areaId
                }
              })
              .then(res => {
                this.addidtocomponent({ id: '', activeStatus: '' })
                this.$message.success('删除成功')
              })
              .catch(err => {
                this.$message.error(err.msg || '操作失败')
              })
              .finally(() => {
                setTimeout(() => {
                  this.loading = false
                }, 200)
              })
          } else {
            this.$message.info('无数据，无须删除')
            this.loading = false
          }
        })
        .catch(() => {
          this.$message({
            type: 'info',
            message: '已取消'
          })
        })
    },
    handleChangeState() {}
  },
  watch: {
    cptData: {
      handler(n) {
        if (!this.canEdit) {
          this.getProductList()
        }
      },
      deep: true
    }
  }
}
</script>

<style lang="less" scoped>
@import './com.less';
.waterfall_2 {
  border: 1px solid transparent;
  cursor: pointer;

  .radius(6px);
  & > div {
    .header-img {
      img {
        height: 110px;
        width: 100%;
        .radius(5px);
      }
    }
    .shop-card {
      background-color: @color-background;
      margin-bottom: 15px;
      overflow: hidden;
      .radius(6px);
      img {
        width: 100%;
        height: 124px;
      }
      p {
        padding: 0 10px;
        &:nth-of-type(1) {
          height: 33px;
          line-height: 19px;
          font-size: 13px;
          font-weight: 500;
          overflow: hidden;
          display: -webkit-box;
          -webkit-box-orient: vertical;
          -webkit-line-clamp: 2;
        }
        &:nth-of-type(2) {
          color: #999999;
          margin-bottom: 6px;

          span {
            &:nth-child(1) {
              font-size: 12px;
              color: @color-primary-select;
            }
            &:nth-child(2) {
              font-size: 10px;
            }
            &:nth-child(3) {
              font-size: 11px;
              float: right;
              position: relative;
              bottom: -4px;
            }
          }
        }
      }
    }
    .waterfall-edit {
      padding: 25px;
      background-color: @color-background;
      .c-e-header {
        margin-bottom: 50px;
        font-weight: 700;
      }
    }
  }
}
</style>
