<template>
  <div class="priceInputContainer">
    <span class="currencyIcon">￥</span>
    <el-input-number
      size="mini"
      :value="value"
      @input="onValueChange"
      :min="minNumber"
      :max="maxNumber"
      :disabled="disabled"
      controls-position="right"
      :placeholder="placeholder || '请输入价格'"
      @blur="onBlur"
      @keyup.enter.native="onBlur"
    ></el-input-number>
    <span class="unit">元</span>
  </div>
</template>

<script>
import { mapGetters, mapActions } from 'vuex'

export default {
  props: {
    value: {
      type: [String, Number],
      default: 0
    },
    placeholder: String,
    disabled: {
      type: Boolean,
      default: false
    },
    min: {
      type: [Number, String],
      default: 0
    },
    max: {
      type: [Number, String],
      default: Infinity
    },
    ratio: {
      type: Number,
      default: 100
    }
  },
  data: () => ({
    bar: 'bar'
  }),
  computed: {
    minNumber() {
      return Number(this.min || 0)
    },
    maxNumber() {
      return Number(this.max || 0)
    }
  },
  watch: {},
  methods: {
    onBlur() {
      let val = this.value
      if (val > (this.max * this.ratio) / 100 && val !== this.max) {
        val = (this.max * this.ratio) / 100
      }
      this.$emit('input', val)
    },
    onValueChange(val) {
      this.$emit('input', val)
    }
  },
  created() {},
  mounted() {}
}
</script>

<style lang="less" scoped>
.priceInputContainer {
  display: inline-flex;
  vertical-align: middle;
  align-items: center;
  .currencyIcon {
    margin-right: 5px;
  }
  .unit {
    margin-left: 5px;
  }
}
</style>
