<template>
  <div class="search-cpt">
    <template v-if="!canEdit">
      <div class="s-site" v-if="!cptData.hideAreaListSelect">
        <el-select v-model="site">
          <el-option value="0" label="全国"></el-option>
          <el-option value="1" label="广州"></el-option>
          <el-option value="2" label="深证"></el-option>
          <el-option value="3" label="上海"></el-option>
          <el-option value="4" label="北京"></el-option>
        </el-select>
      </div>
      <div class="s-search" :style="cptData.hideAreaListSelect && 'display : block;width:100%'">
        <el-input placeholder="搜索"></el-input>
      </div>
    </template>
  </div>
</template>

<script>
export default {
  name: 'search-cpt',
  props: {
    cptData: {
      type: Object,
      default: {
        componentName: 'carousel',
        selectStatus: false,
        data: {}
      }
    },
    canEdit: {
      type: Boolean,
      default: false,
      require: false,
      comment: '是否编辑状态， true是，会在装修页面右侧调用'
    }
  },
  data() {
    return {
      site: '0'
    }
  }
}
</script>
<style lang="less">
.s-site {
  .el-input__suffix-inner {
    pointer-events: none;
  }
}
</style>
<style lang="less" scoped>
@import './com.less';

.search-cpt {
  padding: 10px 5px;

  .radius(6px);
  &:hover {
    cursor: pointer;
    box-shadow: 0 0 2px @color-border;
  }

  .s-site,
  .s-search {
    display: inline-block;
  }
  .s-site {
    width: 30%;
  }
  .s-search {
    width: 70%;
  }
}
</style>
