<template>
  <div id="app">
    <router-view />
  </div>
</template>

<script>
export default {
  name: 'app',
  components: {},
  mounted() {
    this.$store.dispatch('user/initAreaList')
  }
}
</script>

<style lang="less">
@import '~@/style/reset.less';
@import '~@/style/global.less';
@import '~@/style/modules/table.less';
</style>
