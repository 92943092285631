import Vue from 'vue'
import * as Sentry from '@sentry/browser'
import { Vue as VueIntegration } from '@sentry/integrations'
import { Integrations } from '@sentry/tracing'
const pkg = require('../../package')

if (process.env.NODE_ENV === 'production') {
  const dsn = window.location.protocol + process.env.VUE_APP_SENTRY_URL
  Sentry.init({
    dsn,
    release: 'bm-admin-web@v' + pkg.version,
    environment: 'staging',
    tracesSampleRate: 1.0,
    integrations: [
      new VueIntegration({
        Vue,
        tracing: true,
        logErrors: process.env.VUE_APP_SENTRY_logError === 'true'
      }),
      new Integrations.BrowserTracing()
    ]
  })
} 
