import http from '@/plugin/axios'
import httpOa from '@/plugin/axiosForOa'

const state = {
  businessList: [],
  productCategoryList: [],
  serviceTagList: [],
  sellerList: [],
  promoList: [],
  postTempList: [],
  bookingRuleList: [],
  mallShareRatio: 30,
  businessListPage: {
    size: 6,
    page: 1,
    total: 0,
    searchValue: ''
  },
  shelfStatusList: [
    { name: '全部', value: '' },
    { name: '出售中', value: 1 },
    { name: '待上架', value: 2 },
    { name: '已下架', value: 3 }
  ],
  stockStatusList: [
    { name: '全部', value: '' },
    { name: '库存预警', value: 1 },
    { name: '已售罄', value: 2 },
    { name: '未售罄', value: 3 }
  ],
  expiringStatusList: [
    { name: '全部', value: '' },
    { name: '即将过期', value: 1 },
    { name: '已过期', value: 2 }
  ]
}
const getters = {
  shelfStatusList: state => state.shelfStatusList,
  stockStatusList: state => state.stockStatusList,
  expiringStatusList: state => state.expiringStatusList,

  businessList: state => state.businessList,
  mallShareRatio: state => state.mallShareRatio,
  businessListPage: state => state.businessListPage,
  productCategoryList: state => state.productCategoryList,
  postTempList: state => state.postTempList,
  productMainCategoryList: state =>
    state.productCategoryList.map(i => {
      const { childList, ...r } = i
      return r
    }),
  serviceTagList: state => state.serviceTagList,
  bookingRuleList: state => state.bookingRuleList,
  sellerList: state => state.sellerList,
  promoList: state => state.promoList
}
const mutations = {
  update: (state, { key, value }) => (state[key] = value),
  updateBusinessListSearchValue(state, payload) {
    state.businessListPage = {
      ...state.businessListPage,
      searchValue: payload
    }
  }
}
const actions = {
  mallShareRatio(store) {
    let model = store.rootGetters['product/partner/cache'].businessModel
    console.log(model)
    return http.get(`/boom-center-admin-service/sys/mallSetting/account/ratio`).then(res => {
      store.commit('update', {
        key: 'mallShareRatio',
        value: model ? 100 : res
      })
    })
  },
  getPostTemp(store) {
    return http
      .get('/boom-px-api-service/template', {
        params: {
          size: -1,
          category: 'product'
        }
      })
      .then(res => {
        console.log(res)
        store.commit('update', {
          key: 'postTempList',
          value: res.list || []
        })
      })
  },

  getProductCategory(store) {
    function treeData(data) {
      for (let i = 0; i < data.length; i++) {
        if (data[i].childList.length === 0) {
          data[i].childList = undefined
        } else {
          treeData(data[i].childList)
        }
      }
      return data
    }
    return http.get('/boom-center-product-service/sysAdmin/productCategory/all/').then(res => {
      store.commit('update', {
        key: 'productCategoryList',
        value: treeData(res)
      })
    })
  },
  getBusinessList(store) {
    return http
      .get('/boom-center-product-service/sysAdmin/business/list/mini', {
        params: {
          size: store.getters.businessListPage.size,
          page: store.getters.businessListPage.page,
          searchByName: store.getters.businessListPage.searchValue
        }
      })
      .then(res => {
        store.commit('update', {
          key: 'businessList',
          value: res.list || []
        })
        store.commit('update', {
          key: 'businessListPage',
          value: {
            page: store.getters.businessListPage.page,
            size: res.size,
            total: res.total,
            searchValue: store.getters.businessListPage.searchValue
          }
        })
      })
  },
  onBusinessListPageChange(store, page) {
    store.commit('update', {
      key: 'businessListPage',
      value: {
        ...store.getters.businessListPage,
        page
      }
    })
    store.commit('product/partner/clearPickBusiness', {}, { root: true })
    store.dispatch('getBusinessList')
  },
  handleSearchBusinessList(store) {
    store.dispatch('onBusinessListPageChange', 1)
  },
  getServiceTagList(store) {
    return http.get('/boom-center-product-service/sysAdmin/productService/').then(res => {
      store.commit('update', {
        key: 'serviceTagList',
        value: res || []
      })
    })
  },
  getSellerList(store) {
    return httpOa.get('/user/promo?size=-1').then(res => {
      store.commit('update', {
        key: 'sellerList',
        value: res.list || []
      })
    })
  },
  getPromoList(store) {
    return http.get('/boom-center-product-service/sysAdmin/promotionAccount').then(res => {
      let arr = []

      let obj = { id: '0', promotionAccountName: '公众号', children: [] }
      let obj1 = { id: '1', promotionAccountName: '抖音', children: [] }
      res.forEach(item => {
        if (item.promotionAccountType === 0) {
          obj.children.push(item)
        } else {
          obj1.children.push(item)
        }
      })
      arr.push(obj, obj1)

      store.commit('update', {
        key: 'promoList',
        value: arr || []
      })
      return
    })
  },
  getBookingRuleList(store) {
    if (!store.rootGetters['product/partner/businessInfo'] || !store.rootGetters['product/partner/businessInfo'].id) {
      return Promise.reject({ msg: '参数错误' })
    }
    return http
      .get(
        `/boom-center-product-service/sysAdmin/bookingRule/all?businessId=${store.rootGetters['product/partner/businessInfo'].id}`
      )
      .then(res => {
        let list = res || []
        list.unshift({
          id: '',
          ruleName: '无需预约'
        })
        store.commit('update', {
          key: 'bookingRuleList',
          value: list
        })
        return list
      })
  }
}

export default {
  namespaced: true,
  state,
  getters,
  mutations,
  actions
}
