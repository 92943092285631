import axios from 'axios'
import { logAxiosSuccess, logAxiosError } from '@/util/log'

let config = {
  baseURL: process.env.VUE_APP_OA_BASE_URL || '//api.oa.boomsj.com/api/',
  timeout: 65 * 1000,
  withCredentials: false,
  headers: {
    'content-type': 'application/json',
    'x-access-token': 'thisIsAVeryLongTokenThatForBmMallApp'
  }
}

const instance = axios.create(config)

instance.interceptors.request.use(
  config => {
    return config
  },
  error => {
    return Promise.reject(error)
  }
)

instance.interceptors.response.use(
  response => {
    if (response.data.err === 0) {
      logAxiosSuccess(response)
      return response.data.data || response.data
    } else {
      logAxiosError(response)
      return Promise.reject(response.data)
    }
  },
  error => {
    logAxiosError(error)
    return Promise.reject(error)
  }
)

export default instance
