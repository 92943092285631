import http from '@/plugin/axios'
const lotteryService = 'boom-center-product-service/'
const mixService = 'boom-mix-biz-service/'
let httpLottery = {
  lotteryPage(params) {
    return http.get(lotteryService + 'sysAdmin/overlordMeal/findPage', params)
  },
  startLottery(params, config) {
    return http.post(mixService + `sysAdmin/overlordMeal/lottery/${params}`, config)
  },
  record(params) {
    return http.get(lotteryService + 'sysAdmin/overlordMeal/participateRecord/findPage', params)
  },
  detail(params, config) {
    return http.get(lotteryService + `sysAdmin/overlordMeal/${params}`, config)
  },
  addLottery(params, config) {
    return http.post(lotteryService + 'sysAdmin/overlordMeal', params, config)
  },
  editLottery(params, config) {
    return http.put(lotteryService + 'sysAdmin/overlordMeal', params, config)
  },
  delLottery(params, config) {
    return http.delete(lotteryService + `sysAdmin/overlordMeal/${params}`, config)
  },
  getProductList(params) {
    return http.get(lotteryService + 'sysAdmin/product/findPageInPlatformStock', params)
  }
}
export function lottery(tag, params, setting) {
  return httpLottery[tag](params, setting)
}
