import { render, staticRenderFns } from "./seckill_2.vue?vue&type=template&id=01b62bc2&scoped=true&"
import script from "./seckill_2.vue?vue&type=script&lang=js&"
export * from "./seckill_2.vue?vue&type=script&lang=js&"
import style0 from "./seckill_2.vue?vue&type=style&index=0&id=01b62bc2&lang=less&scoped=true&"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "01b62bc2",
  null
  
)

export default component.exports