import Vue from 'vue'
import Vuex from 'vuex'
import createPersistedState from 'vuex-persistedstate'

Vue.use(Vuex)

let modules = {}
const moduleList = require.context('@/store', true, /\.js$/)
moduleList.keys().forEach(fileName => {
  const moduleName = fileName.substring(2, fileName.length - 3)
  if (moduleName.split('/').length === 1) {
    modules[moduleName] = moduleList(fileName).default
  } else {
    modules[moduleName.split('/')[0]] = modules[moduleName.split('/')[0]] || { namespaced: true, modules: {} }
    modules[moduleName.split('/')[0]].modules[moduleName.split('/')[1]] = moduleList(fileName).default
  }
})
console.log(modules)
export default new Vuex.Store({
  modules,
  plugins: [
    createPersistedState({
      paths: ['cache', 'user', 'product.partner', 'vendor.params', 'lottery', 'seckill']
    })
  ]
})
