<template>
  <div class="seckill">
    <template v-if="!canEdit">
      <template v-if="cptData.data.length">
        <div class="seckill_header">
          <span class="seckill_title">限时秒杀</span>
          <countDown :data="countTime" />
          <span class="more">
            <span>更多</span>
            <i class="el-icon-d-arrow-right"></i>
          </span>
        </div>
        <div class="seckill_content">
          <img class="pic" :src="firstActivity.productImageUrl" />
          <div class="info">
            <p>商品名称: {{ firstActivity.name || '' }}</p>
            <div>
              <span>商品价格: {{ firstActivity.secKillPrice }}</span>
              <span class="sales">已售: {{ firstActivity.secKillSaleCount }}</span>
            </div>
          </div>
        </div>
      </template>
      <template v-else>
        <p class="remindText">秒杀组件</p>
      </template>
    </template>
    <template v-else>
      <div class="seckill-edit">
        <p class="c-e-header">秒杀组件</p>
        <table class="seckill-global">
          <tr>
            <td>状态</td>
            <td>
              <el-switch @change="ifOpen" v-model="hideStatus" :active-value="0" :inactive-value="1"></el-switch>
            </td>
          </tr>
          <tr class="seckill-main-img">
            <td>活动主图</td>
            <td>
              <bm-upload v-model="secKillMainImg" @change="getOrAddMainImg"></bm-upload>
              <div class="size">建议尺寸750 * 850</div>
              <div class="notAllowempty" v-if="!secKillMainImg">没有选择秒杀主图</div>
            </td>
          </tr>
        </table>
        <template v-if="dataList.length">
          <el-collapse v-model="activeName" :accordion="true">
            <div v-for="(classifyData, i) in dataList" :key="i" :index="i">
              <el-collapse-item :name="cptData.componentName + i" :key="'seckill' + i">
                <template slot="title">
                  <span style="margin-left: 10px; font-weight: 700">{{ i + 1 + '.' + classifyData.name }}</span>
                </template>

                <el-form
                  ref="seckillRef"
                  :model="classifyData"
                  class="vendorFrom"
                  label-position="right"
                  label-width="100px"
                >
                  <el-form-item label="活动名称">
                    <el-input v-model="classifyData.name" placeholder="请输入活动名称" disabled></el-input>
                  </el-form-item>
                  <el-form-item label="活动名称">
                    <el-input v-model="classifyData.name" placeholder="请输入活动名称" disabled></el-input>
                  </el-form-item>
                  <el-form-item label="起止时间" prop="dateRange">
                    <el-date-picker
                      v-model="classifyData.dateRange"
                      type="datetimerange"
                      :default-time="['00:00:00', '23:59:59']"
                      value-format="timestamp"
                      range-separator="至"
                      start-placeholder="开始日期"
                      end-placeholder="结束日期"
                      disabled
                    />
                  </el-form-item>
                </el-form>
              </el-collapse-item>
            </div>
          </el-collapse>
        </template>
        <template v-else> 限时秒杀活动中未有开启的活动 </template>
      </div>
    </template>
  </div>
</template>

<script>
import CountDown from './__com__/countdown.vue'

export default {
  name: 'seckill',
  components: {
    CountDown
  },
  inject: ['addidtocomponent'],
  props: {
    cptData: {
      type: Object,
      default() {
        return {
          componentName: 'seckill',
          selectStatus: false,
          data: []
        }
      }
    },
    canEdit: {
      type: Boolean,
      default: false,
      comment: '是否编辑状态， true是，会在装修页面右侧调用'
    }
  },
  data() {
    return {
      loading: false,
      activeName: this.cptData.componentName + '0',
      dataList: [],
      secKillMainImg: '',
      hideStatus: 0,
      areaId: this.$store.getters['malls/getCurrentEditAreaId']
    }
  },
  computed: {
    firstActivity() {
      if (!this.cptData.data.length) return
      return this.cptData.data[0]
    },
    countTime() {
      if (!this.cptData.data.length) return
      let diff = this.cptData.data[0].endTime - this.cptData.data[0].startTime
      let days = parseInt(diff / (1000 * 60 * 60 * 24))
      let hours = parseInt((diff % (1000 * 60 * 60 * 24)) / (1000 * 60 * 60)) + days * 24
      let minutes = parseInt((diff % (1000 * 60 * 60)) / (1000 * 60))
      let seconds = (diff % (1000 * 60)) / 1000

      return {
        h: hours < 10 ? '0' + hours : hours,
        m: minutes < 10 ? '0' + minutes : minutes,
        s: seconds < 10 ? '0' + seconds : seconds
      }
    }
  },
  watch: {
    cptData: {
      handler(nV) {
        if (!Array.isArray(nV.data)) return
        console.log(typeof nV.data)
        this.dataList = nV.data.map(c => {
          return {
            name: c.name,
            dateRange: [c.startTime, c.endTime]
          }
        })
        this.hideStatus = nV.activeStatus ? 0 : 1
      },
      immediate: true,
      deep: true
    }
  },
  created() {
    this.getOrAddMainImg()
  },
  methods: {
    ifOpen() {
      this.addidtocomponent({
        activeStatus: this.hideStatus == 0 ? true : false
      })
    },
    async getOrAddMainImg() {
      let res = await this.$api.mall.mall(
        'postMainImg',
        {
          mainImage: this.secKillMainImg
        },
        {
          headers: {
            ['Bm-Area-Id']: this.areaId
          }
        }
      )
      this.secKillMainImg = res.code == 0 ? res.data : res
    }
  }
}
</script>

<style lang="less" scoped>
@import './com.less';
.seckill {
  width: 100%;
  .seckill_header {
    display: flex;
    align-items: center;
    .seckill_title {
      margin-right: 10px;
    }
    .more {
      margin-left: auto;
    }
  }
  .seckill_content {
    display: flex;
    align-items: flex-start;
    margin-top: 10px;
    .pic {
      width: 120px;
      height: 120px;
      display: block;
      object-fit: contain;
    }
    .info {
      height: 120px;
      display: flex;
      flex-direction: column;
      justify-content: space-between;
      flex: 1;
      padding: 10px;
      .sales {
        float: right;
      }
    }
  }
  .remindText {
    min-height: 150px;
    line-height: 140px;
    text-align: center;
    color: #999999;
    border: 1px dotted #999999;
  }
  .seckill-edit {
    padding: 25px;
    background-color: @color-background;
    .c-e-header {
      margin-bottom: 20px;
      font-weight: 700;
    }
    .seckill-global {
      td {
        padding: 10px;
        vertical-align: top;
      }
      td:nth-child(1) {
        width: 100px;
        &:before {
          content: '*';
          color: red;
          margin-right: 5px;
        }
      }
      .seckill-main-img td {
        .notAllowempty {
          color: red;
          font-size: 14px;
        }
        .size {
          color: #999999;
          margin-top: 2px;
          font-size: 12px;
        }
      }
    }
  }
}
</style>
