<!--
1上2下
-->
<template>
  <div class="cube-1">
    <template v-if="!canEdit">
      <div class="cube-1-content">
        <el-row :gutter="10">
          <template v-if="cptData.data[0] && cptData.data[0].imgNode">
            <template v-for="(d, i) in cptData.data[0].imgNode">
              <el-col :span="12" :key="i" class="cube-item"><img :src="d.imagePath" alt="" /> </el-col>
            </template>
          </template>
        </el-row>
      </div>
    </template>
    <template v-else>
      <div class="cube-1-edit">
        <p class="c-e-header">魔方组件-左2右2</p>
        <div class="cube-content" v-for="(classifyData, i) in cptData.data" :key="'cube-1' + i">
          <el-form
            ref="form"
            :rules="rules"
            :model="classifyData"
            class="vendorFrom"
            label-position="right"
            label-width="100px"
          >
            <el-form-item label="状态">
              <el-switch v-model="classifyData.hideStatus" :active-value="0" :inactive-value="1"></el-switch>
            </el-form-item>
            <el-form-item label="商圈图片" prop="imgs">
              <div class="cube-img-block">
                <div v-for="(d, i) in classifyData.imgNode" :key="'cube-img-item' + i" class="cube-img-item">
                  <span>a{{ i + 1 }}</span>
                  <bm-upload v-model="d.imagePath" type="system"></bm-upload>
                </div>
              </div>
              <div>164.5px * 69.5px</div>
            </el-form-item>
            <el-form-item label="组件名称" prop="name">
              <el-input v-model="classifyData.name" placeholder="输入自定义名称"></el-input>
            </el-form-item>
            <template v-for="(d, i) in classifyData.imgNode">
              <el-form-item :label="`a${i + 1}图片关联`" :key="'cube-img-tar' + i">
                <el-radio-group v-model="d.type" @change="initRadio(i)" prop="type">
                  <el-radio :value="1" :label="1">跳转活动</el-radio>
                  <el-radio :value="2" :label="2">跳转路径</el-radio>
                </el-radio-group>
              </el-form-item>
              <el-form-item :key="'cube-img-tar1' + i" prop="target">
                <activitySelect v-model="d.target" v-if="d.type == 1"></activitySelect>
                <el-input v-model="d.target" v-else placeholder="输入跳转路径"></el-input>
              </el-form-item>
            </template>
            <el-form-item v-loading="loading">
              <!-- <el-button type="primary" @click="list">列表</el-button> -->
              <el-button type="primary" @click="save(i)">保存</el-button>
              <el-button type="danger" @click="remove(i)">删除</el-button>
            </el-form-item>
          </el-form>
        </div>
      </div>
    </template>
  </div>
</template>
<script>
import { log } from '@/util/log'
import activitySelect from './__com__/activitySelect'

export default {
  name: 'cube-1',
  inject: ['addidtocomponent'],
  components: { activitySelect },
  props: {
    cptData: {
      type: Object,
      default: {
        componentName: 'classify',
        selectStatus: false,
        data: [
          {
            imgNode: [],
            bannerNode: [],
            hideStatus: 0
          }
        ]
      },
      require: true
    },
    canEdit: {
      type: Boolean,
      default: false,
      require: false,
      comment: '是否编辑状态， true是，会在装修页面右侧调用'
    }
  },
  data() {
    return {
      loading: false,
      defaultImg: require('@/asset/logo.png'),
      areaId: this.$store.getters['malls/getCurrentEditAreaId'],

      rules: {
        imgs: [
          {
            required: true,
            trigger: 'change',
            validator: (rule, value, callback) => {
              for (let i of this.cptData.data[0].imgNode) {
                if (!i.imagePath) {
                  return callback(new Error('选择图片'))
                }
              }
              callback()
            }
          }
        ],
        name: [{ required: true, message: '输入名字', trigger: 'change' }],
        type: [
          {
            required: true,
            trigger: 'change',
            validator: (rule, value, callback) => {
              callback()
            }
          }
        ],
        target: [
          {
            required: true,
            trigger: 'change',
            validator: (rule, value, callback) => {
              for (let i of this.cptData.data[0].imgNode) {
                if (!i.target) {
                  return callback(new Error('跳转活动或链接必填'))
                }
              }
              callback()
            }
          }
        ]
      },
      model: {
        id: '',
        hideStatus: 0,
        name: '',
        type: 0,
        bannerNode: [
          // {
          //   imagePath: '',
          //   target: '',
          //   type: 0
          // },
        ],
        imgNode: [
          {
            imagePath: '',
            target: '',
            type: 0
          },
          {
            imagePath: '',
            target: '',
            type: 0
          },
          {
            imagePath: '',
            target: '',
            type: 0
          },
          {
            imagePath: '',
            target: '',
            type: 0
          }
        ]
      }
    }
  },
  created() {
    if (!this.cptData.data.length) {
      this.cptData.data.push({ ...this.model })
    }
  },
  methods: {
    initRadio(i) {
      if (this.cptData.data[0].imgNode[i].type == 1) {
        this.cptData.data[0].imgNode[i].target = ''
      }
    },
    save() {
      this.$refs['form'][0].validate(valid => {
        if (valid) {
          let tag = ''
          if (!this.cptData.data[0].id) {
            tag = 'postMagicCube'
          } else {
            tag = 'putMagicCube'
          }
          this.loading = true
          this.$api.mall
            .mall(tag, this.cptData.data[0], {
              headers: {
                ['Bm-Area-Id']: this.areaId
              }
            })
            .then(res => {
              this.addidtocomponent({
                id: res.id,
                activeStatus: res.hideStatus == 0 ? true : false
              })
              this.$message.success('保存成功')
            })
            .catch(err => {
              this.$message.error(err)
            })
            .finally(() => {
              setTimeout(() => {
                this.loading = false
              }, 200)
            })
        }
      })
    },
    async remove(index) {
      if (!this.cptData.data[0].id) {
        this.$message.info('无数据，无须删除')
        return
      }
      let res = await this.$confirm('此操作将永久删除当前编辑数据, 是否继续?', '提示', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning'
      })
      if (res == 'confirm') {
        this.loading = true
        this.$api.mall
          .mall('deleteMagicCube', this.cptData.data[0].id)
          .then(() => {
            this.addidtocomponent({ id: '', activeStatus: '' })
            this.$message.success('移除成功')
          })
          .catch(err => {
            this.$message.error(err)
          })
          .finally(() => {
            setTimeout(() => {
              this.loading = false
            }, 200)
          })
      } else {
        this.$message({
          type: 'info',
          message: '已取消删除'
        })
      }
    }
  }
}
</script>
<style lang='less' scoped>
@import './com.less';
.cube-1 {
  .cube-1-content {
    min-height: 78px;
    padding: 3px 5px;
    .cube-item {
      padding: 3px;
      img {
        width: 100%;
        height: 68px;
      }
    }
  }
  .cube-1-edit {
    padding: 25px;
    background-color: @color-background;
    .c-e-header {
      margin-bottom: 20px;
      font-weight: 700;
    }
    .cube-content {
      .cube-img-block {
        width: 60%;
        display: inline-block;
        .cube-img-item {
          display: inline-block;
          width: 50%;
          position: relative;
          & > span {
            background-color: rgba(0, 0, 0, 0.1);
            position: absolute;
            .radius(6px);
            display: inline-block;
            padding: 0 5px;
            top: 0;
            left: 0;
            z-index: 10;
          }
        }
      }
    }
  }
}
</style>
