// @/component/common 目录下的文件自动挂载为组件
import Vue from 'vue'

const moduleList = require.context('@/component/common', false, /\.vue$/)
moduleList.keys().forEach(fileName => {
  const moduleName = 'bm-' + fileName.substring(2, fileName.length - 4)
  Vue.component(moduleName, moduleList(fileName).default)
})

// 引入装修相关组件
const moduleList2 = require.context('@/component/adorn', false, /\.vue$/)
moduleList2.keys().forEach(fileName => {
  const moduleName = 'adorn-' + fileName.substring(2, fileName.length - 4)
  Vue.component(moduleName, moduleList2(fileName).default)
})
