<template>
  <div class="activity-select">
    <el-select :value="value" @input="activityChange">
      <template v-for="(d, i) in activityList">
        <el-option :key="'activity' + i" :value="d.id" :label="d.name"> </el-option>
      </template>
    </el-select>
  </div>
</template>

<script>
export default {
  name: 'activity-select',
  props: {
    value: {
      type: String,
      require: true
    }
  },
  data() {
    return {
      activityList: [],
      areaId: this.$store.getters['malls/getCurrentEditAreaId']
    }
  },
  created() {
    this.getActiveData()
  },
  methods: {
    activityChange(v) {
      this.$emit('input', v)
    },
    async getActiveData() {
      // 1. 获取活动列表
      let res = await this.$api.mall.mall('getActivityList', {
        params: {
          displayStatus: 1
        },
        headers: {
          ['Bm-Area-Id']: this.areaId
        }
      })
      for (let r of res) {
        // 2. 获取活动列表 详情
        this.$api.mall.mall('getActivityDetail', r.id).then(detail => {
          this.activityList.push(detail)
        })
      }
    }
  }
}
</script>

<style></style>
