<template>
  <div class="date_select">
    <i class="el-icon-caret-left" @click="handleDate(-1)" style="cursor: pointer; padding: 10px"></i>
    <span>{{ startTime }}</span>
    <span>至</span>
    <span>{{ endTime }}</span>
    <i class="el-icon-caret-right" @click="handleDate(1)" :style="nextStyle"></i>
  </div>
</template>

<script>
const monthTimeStamp = 2592000000
const weekTimeStamp = 604800000
const sixMonthTimeStamp = 15552000001
export default {
  props: {
    nextStyle: {},
    dateType: {
      type: String,
      validator: function (value) {
        return ['month', 'week', 'halfYear'].indexOf(value) !== -1
      }
    }
  },
  data(vm) {
    var dt = new Date(new Date().getFullYear(), new Date().getMonth() - 1, 1)
    return {
      startTime: '',
      endTime: '',
      filterDate: {
        startTime:
          this.dateType === 'halfYear'
            ? new Date(new Date().toLocaleDateString()).getTime() - sixMonthTimeStamp
            : dt.getTime(),
        endTime: new Date().getTime()
      }
    }
  },
  computed: {},
  watch: {
    filterDate: {
      handler(nV) {
        this.$emit('get-date', nV)
      },
      deep: true,
      immediate: true
    }
  },
  created() {
    this.initDataMethod()
    this.nextStyleMethod()
  },
  methods: {
    nextStyleMethod() {
      const isSame =
        new Date(this.filterDate.endTime).getFullYear() === new Date().getFullYear() &&
        new Date(this.filterDate.endTime).getMonth() === new Date().getMonth()
      console.log(new Date(this.filterDate.endTime).getMonth())
      console.log(new Date().getMonth())
      console.log(isSame)
      this.nextStyle = {
        pointerEvents: isSame ? 'none' : 'auto',
        cursor: isSame ? 'auto' : 'pointer',
        padding: '10px',
        color: isSame ? '#909399' : '#000'
      }
    },
    initDataMethod() {
      let start = new Date(this.filterDate.startTime)
      let end = new Date(this.filterDate.endTime)

      console.log(start.getFullYear())
      console.log(end.getFullYear())
      let monthFormat = {
        startTime: start.getFullYear() + '-' + String(start.getMonth() + 1).padStart(2, '0'),
        endTime: end.getFullYear() + '-' + String(end.getMonth() + 1).padStart(2, '0')
      }

      this.$set(this, 'startTime', monthFormat.startTime)
      this.$set(this, 'endTime', monthFormat.endTime)

      console.log(this.startTime)
    },
    handleDate(type) {
      if (type === -1) {
        // 后退
        if (this.dateType === 'week') {
          this.filterDate.startTime = this.filterDate.startTime - weekTimeStamp
          this.filterDate.endTime = this.filterDate.endTime - weekTimeStamp
          return
        }
        let startDate = new Date(this.filterDate.startTime)
        let endDate = new Date(this.filterDate.endTime)
        let day = new Date(endDate.getFullYear(), endDate.getMonth(), 0).getDate()
        let dt = new Date(startDate.getFullYear(), startDate.getMonth() - 1, 1)
        let dt2 = new Date(endDate.getFullYear(), endDate.getMonth() - 1, day)
        this.filterDate.startTime = dt.getTime()
        this.filterDate.endTime = dt2.getTime()
      } else {
        // 前进
        if (this.dateType === 'week') {
          this.filterDate.startTime = this.filterDate.startTime + weekTimeStamp
          this.filterDate.endTime = this.filterDate.endTime + weekTimeStamp
          return
        }
        let startDate = new Date(this.filterDate.startTime)
        let endDate = new Date(this.filterDate.endTime)

        // let day = new Date(endDate.getFullYear(), endDate.getMonth(), 0).getDate()
        let dt = new Date(startDate.getFullYear(), startDate.getMonth() + 1, 1)
        let dt2 = new Date(endDate.getFullYear(), endDate.getMonth() + 1)

        this.filterDate.startTime = dt.getTime()
        this.filterDate.endTime = dt2.getTime()
      }
      this.initDataMethod()
      this.nextStyleMethod()
    }
  }
}
</script>

<style lang="less" scoped>
.date_select {
  background: #f5f5f5;
  border-radius: 30px;
}
</style>
