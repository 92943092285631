<template>
  <div class="countdown">
    <span class="time">{{ data.h || '00' }}</span>
    <span class="text"> ：</span>
    <span class="time">{{ data.m || '00' }}</span>
    <span class="text"> ：</span>
    <span class="time">{{ data.s || '00' }}</span>
  </div>
</template>

<script>
import { mapGetters, mapActions } from 'vuex'

export default {
  props: ['data'],
  data() {
    return {
      foo: 'bar'
    }
  },
  computed: {
    ...mapGetters({})
  },
  watch: {},
  methods: {
    ...mapActions({})
  },
  created() {},
  mounted() {}
}
</script>

<style lang="less" scoped>
.countdown {
  .time {
    display: inline-block;
    width: 30px;
    height: 30px;
    line-height: 30px;
    text-align: center;
    background: #000;
    margin-right: 5px;
    color: #fff;
  }
  .text {
    margin-right: 5px;
  }
}
</style>
