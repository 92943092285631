import http from '@/plugin/axios'
const productService = 'boom-center-product-service/'
let httpSeckill = {
  saleActivity(params) {
    return http.get(productService + 'sysAdmin/limitedTimeSaleActivity', params)
  },
  addSaleActivity(params, config) {
    return http.post(productService + 'sysAdmin/limitedTimeSaleActivity', params, config)
  },
  editSaleActivity(params, config) {
    return http.put(productService + 'sysAdmin/limitedTimeSaleActivity', params, config)
  },
  delSaleActivity(params, config) {
    return http.delete(productService + `sysAdmin/limitedTimeSaleActivity/${params}`, config)
  },
  getSaleActivity(params, config) {
    return http.get(productService + `sysAdmin/limitedTimeSaleActivity/${params}`, config)
  },
  startSaleActivity(id, config) {
    return http.put(productService + `sysAdmin/limitedTimeSaleActivity/start?id=${id}`, {}, config)
  },
  // 根据秒杀活动Id,查询需要绑定的商品
  getSecKillNotRelation(params, id) {
    return http.get(productService + 'sysAdmin/secKillRelation/pageByNotRelationProducts', params)
  },
  // 根据秒杀活动Id查询已绑定商品列表
  getSecKillRelation(params, id) {
    return http.get(productService + `sysAdmin/secKillRelation/${id}`, params)
  },
  //保存关联关系锁定sku库存
  saveSecKillRelation(params, config) {
    return http.post(productService + 'sysAdmin/secKillRelation', params, config)
  },
  //保存单个关联关系锁定sku库存
  saveSingleSecKillRelation(params, config) {
    return http.post(productService + 'sysAdmin/secKillRelation/v1', params, config)
  },
  saveProductCount(id, config) {
    return http.put(productService + `sysAdmin/limitedTimeSaleActivity/productCount?id=${id}`, {}, config)
  }
}
export function seckill(tag, params, setting) {
  return httpSeckill[tag](params, setting)
}
