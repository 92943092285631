const state = {
  currentBusiness: {
    id: '',
    name: '',
    logo: ''
  }
}
const getters = {
  currentBusiness: state => state.currentBusiness,
  isExistCurrentBusiness: state => state.currentBusiness.id && state.currentBusiness.name
}
const mutations = {
  update: (state, { key, value }) => (state[key] = value),
  updateCurrentBusiness: (state, payload) => {
    console.log('设置', payload)
    return (state.currentBusiness = {
      id: payload.id || payload.businessId,
      name: payload.businessTitle || payload.name,
      logo: payload.businessLogo || payload.logo
    })
  },
  clearCurrentBusiness: state => (state.currentBusiness = { id: '', name: '', logo: '' })
}
const actions = {}

export default {
  namespaced: true,
  state,
  getters,
  mutations,
  actions
}
