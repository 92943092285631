/*
 * @Author: Link
 * @Date: 2020-11-07 09:09:45
 * @LastEditTime: 2021-04-09 10:50:07
 */
import http from '@/plugin/axios'

const productService = 'boom-center-product-service/'
const adminService = 'boom-center-admin-service/'
const searchService = 'boom-center-search-service/'
const mixService = 'boom-mix-biz-service/'
const httpProduct = {
  // =============================商品=====================
  // 获取商品列表

  // getProductList(params) {
  //   return http.get(productService + 'sysAdmin/product', params)
  // },

  getProductList(params, config) {
    return http.post(searchService + 'sysAdmin/product/list', params, config)
  },

  // 商品上下架
  reverseProductShelfStatus(params) {
    return http.post(productService + 'sysAdmin/product/reverseShelfStatus/', params)
  },

  // 获取单个商品详情

  getProductDetail(params) {
    return http.get(productService + 'sysAdmin/product/' + params)
  },
  // 添加商品
  postProduct(params) {
    return http.post(productService + 'sysAdmin/product', params)
  },

  // 修改商品
  putProduct(params) {
    return http.put(productService + 'sysAdmin/product', params)
  },

  // 获取服务详情
  productService(params) {
    return http.get(productService + 'sysAdmin/productService/', params)
  },
  // 新增服务详情
  postproductService(params) {
    return http.post(productService + 'sysAdmin/productService/', params)
  },
  getPromotionAccount(params) {
    return http.get(productService + 'sysAdmin/promotionAccount', params)
  },
  // 获取隐藏设置列表
  findHideSettingList(id, params) {
    return http.get(productService + `sysAdmin/product/findHideSettingList/${id}`, params)
  },
  // 隐藏设置
  hideSetting(params) {
    return http.put(productService + `sysAdmin/product/hideSetting`, params)
  },

  // =====================================商品分类 =====================
  // 添加商品分类
  getProductCategory(id) {
    return http.get(productService + `sysAdmin/productCategory/${id}`)
  },
  postProductCategory(params) {
    return http.post(productService + 'sysAdmin/productCategory/', params)
  },
  // 更新商品分类
  putProductCategory(params) {
    return http.put(productService + 'sysAdmin/productCategory/', params)
  },

  // 商品分类所有分类信息列表

  productCategoryAll(params) {
    return http.get(productService + 'sysAdmin/productCategory/all/', params)
  },

  // 根据父id查询商品分类信息列表
  productCategoryAllId(params) {
    return http.get(productService + 'sysAdmin/productCategory/all/', params)
  },

  // 商品一级分类查询列表
  firstLevelList(params) {
    return http.get(productService + 'sysAdmin/productCategory/firstLevelList/', params)
  },

  // 商品一级分类查询列表
  firstLevelList(params) {
    return http.get(productService + 'sysAdmin/productCategory/firstLevelList', params)
  },
  // 商品二级分类查询列表
  secondLevelList(params) {
    return http.get(productService + 'sysAdmin/productCategory/secondLevelList', params)
  },

  // 商品三级分类查询列表
  thirdLevelList(params) {
    return http.get(productService + 'sysAdmin/productCategory/thirdLevelList', params)
  },

  // =====================================商品评论 =====================

  // 评论列表
  userReviewList(params) {
    return http.get(adminService + 'sysAdmin/userReview/search/review', params)
  },

  // 评论显示隐藏状态
  updateShowStatus(id, showStatus) {
    return http.put(adminService + `sysAdmin/userReview/updateShowStatus?id=${id}&showStatus=${showStatus}`)
  },

  // 查看单个评论详情
  reviewDetail(params) {
    return http.get(mixService + 'sysAdmin/userReview/' + params)
  },

  // =====================================商家评价 =====================
  // 评价类型列表
  selectList(params) {
    return http.get(productService + 'sysAdmin/reviewType/selectList', params)
  },

  addReviewType(params) {
    return http.post(productService + 'sysAdmin/reviewType', params)
  },

  updateReviewType(params) {
    return http.put(productService + 'sysAdmin/reviewType', params)
  },

  // ==============商品活动=====================
  // 查询活动列表

  activityList(params, config) {
    return http.post(adminService + 'sysAdmin/activity/list', params, config)
  },

  // 添加活动
  postActivity(params, config) {
    return http.post(adminService + 'sysAdmin/productGroup', params, config)
  },

  // 添加活动
  putActivity(params, config) {
    return http.put(adminService + 'sysAdmin/productGroup', params, config)
  },

  // 活动关联商品
  activityRelationProduct(params, config) {
    return http.post(adminService + 'sysAdmin/productGroup/relationProduct', params, config)
  },

  // 根据排序搜索商品

  activityProductList(params) {
    return http.get(searchService + 'sysAdmin/activity/product', params)
  },
  //活动管关联的商品

  // 活动关联商品列表
  getActivityProductList(params, config) {
    return http.post(productService + 'sysAdmin/activity/relationProductList', params, config)
  },
  deleteActivityProduct(params, config) {
    return http.post(adminService + 'sysAdmin/productGroup/deleteRelationProduct', params, config)
  },

  // ============商品关联销售人员=============
  // 搜索销售人员
  getSaleList(params) {
    return http.get(adminService + 'sysAdmin/salesman', params)
  },
  // 人工添加营销人员
  postSale(params) {
    return http.post(adminService + 'sysAdmin/salesman', params)
  },
  // 修改营销人员信息
  putSale(params) {
    return http.put(adminService + 'sysAdmin/salesman', params)
  },
  // 关联商品-显示/隐藏
  reverseHideStatus(params) {
    return http.post(adminService + 'sysAdmin/productGroup/relationProduct/reverseHideStatus', params)
  },
  // 预约信息
  getBookingData(params) {
    return http.get(productService + 'sysAdmin/booking/store/product', params)
  },
  // 更新预约资源
  updateResource(params) {
    return http.put(productService + 'sysAdmin/booking/updateResource', params)
  },
  // 编辑商品的门店预约规则
  bookingRule(id, params) {
    return http.put(productService + `sysAdmin/product/bookingRule/${id}`, params)
  },
  // 串货商品
  getSharedProduct(params) {
    return http.get(mixService + 'sysAdmin/sharedProduct/findPage', params)
  },
  // 新增串货商品
  addSharedProduct(params, config) {
    return http.post(mixService + 'sysAdmin/sharedProduct', params, config)
  },
  // 编辑串货商品
  editSharedProduct(params, config) {
    return http.put(mixService + 'sysAdmin/sharedProduct', params, config)
  },
  // 串货商品上下架
  reverseShelfStatus(params) {
    return http.put(mixService + 'sysAdmin/sharedProduct/reverseShelfStatus', params)
  },
  // 商品取消绑定工单
  unbindIssue(id) {
    return http.put(productService + `sysAdmin/product/unbindIssue/${id}`)
  },
  // 未共享商品
  getSaleProductList(params) {
    return http.get(mixService + 'sysAdmin/product/findNotSharedProductPage', params)
  },
  // 串货商品删除
  delSharedProduct(params) {
    return http.delete(mixService + 'sysAdmin/sharedProduct', params)
  },
  // 未关联商品列表
  getNotRelationProduct(params) {
    return http.get(adminService + 'sysAdmin/productGroup/notRelationProduct/findPage', params)
  }
}

export function product(tag, params, config) {
  return httpProduct[tag](params, config)
}

export function getTemplate(id = 0) {
  id += 1
  let img = ''
  let name = '贪吃'
  let headerImg = 'https://bm-mall-file.oss-cn-shenzhen.aliyuncs.com/pcMaterial/通栏动图.gif'
  let path = 'https://bm-mall-file.oss-cn-shenzhen.aliyuncs.com/pcMaterial/'
  if (id === 1) {
    img = `${path}贪吃商城服务号.gif`
    name = '贪吃商城服务号'
  } else if (id === 2) {
    img = `${path}番禺贪吃商城.gif`
    name = '番禺贪吃商城'
  } else if (id === 3) {
    img = `${path}深圳贪吃商城.gif`
    name = '深圳贪吃商城'
  } else if (id === 4) {
    img = `${path}佛山贪吃商城.gif`
    name = '佛山贪吃商城'
  } else if (id === 5) {
    img = `${path}东莞贪吃商城.gif`
    name = '东莞贪吃商城'
  } else if (id === 6) {
    img = `${path}上海贪吃商城.gif`
    name = '上海贪吃商城'
  } else if (id === 7) {
    img = `${path}北京贪吃商城.gif`
    name = '北京贪吃商城'
  } else if (id === 8) {
    img = `${path}顺德贪吃商城.gif`
    name = '顺德贪吃商城'
  }
  return `<p style="white-space: normal; text-align: center;" data-mce-style="white-space: normal; text-align: center;"><span style="font-size: 10pt;" data-mce-style="font-size: 10pt;"><img src="${headerImg}" style="max-width: 100%;" data-mce-src="${headerImg}" data-mce-style="max-width: 100%;"></span></p>
  <p style="margin: 10pt 0 !important; white-space: normal; font-family: Helvetica, STHeiti STXihei, Microsoft JhengHei, Microsoft YaHei, Tohoma, Arial;text-align: center;" data-mce-style="margin: 10pt 0 !important; white-space: normal; font-family: Helvetica, STHeiti STXihei, Microsoft JhengHei, Microsoft YaHei, Tohoma, Arial;text-align: center;">
  <span style="font-family: 宋体, SimSun; font-size: 12pt; color: #262626;" data-mce-style="font-family: 宋体, SimSun; font-size: 12pt; color: #262626;"><span style="font-family: 宋体, SimSun; font-size: 12pt; color: #999999;" data-mce-style="font-family: 宋体, SimSun; font-size: 12pt; color: #999999;">-</span> <strong>有效日期</strong> <span style="font-family: 宋体, SimSun; font-size: 12pt; color: #999999;" data-mce-style="font-family: 宋体, SimSun; font-size: 12pt; color: #999999;">-</span></span></p>
  <ul><li><p style="margin: 0 !important; white-space: normal; font-family: Helvetica, STHeiti STXihei, Microsoft JhengHei, Microsoft YaHei, Tohoma, Arial;" data-mce-style="margin: 0 !important; white-space: normal; font-family: Helvetica, STHeiti STXihei, Microsoft JhengHei, Microsoft YaHei, Tohoma, Arial;">
  <span style="font-family: 宋体, SimSun; font-size: 10pt; color: #262626;" data-mce-style="font-family: 宋体, SimSun; font-size: 10pt; color: #262626;">售卖日期：2021.07.01-2021.07.31</span></p></li><li><p style="margin: 0 !important; white-space: normal; font-family: Helvetica, STHeiti STXihei, Microsoft JhengHei, Microsoft YaHei, Tohoma, Arial;" data-mce-style="margin: 0 !important; white-space: normal; font-family: Helvetica, STHeiti STXihei, Microsoft JhengHei, Microsoft YaHei, Tohoma, Arial;">
  <span style="font-family: 宋体, SimSun; font-size: 10pt; color: #262626;" data-mce-style="font-family: 宋体, SimSun; font-size: 10pt; color: #262626;">核销日期：2021.07.01-2021.08.31（8月1日不可用）</span></p></li><li><p style="margin: 0 !important; white-space: normal; font-family: Helvetica, STHeiti STXihei, Microsoft JhengHei, Microsoft YaHei, Tohoma, Arial;" data-mce-style="margin: 0 !important; white-space: normal; font-family: Helvetica, STHeiti STXihei, Microsoft JhengHei, Microsoft YaHei, Tohoma, Arial;">
  <span style="font-family: 宋体, SimSun; font-size: 10pt; color: #262626;" data-mce-style="font-family: 宋体, SimSun; font-size: 10pt; color: #262626;">可用时段：周一至周日17:00-次日02:00</span></p></li><li><p style="margin: 0 !important; white-space: normal; font-family: Helvetica, STHeiti STXihei, Microsoft JhengHei, Microsoft YaHei, Tohoma, Arial;" data-mce-style="margin: 0 !important; white-space: normal; font-family: Helvetica, STHeiti STXihei, Microsoft JhengHei, Microsoft YaHei, Tohoma, Arial;"><span style="font-size: 10pt;" data-mce-style="font-size: 10pt;">
  <span style="font-family: 宋体, SimSun; color: #262626;" data-mce-style="font-family: 宋体, SimSun; color: #262626;">可用门店：祖庙店、同济路店</span></span></p></li><li><p style="margin: 0 !important; white-space: normal; font-family: Helvetica, STHeiti STXihei, Microsoft JhengHei, Microsoft YaHei, Tohoma, Arial;" data-mce-style="margin: 0 !important; white-space: normal; font-family: Helvetica, STHeiti STXihei, Microsoft JhengHei, Microsoft YaHei, Tohoma, Arial;"><span style="font-size: 10pt;" data-mce-style="font-size: 10pt;">
  <span style="font-family: 宋体, SimSun; color: #262626;" data-mce-style="font-family: 宋体, SimSun; color: #262626;">使用须知：<span style=\"font-family: 宋体, SimSun; font-size: 10pt; color: #262626;\"><strong><span style=\"font-family: 宋体, SimSun; color: #c00000;\">请确认好是否要预约？怎么预约？是否限购限量？限用条件？适用范围？可否打包？是否有加收项目？</span></strong></span><span style="font-family: 宋体, SimSun;" data-mce-style="font-family: 宋体, SimSun;"></span></span></span></span></p></li></ul><p style="margin: 10pt 0 !important; white-space: normal; font-family: Helvetica, STHeiti STXihei, Microsoft JhengHei, Microsoft YaHei, Tohoma, Arial;text-align: center;" data-mce-style="margin: 10pt 0 !important; white-space: normal; font-family: Helvetica, STHeiti STXihei, Microsoft JhengHei, Microsoft YaHei, Tohoma, Arial;text-align: center;">
  <span style="font-family: 宋体, SimSun; font-size: 12pt; color: #262626;" data-mce-style="font-family: 宋体, SimSun; font-size: 12pt; color: #262626;"><span style="font-family: 宋体, SimSun; font-size: 12pt; color: #999999;" data-mce-style="font-family: 宋体, SimSun; font-size: 12pt; color: #999999;">-</span> <strong>套餐内容</strong> <span style="font-family: 宋体, SimSun; font-size: 12pt; color: #999999;" data-mce-style="font-family: 宋体, SimSun; font-size: 12pt; color: #999999;">-</span></span></p><p style="margin: 0 !important; white-space: normal; font-family: Helvetica, STHeiti STXihei, Microsoft JhengHei, Microsoft YaHei, Tohoma, Arial;text-align: center;" data-mce-style="margin: 0 !important; white-space: normal; font-family: Helvetica, STHeiti STXihei, Microsoft JhengHei, Microsoft YaHei, Tohoma, Arial;text-align: center;">
  <strong><span style="font-family: 宋体, SimSun; font-size: 12pt; color: #FF0000;" data-mce-style="font-family: 宋体, SimSun; font-size: 12pt; color: #FF0000;">“记得完善好套餐明细的表头，然后再把我删掉”</span></strong></p>
  <p style="margin: 10pt 0 !important; white-space: normal; font-family: Helvetica, STHeiti STXihei, Microsoft JhengHei, Microsoft YaHei, Tohoma, Arial;text-align: center;" data-mce-style="margin: 10pt 0 !important; white-space: normal; font-family: Helvetica, STHeiti STXihei, Microsoft JhengHei, Microsoft YaHei, Tohoma, Arial;text-align: center;">
  <span style="font-family: 宋体, SimSun; font-size: 12pt; color: #262626;" data-mce-style="font-family: 宋体, SimSun; font-size: 12pt; color: #262626;"><span style="font-family: 宋体, SimSun; font-size: 12pt; color: #999999;" data-mce-style="font-family: 宋体, SimSun; font-size: 12pt; color: #999999;">-</span> <strong>温馨提示</strong> <span style="font-family: 宋体, SimSun; font-size: 12pt; color: #999999;" data-mce-style="font-family: 宋体, SimSun; font-size: 12pt; color: #999999;">-</span></span></p>
  <ul><li><p style="margin: 0 !important; white-space: normal; font-family: Helvetica, STHeiti STXihei, Microsoft JhengHei, Microsoft YaHei, Tohoma, Arial;" data-mce-style="margin: 0 !important; white-space: normal; font-family: Helvetica, STHeiti STXihei, Microsoft JhengHei, Microsoft YaHei, Tohoma, Arial;"><span style="font-family: 宋体, SimSun; font-size: 10pt; color: #262626;" data-mce-style="font-family: 宋体, SimSun; font-size: 10pt; color: #262626;">
  <span style="color: #333333; font-family: 宋体, SimSun;" data-mce-style="color: #333333; font-family: 宋体, SimSun;"></span><span style="color: #333333; font-family: 宋体, SimSun;" data-mce-style="color: #333333; font-family: 宋体, SimSun;">关于退款：该产品为贪吃商城无忧购产品，<strong><span style=\"font-family: 宋体, SimSun; color: #c00000;\">未使用前可随时退款，全额原路退回；</span></strong>超过产品使用期限范围，将扣除2%运营手续费；</span></span></p></li><li><p style="margin: 0 !important; white-space: normal; font-family: Helvetica, STHeiti STXihei, Microsoft JhengHei, Microsoft YaHei, Tohoma, Arial;" data-mce-style="margin: 0 !important; white-space: normal; font-family: Helvetica, STHeiti STXihei, Microsoft JhengHei, Microsoft YaHei, Tohoma, Arial;">
  <span style="font-family: 宋体, SimSun; font-size: 10pt; color: #262626;" data-mce-style="font-family: 宋体, SimSun; font-size: 10pt; color: #262626;">此活动不可同时与店内其他优惠折扣同享，超出套餐内容费用，按店内实际收费另附；</span></p></li><li><p style="margin: 0 !important; white-space: normal; font-family: Helvetica, STHeiti STXihei, Microsoft JhengHei, Microsoft YaHei, Tohoma, Arial;" data-mce-style="margin: 0 !important; white-space: normal; font-family: Helvetica, STHeiti STXihei, Microsoft JhengHei, Microsoft YaHei, Tohoma, Arial;">
  <span style="font-family: 宋体, SimSun; font-size: 10pt; color: #262626;" data-mce-style="font-family: 宋体, SimSun; font-size: 10pt; color: #262626;"><span style="font-family: 宋体, SimSun; color: #000000;" data-mce-style="font-family: 宋体, SimSun; color: #000000;">用券金额不兑现、不找零；</span></span></p></li><li><p style="margin: 0 !important; white-space: normal; font-family: Helvetica, STHeiti STXihei, Microsoft JhengHei, Microsoft YaHei, Tohoma, Arial;" data-mce-style="margin: 0 !important; white-space: normal; font-family: Helvetica, STHeiti STXihei, Microsoft JhengHei, Microsoft YaHei, Tohoma, Arial;">
  <span style="font-family: 宋体, SimSun; font-size: 10pt; color: #262626;" data-mce-style="font-family: 宋体, SimSun; font-size: 10pt; color: #262626;">客流量大，高峰时段可能需要排队，实际排队情况、等位情况以商家门店为准；</span></p></li>\n<li><p style=\"margin: 0 !important; white-space: normal; font-family: Helvetica, STHeiti STXihei, Microsoft JhengHei, Microsoft YaHei, Tohoma, Arial;\"><span style=\"font-family: 宋体, SimSun; font-size: 10pt;\">产品仅限本人使用，不可拆分使用，严禁二次售卖；</span></p></li>\n<li><p style=\"margin: 0 !important; white-space: normal; font-family: Helvetica, STHeiti STXihei, Microsoft JhengHei, Microsoft YaHei, Tohoma, Arial;\"><span style=\"font-family: 宋体; font-size: 10pt;\">下单购买成功后，到店消费需出示电子兑换码使用；</span></p></li><li><p style=\"margin: 0 !important; white-space: normal; font-family: Helvetica, STHeiti STXihei, Microsoft JhengHei, Microsoft YaHei, Tohoma, Arial;\"><span style=\"font-family: 宋体; font-size: 10pt;\">图片仅供参考，到店请以实物为准；</span></p></li><li><p style=\"margin: 0 !important; white-space: normal; font-family: Helvetica, STHeiti STXihei, Microsoft JhengHei, Microsoft YaHei, Tohoma, Arial;\"><span style=\"font-family: 宋体; font-size: 10pt;\">在法律允许范围内，活动解释权归商家所有；</span></p></li></ul><p><br></p><p style="white-space: normal; text-align: center;" data-mce-style="white-space: normal; text-align: center;"><span style="font-size: 10pt;" data-mce-style="font-size: 10pt;"><img src="${img}" style="max-width: 100%;" data-mce-src="${img}" data-mce-style="max-width: 100%;"></span></p><p style="white-space: normal;" data-mce-style="white-space: normal;"><br></p><p style="white-space: normal;" data-mce-style="white-space: normal;"><br></p>`
  return [][id] || ''
}
