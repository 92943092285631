import http from '@/plugin/axios'
const adminService = 'boom-center-admin-service/'
const mixService = 'boom-mix-biz-service/'
const userService = 'boom-center-user-service/'
const statisticsService = 'boom-center-statistics-service/'
let httpDistribution = {
  // /sysAdmin/distribution/rule/{id}
  // 根据ID删除分销等级 @Saving

  // 分销等级列表
  distributionRuleList() {
    return http.get(adminService + 'sysAdmin/distribution/rule')
  },
  // 新增分销等级
  postDistributionRule(params) {
    return http.post(adminService + 'sysAdmin/distribution/rule', params)
  },
  // 根据ID更新分销等级信息
  putDistributionRule(params, id) {
    return http.put(adminService + 'sysAdmin/distribution/rule/' + id, params)
  },
  // 分销员列表

  distributionMemberList(params) {
    return http.get(userService + 'sysAdmin/distribution/member/pageBy', params)
  },

  // 编辑分销员

  putDistributor(params) {
    return http.put(userService + '/sysAdmin/distribution/member/updateLevelBy', params)
  },

  // 拉黑会员
  pullBlackDistribution(params) {
    return http.put(userService + 'sysAdmin/distribution/member/pullBlack', params)
  },
  cancelBlackDistribution(params) {
    return http.put(userService + 'sysAdmin/distribution/member/thaw', params)
  },

  // 分销员团队列表分页查询
  distributionMemberTeamList(params, id) {
    return http.get(userService + 'sysAdmin/distribution/member/pageTeamBy/' + id, params)
  },
  // 分销员分销记录列表分页查询
  distributionMemberBillList(params, id) {
    return http.get(userService + 'sysAdmin/distribution/bill/' + id, params)
  },
  // 分销排行榜
  distributionList(params) {
    return http.get(statisticsService + 'sysAdmin/rank/distribution', params)
  },
  // 提现记录列表
  distributionWithdrawList(params) {
    return http.get(userService + 'sysAdmin/distribution/withdraw-records', params)
  },

  // 审核通过
  approveWithdraw(id) {
    return http.put(userService + 'sysAdmin/distribution/withdraw-records/approve/' + id)
  },

  // 批量审核通过

  batchApproveWithdraw(params) {
    return http.put(userService + 'sysAdmin/distribution/withdraw-records/approve-batch', params)
  },

  // 拒绝申请
  rejectWithdraw(params) {
    return http.put(userService + 'sysAdmin/distribution/withdraw-records/reject', params)
  },

  // 批量拒绝申请
  batchRejectWithdraw(params) {
    return http.put(userService + 'sysAdmin/distribution/withdraw-records/reject-batch', params)
  },

  // 分销设置
  getDistributionSetting() {
    return http.get(adminService + 'sysAdmin/distributionSetting')
  },

  // 保存分销设置
  putDistributionSetting(params) {
    return http.put(adminService + 'sysAdmin/distributionSetting', params)
  }
}
export function distribution(tag, params, setting) {
  return httpDistribution[tag](params, setting)
}
