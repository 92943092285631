<template>
  <el-dialog :close-on-click-modal="false" title="选择地址" :visible.sync="isShow" class="mapPickerContainer">
    <div class="contentContainer">
      <iframe
        ref="iframe"
        frameborder="0"
        width="100%"
        height="600px"
        border
        @load="load"
        src="https://m.amap.com/picker/?keywords=店,商场,商铺&key=b818587ab93aacb4ec2220f878753605"
      ></iframe>
      <div class="info" v-if="location.name">
        <div class="name">
          {{ location.name }}
        </div>
        <div class="address">
          {{ location.address }}
        </div>
      </div>
    </div>
    <div slot="footer">
      <el-button v-if="!location.name" type="info" style="width:200px">确定</el-button>
      <el-button v-else type="primary" style="width:200px" @click="handleSubmit">确定</el-button>
    </div>
  </el-dialog>
</template>

<script>
import { mapGetters, mapActions } from 'vuex'

export default {
  props: {
    value: Boolean
  },
  data: () => ({
    location: {
      name: '',
      location: '',
      address: ''
    }
  }),
  computed: {
    isShow: {
      get() {
        return this.value
      },
      set(val) {
        this.$emit('input', val)
      }
    }
  },
  watch: {
    value(val) {
      val ? this.init() : this.clear()
    }
  },
  methods: {
    init() {
      this.location = {
        name: '',
        location: '',
        address: ''
      }
      window.addEventListener('message', this.handleMessage, false)
    },
    load() {
      this.$refs.iframe.contentWindow.postMessage('hello', 'https://m.amap.com/picker/')
    },
    clear() {
      window.removeEventListener('message', this.handleMessage)
    },
    handleMessage({ data, ...res }) {
      if (data && data.location && data.name) {
        this.location = { ...data }
      }
    },
    handleSubmit() {
      console.log(this.location.address)

      this.$http
        .get('boom-center-admin-service/sysAdmin/area-settings/location-info', {
          params: { latitude: this.location.location.split(',')[1], longitude: this.location.location.split(',')[0] }
        })
        .then(res => {
          this.$emit('success', {
            name: this.location.name,
            address: res.district + this.location.address,
            lat: this.location.location.split(',')[1],
            lng: this.location.location.split(',')[0]
          })
          this.isShow = false
        })
        .catch(err => {
          this.$message.error(err.msg)
        })
    }
  },
  created() {},
  mounted() {}
}
</script>

<style lang="less" scoped>
.contentContainer {
  margin-top: -30px;
  margin-bottom: -30px;
  margin-left: -20px;
  margin-right: -20px;
  iframe {
    border-bottom: 1px solid @color-border;
  }
  .info {
    background: var(--primary);
    color: white;
    display: flex;
    justify-content: space-between;
    padding: 20px;
  }
}
</style>
