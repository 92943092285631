<template>
  <span>{{ tweeningValue }}</span>
</template>

<script>
var TWEEN = require('./tween.js')
export default {
  props: {
    value: {
      type: [Number, String],
      required: true
    }
  },
  data() {
    return {
      tweeningValue: 0
    }
  },
  watch: {
    value: function(newValue, oldValue) {
      this.tween(oldValue, newValue)
    }
  },
  mounted: function() {
    this.tween(0, this.value)
  },
  methods: {
    tween: function(startValue, endValue) {
      let vm = this

      function animate(time) {
        requestAnimationFrame(animate)
        TWEEN.update(time)
      }
      new TWEEN.Tween({
        tweeningValue: startValue
      })
        .to(
          {
            tweeningValue: endValue
          },
          2000
        )
        .onUpdate(function() {
          let point = String(vm.value).indexOf('.') + 1
          let index = point === 0 ? 0 : String(vm.value).length - point
          vm.tweeningValue = this.tweeningValue.toFixed(index)
        })
        .start()
      animate()
    }
  }
}
</script>

<style></style>
