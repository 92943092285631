export function formatStatus(val) {
  return { 0: 'success', 1: 'fail' }[val]
}
export function formatArea(val) {
  let strVal = val + ''
  const sourceList = strVal.split(',')

  if (strVal.toString().includes('-1')) {
    return '全国'
  }

  if (!sessionStorage.allAreaList) {
    return sessionStorage.allAreaList
  }

  try {
    const allAreaList = JSON.parse(sessionStorage.allAreaList)

    const resultList = sourceList.map(item => {
      if (allAreaList.find(i => i.id == item)) {
        return allAreaList.find(i => i.id == item).areaName
      } else {
        return item
      }
    })
    return resultList.join(',')
  } catch (e) {
    console.log('>>>>>>> err', e)
    return strVal
  }
}


export function filterRefundAndWriteoffRadio(val) {
  console.log(val)
  switch (val) {
    case 'A+':
      return 'A+'
      break
    case 'A':
      return 'A'
      break
    case 'B':
      return 'B'
      break
    case 'C':
      return 'C'
      break
    case 'D':
      return 'D'
      break
    default:
      return '-'
      break
  }
}


export function formatManyAreas(val) {
  const allAreaList = JSON.parse(sessionStorage.allAreaList)
  const resultList = val.map(item => {
    if (allAreaList.find(i => i.id == item)) {
      return allAreaList.find(i => i.id == item).areaName
    } else {
      return item
    }
  })
  return resultList.join(',')
}
export function twoPoint(val) {
  return Number.parseFloat(val).toFixed(2)
}

/**
 * 过滤时间的过滤器
 * 可用在详情或者表格等
 * @param  Timestamp
 */
export function formatDate(value) {
  if (value === null || value === '' || value === undefined) {
    return '----'
  }
  const date = new Date(parseInt(value))
  const year = date.getFullYear()
  const month = date.getMonth() + 1 < 10 ? '0' + (date.getMonth() + 1) : date.getMonth() + 1
  const day = date.getDate() < 10 ? '0' + date.getDate() : date.getDate()
  return `${year}-${month}-${day}`
}
export function formatTime(value, filter) {
  if (value === null || value === '' || value === undefined || !value) {
    return '----'
  }
  const date = new Date(parseInt(value))
  const year = date.getFullYear()
  const month = date.getMonth() + 1 < 10 ? '0' + (date.getMonth() + 1) : date.getMonth() + 1
  const day = date.getDate() < 10 ? '0' + date.getDate() : date.getDate()
  const hh = date.getHours() < 10 ? '0' + date.getHours() : date.getHours()
  const mm = date.getMinutes() < 10 ? '0' + date.getMinutes() : date.getMinutes()
  const ss = date.getSeconds() < 10 ? '0' + date.getSeconds() : date.getSeconds()

  if (filter) {
    return `${year}-${month}-${day}`
  }
  return `${year}-${month}-${day} ${hh}:${mm}:${ss}`
}

/**
 * 过滤订单状态的过滤器
 * 可用在详情或者表格等
 * @param  Timestamp
 */
export function formatOrderStatus(value) {
  switch (value) {
    case 0:
      return '待支付'
      break
    case 1:
      return '待核销'
      break
    case 2:
      return '已核销'
      break
    case 3:
      return '已过期'
      break
    case 4:
      return '已关闭'
      break
    case 11:
      return '退款中'
      break
    case 12:
      return '已退款'
      break
    case 21:
      return '已评价'
      break
    default:
      return '没有匹配到'
      break
  }
}
export function sliceString(val, length = 15) {
  return val.toString().substring(0, length)
}
export function formatHourTime(val) {
  if (val) {
    return new Date(val * 1000).toISOString().substring(11, 16)
  }
}
export function thumbMedium(val) {
  if (!val) return ''
  if (val.includes('?x-oss-process')) return val
  return (val += '?x-oss-process=style/thumb_600')
}
export function thumbSmall(val) {
  if (!val) return ''
  if (val.includes('?x-oss-process')) return val
  return (val += '?x-oss-process=style/thumb_200')
}
export function formatPrice(val = 0) {
  return val.toFixed(2)
}



Number.prototype.parseTime = function () {
  var h = Math.floor(this / 3600)
  var min = Math.floor((this % 3600) / 60)
  h = h < 10 ? '0' + h : h
  min = min < 10 ? '0' + min : min
  return h + ':' + min
}

Number.prototype.parseDate = function () {
  if (this === null || this === '' || this === undefined) {
    return '----'
  }
  const date = new Date(this)
  const year = date.getFullYear()
  const month = date.getMonth() + 1 < 10 ? '0' + (date.getMonth() + 1) : date.getMonth() + 1
  const day = date.getDate() < 10 ? '0' + date.getDate() : date.getDate()
  return `${year}-${month}-${day}`
}
