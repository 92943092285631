import axios from 'axios'
import { logAxiosSuccess, logAxiosError } from '@/util/log'
import $store from '@/plugin/store'
import { Message } from 'element-ui'
// import { version } from './conf'

let config = {
  baseURL: process.env.VUE_APP_BASE_URL || '',
  timeout: 65 * 1000,
  withCredentials: false,
  headers: { 'content-type': 'application/json', 'Request-Source': 'EMS' }
}

let tenantId = $store.getters['global/isEnvProd'] ? '1888' : '2'
const instance = axios.create(config)

let version

if ($store.getters['global/isEnvDev'] === 'dev') {
  version = '1.17.0'
} else if ($store.getters['global/isEnvNext'] === 'next') {
  version = '1.16.0'
} else if ($store.getters['global/isEnvTest'] === 'test') {
  version = '1.17.0'
} else if ($store.getters['global/isEnvProd'] === 'prod') {
  version = '1.16.0'
}

instance.interceptors.request.use(
  config => {
    config.headers['n-d-version'] = process.env.VUE_APP_Header_version
    config.headers['Bm-Area-Id'] = config.headers['Bm-Area-Id'] || $store.getters['user/currentAreaId']
    config.headers['Tenant-Id'] = sessionStorage.tenantId || tenantId
    if (localStorage.token) {
      config.headers['Bm-Admin-Token'] = localStorage.token
    }
    return config
  },
  error => {
    return Promise.reject(error)
  }
)

instance.interceptors.response.use(
  response => {
    if (response.headers['bm-new-token']) {
      localStorage.setItem('token', response.headers['bm-new-token'])
    }
    if (response.config.url.includes(process.env.VUE_APP_BASE_URL)) {
      if ([2001, 2002, 2003].includes(response.data.code)) {
        $store.dispatch('user/logout')
        Message.error('登陆超时，自动退出')
      } else if (response.data.code === 0 || response.data.err === 0) {
        logAxiosSuccess(response)
        return response.data.data || response.data
      } else {
        logAxiosError(response)
        return Promise.reject(response.data)
      }
    } else {
      logAxiosSuccess(response)
      return response.data.data || response.data
    }
  },
  error => {
    logAxiosError(error)
    return Promise.reject(error)
  }
)

export default instance
