import $http from '@/plugin/axios'
import { log } from '@/util/log'
import { verifyAccount, verifyPassword } from '@/util/validate'

const state = {
  userInfo: {
    id: 0,
    name: '',
    avatar: 'https://demo.fastadmin.net/assets/img/avatar.png'
  },
  authList: [],
  allAreaList: [],
  userAreaIdList: [],
  accountList: [], //账号管理列表
  currentAreaId: ''
}
const getters = {
  userInfo: state => state.userInfo,
  isLogin: state => !!state.userInfo.id,
  authList: state => state.authList,
  allAreaList: state => {
    if (state.allAreaList && state.allAreaList.length) {
      return state.allAreaList
    } else if (sessionStorage.allAreaList) {
      return JSON.parse(sessionStorage.allAreaList)
    } else {
      return []
    }
  },
  userAreaIdList: state => {
    if (state.userAreaIdList && state.userAreaIdList.length) {
      let values = state.userAreaIdList
      let index = values.indexOf(-1)
      let first = values.splice(index, 1)[0]
      values.unshift(first)
      return values
    } else {
      let result = []
      try {
        result = JSON.parse(sessionStorage.userAreaIdList)
      } catch {
        result = []
      }
      return result.sort((a, b) => {
        return a - b
      })
    }
  },
  userAreaInfoList: (state, getters) => {
    if (getters.allAreaList) {
      return (
        getters.userAreaIdList.map(i => {
          return {
            id: i,
            name: getters.allAreaList.find(j => j.id == i) ? getters.allAreaList.find(j => j.id == i).areaName : '全国'
          }
        }) || []
      )
    }
    return (
      getters.userAreaIdList.map(i => ({
        id: i,
        name: i
      })) || []
    )
  },
  userAreaInfoListWithoutGlobal: (state, getters) => {
    return getters.userAreaInfoList.filter(i => i.id != -1)
  },
  currentAreaId: state => {
    return state.currentAreaId || sessionStorage.currentAreaId || -1
  },
  isAllArea(state, getters) {
    return getters.currentAreaId == -1
  }
}
const mutations = {
  update: (state, { key, value }) => (state[key] = value),
  updateCurrentAreaId: (state, payload) => {
    state.currentAreaId = payload
    sessionStorage.setItem('currentAreaId', payload)
  },
  updateUserAreaIdList: (state, payload) => {
    state.userAreaIdList = payload
    sessionStorage.setItem('userAreaIdList', JSON.stringify(payload))
  },
  updateAllAreaList: (state, payload) => {
    state.allAreaList = payload
    sessionStorage.setItem('allAreaList', JSON.stringify(payload))
  },
  updateAccountList(state, payload) {
    state.accountList = payload
  }
}
const actions = {
  getAccountList(store) {
    return $http.get('/boom-center-admin-service/sysAdmin/sysUser/list').then(res => {
      store.commit('updateAccountList', res)
    })
  },
  getPermissionList(store) {
    return $http.get('/auth').then(res => {
      store.commit({
        key: 'permissionList',
        value: res.list
      })
    })
  },
  loginByPassword({ dispatch, commit, getters }, { userName, password }) {
    // if (!verifyAccount(userName).isValidate) {
    //   return Promise.reject(verifyAccount(userName))
    // } else if (!verifyPassword(password).isValidate) {
    //   return Promise.reject(verifyPassword(password))
    // } else {
    return $http
      .post('/boom-center-admin-service/app/sysUser/login', {
        userName,
        password
      })
      .then(res => {
        localStorage.setItem('token', res.token)
        commit('update', {
          key: 'userInfo',
          value: {
            id: res.sysUserVO.id,
            name: res.sysUserVO.realName,
            avatar: res.sysUserVO.avatarUrl || 'https://demo.fastadmin.net/assets/img/avatar.png'
          }
        })
        return $http.get('/boom-center-admin-service/app/sysUser/privilege')
      })
      .then(res => {
        if (res.roleAreaId.length < 1) {
          return Promise.reject({ msg: '没有可用的地区权限' })
        }
        commit('update', {
          key: 'authList',
          value: res.rolePrivilege.map(i => i.privilegeId)
        })
        commit(
          'updateUserAreaIdList',
          res.roleAreaId.map(i => i.areaId)
        )
        dispatch('getAccountList')
        if (sessionStorage.currentAreaId && getters.userAreaIdList.includes(Number(sessionStorage.currentAreaId))) {
          commit('updateCurrentAreaId', Number(sessionStorage.currentAreaId))
        } else if (getters.userAreaIdList && getters.userAreaIdList.length) {
          commit('updateCurrentAreaId', getters.userAreaIdList[0])
        }
        return dispatch('getAreaList')
      })
    // }
  },
  logout({ commit }) {
    localStorage.removeItem('token')
    commit('update', {
      key: 'userInfo',
      value: {
        id: 0,
        name: '',
        avatar: 'https://demo.fastadmin.net/assets/img/avatar.png'
      }
    })
    window.location.reload()
    this.$router.push('/account/login')
    return Promise.resolve()
  },
  getAreaList(store) {
    return $http
      .get('/boom-center-admin-service/sysAdmin/area-settings/page', {
        params: {
          size: 1000
        }
      })
      .then(res => {
        // res.list.unshift({
        //   id: -1,
        //   areaName: '全国'
        // })

        store.commit('updateAllAreaList', res.list)
      })
  },
  initAreaList(store) {
    if (!store.getters['allAreaList'] || !store.getters['allAreaList'].length) {
      store.dispatch('getAreaList')
    } else if (!sessionStorage.allAreaList) {
      sessionStorage.setItem('allAreaList', JSON.stringify(store.getters['allAreaList']))
    }
  }
}

export default {
  namespaced: true,
  state,
  getters,
  mutations,
  actions
}
