import http from '@/plugin/axios'
import store from '@/plugin/store'
import { Message } from 'element-ui'
const state = {
  banner: [],
  areaId: '',
  currentBannerItem: {},
  pageCatLog: {
    'pages/shop/category/index': '分类页',
    'pages/nearby/index': '附近页',
    'pages/shop/product/index': '商品页'
  }
}

const getters = {
  areaId: state => state.areaId,
  banner: state => state.banner,
  pageCatLog: state => state.pageCatLog,
  currentBannerItem: state => state.currentBannerItem,
  validateForm: state => {
    const item = state.currentBannerItem
    if (!item.bannerName) {
      return '请输入昵称'
    } else if (!item.bannerImageUrl) {
      return '请上传轮播图片'
    } else if (!item.bannerSort) {
      return '请输入排序'
    }
  }
}

const mutations = {
  updateBannerList: (state, value) => {
    state.banner = value
  },

  updateAreaId: (state, value) => {
    state.areaId = value
  },
  updateCurrentBannerItem: (state, value) => {
    state.currentBannerItem = value
  }
}

const actions = {
  validateBannerItem(store, index) {
    if (store.getters.currentBannerItem.productId === '') {
      Message.error('请输入商品id')
      return
    }
    return http
      .get('boom-center-product-service/sysAdmin/product/status/', {
        params: {
          id: store.getters.currentBannerItem.productId
        },
        headers: {
          ['Bm-Area-Id']: store.getters.areaId
        }
      })
      .then(res => {
        store.dispatch('sendSaveHttp')
      })
      .catch(err => {
        Message.error(err.msg || '该地区没有此商品或者商品已经下架')
      })
  },
  sendSaveHttp(store) {
    let params = store.getters.currentBannerItem
    params.hideStatus = params.hideStatus ? 0 : 1

    if (Array.isArray(params.bannerImageUrl)) {
      params.bannerImageUrl = params.bannerImageUrl[0]
    }

    if (params.id < 0) {
      return http
        .post('boom-center-admin-service/sysAdmin/banner/add', params, {
          headers: {
            ['Bm-Area-Id']: store.getters.areaId
          }
        })
        .then(res => {
          if (res) {
            store.getters.currentBannerItem.new = false
            Message.success('添加成功')
            store.getters.currentBannerItem.id = res
          } else {
            Message.error('添加失败')
          }
        })
        .catch(() => {
          Message.error('添加失败')
        })
    } else {
      return http
        .put(`boom-center-admin-service/sysAdmin/banner/update/${params.id}`, params, {
          headers: {
            ['Bm-Area-Id']: store.getters.areaId
          }
        })
        .then(res => {
          if (res) {
            Message.success('更新成功')
            store.dispatch('getBanner')
          } else {
            Message.error('更新失败')
          }
        })
        .catch(() => {
          Message.error('更新失败')
        })
    }
  },
  saveBannerItem(store) {
    let params = JSON.parse(JSON.stringify(store.getters.currentBannerItem))
    params.hideStatus = params.hideStatus ? 0 : 1

    if (Array.isArray(params.bannerImageUrl)) {
      params.bannerImageUrl = params.bannerImageUrl[0]
    }

    if (store.getters.validateForm) {
      return Promise.reject({ msg: store.getters.validateForm })
    }
    console.log(store.getters.currentBannerItem.linkUrl)
    if (store.getters.currentBannerItem.linkUrl) {
      console.log(1)
      store.dispatch('sendSaveHttp')
    } else {
      console.log(13)
      store.dispatch('validateBannerItem')
    }
  },

  removeBannerItem(store, index) {
    if (store.getters.banner[index].id < 0) {
      store.getters.banner.splice(index, 1)
      return
    }
    return http
      .delete(`boom-center-admin-service/sysAdmin/banner/delete/${store.getters.banner[index].id}`, {
        headers: {
          ['Bm-Area-Id']: store.getters.areaId
        }
      })
      .then(res => {
        store.getters.banner.splice(index, 1)
        Message.success('删除成功')
      })
      .catch(() => {
        Message.error('删除失败')
      })
    return
  },
  getBanner(store) {
    return http
      .get('boom-center-admin-service/sysAdmin/banner/list', {
        params: { size: 100, page: 1 },
        headers: {
          ['Bm-Area-Id']: store.getters.areaId
        }
      })
      .then(res => {
        res.records.map(item => (item.hideStatus = item.hideStatus === 0 ? true : false))
        store.commit('updateBannerList', res.records)
      })
      .catch(err => {
        Message.error(err.msg || '操作失败')
      })
  }
}

export default {
  namespaced: true,
  state,
  getters,
  mutations,
  actions
}
