<!--
 * @Author: Link
 * @Date: 2020-12-29 10:12:05
 * @LastEditTime: 2021-05-20 19:12:20
-->
<template>
  <div>
    <div :style="`height:${height};${width && 'width:' + width}`" ref="chart"></div>
  </div>
</template>

<script>
import echarts from 'echarts'
export default {
  data() {
    return {
      chartInstance: null
    }
  },
  props: {
    config: {
      type: Object,
      default: {}
    },
    legend: {
      type: Boolean,
      default: true
    },
    legendFormat: {
      type: [String, Function],
      default: ''
    },
    height: {
      type: String,
      default: '300px'
    },
    width: {
      type: String,
      default: ''
    }
  },
  watch: {
    config: {
      handler(val) {
        console.log(val.data.data)
        this.initChart()
      },
      deep: true,
      immediate: true
    }
  },
  mounted() {},
  methods: {
    initChart() {
      this.$nextTick(() => {
        this.chartInstance = echarts.init(this.$refs.chart)
        this.updateChart()
      })
    },
    updateChart() {
      let color = []
      this.config.data.data.map(item => {
        if (item.color) color.push(item.color)
      })
      let legend = {
        show: this.legend,
        bottom: '2%',
        left: 'center',
        orient: 'vertical',
        data: this.config.data.data.map(i => {
          return i.name
        })
      }
      if (this.legendFormat)
        Object.assign(legend, {
          formatter: this.legendFormat
        })
      this.chartInstance.setOption({
        color: color.length ? color : ['#0099FF', '#FFD800'],
        title: {
          text: this.config.title,
          subtext: this.config.subtext,
          left: 'center'
        },
        tooltip: {
          trigger: 'item'
        },
        grid: {
          x: 0,
          y: 0,
          x2: 0,
          y2: 0
        },
        legend,
        series: [
          {
            name: this.config.data.name,
            type: 'pie',
            label: {
              normal: {
                show: true, // 设置不显示
                position: 'center', // hover时提示在圆环内
                color: '#333',
                fontSize: 18,
                formatter: this.config.data.formatter
              }
            },
            hoverAnimation: false,
            radius: ['64%', '70%'],
            center: [color.length ? '40%' : '50%', color.length ? '50%' : '44%'],
            data: this.config.data.data,
            emphasis: {
              itemStyle: {
                shadowBlur: 10,
                shadowOffsetX: 0,
                shadowColor: 'rgba(0, 0, 0, 0.5)'
              }
            }
          }
        ]
      })
    }
  }
}
</script>

<style lang="less" scoped>
.page {
  width: 100%;
}
</style>
