<template>
  <div class="home-page-model">
    <template v-if="!canEdit">
      <div class="home-page-dialog flod" :class="{ currentselect: cptData.selectStatus && !canEdit }">
        <template v-if="dialogShow">
          <div class="dialog-content">
            <template v-for="(d, i) in cptData.data">
              <template v-if="i == currentActBarsIndex && d.hideStatus == 0">
                <div class="main-img" :key="'dialogImg' + i">
                  <img :src="d.popUpsImageUrl" alt="" class="img" />
                </div>
              </template>
            </template>
            <template v-if="currentActBarsIndex == -1">
              <p><span>弹框组件</span></p>
            </template>
            <div class="close"><span @click="dialogShow = false">×</span></div>
          </div>
        </template>
        <template v-else>
          <div class="show-img">
            <span @click="dialogShow = true"> <i class="el-icon-d-arrow-left"></i> </span>
          </div>
        </template>
      </div>
    </template>
    <template v-else>
      <div class="home-page-edit">
        <p class="com_title">首页弹框</p>
        <el-button type="primary" class="add_btn" @click="handleAddData">添加弹窗</el-button>
        <el-collapse v-model="activeName" accordion>
          <template v-for="(classifyData, index) in cptData.data">
            <el-collapse-item :name="cptData.componentName + index" :key="index">
              <template slot="title">
                <span style="margin-left: 10px; font-weight: 700">{{
                  index + 1 + (classifyData.popUpsName ? '-' + classifyData.popUpsName : '')
                }}</span>
                <span style="font-size: 12px">
                  {{ '（' + (classifyData.hideStatus == 0 ? '√' : '×') + '）' }}
                </span>
              </template>
              <el-form
                ref="form"
                :model="classifyData"
                :rules="rules"
                class="vendorFrom"
                label-position="right"
                label-width="100px"
              >
                <el-form-item label="状态">
                  <el-switch v-model="classifyData.hideStatus" :active-value="false" :inactive-value="true"></el-switch>
                </el-form-item>
                <el-form-item label="弹窗图片" prop="popUpsImageUrl">
                  <bm-upload v-model="classifyData.popUpsImageUrl" type="system"></bm-upload>
                  <span class="assist">建议尺寸750*750</span>
                </el-form-item>
                <el-form-item label="弹框名称" prop="popUpsName">
                  <el-input v-model="classifyData.popUpsName" placeholder="输入弹框名称"></el-input>
                </el-form-item>
                <el-form-item label="跳转到页面">
                  <el-select v-model="classifyData.skipType" placeholder="请选择跳转到页面">
                    <el-option label="商品" :value="0"> </el-option>
                    <el-option label="商家" :value="1"> </el-option>
                    <el-option label="其他" :value="2"> </el-option>
                  </el-select>
                </el-form-item>

                <el-form-item
                  label="跳转ID"
                  prop="skipId"
                  v-if="classifyData.skipType == 0 || classifyData.skipType == 1"
                >
                  <el-input
                    style="width: 100%"
                    v-model="classifyData.skipId"
                    :placeholder="classifyData.skipType == 0 ? '请输入商品id' : '请输入商家id'"
                  ></el-input>
                </el-form-item>
                <el-form-item label="跳转路径" prop="skipUrl" v-else>
                  <el-input v-model="classifyData.skipUrl" placeholder="请输入页面url"></el-input>
                </el-form-item>
                <el-form-item label="显示时间" prop="dateRange">
                  <el-date-picker
                    v-model="allowTimes[index]"
                    type="datetimerange"
                    :default-time="['00:00:00', '23:59:59']"
                    value-format="timestamp"
                    range-separator="至"
                    start-placeholder="开始日期"
                    end-placeholder="结束日期"
                    @change="setAllowTIme(index)"
                  />
                </el-form-item>
                <el-form-item label="关闭不显示" prop="popBoot">
                  <el-input-number v-model="classifyData.popBoot" :min="1" controls-position="right" />
                  <p class="tips">为了避免对用户造成打扰，建议用户关闭该弹框达到2次后不再显示该弹框</p>
                </el-form-item>
                <el-form-item v-loading="loading">
                  <el-button type="primary" @click="save(index)">保存</el-button>
                  <el-button type="danger" @click="remove(index)">删除</el-button>
                </el-form-item>
              </el-form>
            </el-collapse-item>
          </template>
        </el-collapse>
      </div>
    </template>
  </div>
</template>

<script>
import { log } from '@/util/log'
import { mapGetters } from 'vuex'
export default {
  name: 'search-cpt',
  props: {
    cptData: {
      type: Object,
      default: {
        componentName: 'homepageDialog',
        selectStatus: false,
        data: []
      }
    },
    canEdit: {
      type: Boolean,
      default: false,
      require: false,
      comment: '是否编辑状态， true是，会在装修页面右侧调用'
    }
  },
  data() {
    return {
      loading: false,
      activeName: this.cptData.componentName + 0,
      dialogShow: true,
      defaultImg: require('@/asset/icon/saoma@2x.png'),
      rules: {
        popUpsImageUrl: [{ required: true, message: '请上传弹窗图片', trigger: 'change' }],
        popUpsName: [{ required: true, message: '请输入弹框名称', trigger: 'blur' }],
        skipId: [
          {
            required: true,
            trigger: 'blur',
            validator: async (rule, value, callback) => {
              let cutData = this.cptData.data[this.curIndex]
              if (!value) {
                let msg = ''
                if (cutData.skipType == 0) {
                  msg = '请输入商品id'
                } else {
                  msg = '请输入商家id'
                }
                return callback(new Error(msg))
              } else {
                if (cutData.skipType == 0) {
                  try {
                    let res = await this.$api.mall.mall('validProductId', value, {
                      headers: {
                        ['Bm-Area-Id']: this.areaId
                      }
                    })
                    callback()
                  } catch (err) {
                    return callback('该地区没有此商品或者商品已经下架')
                  }
                }
              }
            }
          }
        ],
        skipUrl: [
          {
            required: true,
            message: '请输入跳转路径',
            trigger: 'blur'
          }
        ],
        dateRange: [
          {
            required: true,
            trigger: 'blur',
            validator: (rule, value, callback) => {
              let curValue = this.allowTimes[this.curIndex]
              if (!curValue) {
                return callback(new Error('选择显示时间'))
              } else {
                callback()
              }
            }
          }
        ]
      },
      intStartTime: Date.parse(new Date()) + 10 * 60 * 1000,
      intEndTime: Date.parse(new Date()) + 24 * 60 * 60 * 1000,
      model: {
        hideStatus: true,
        id: '',
        popBoot: 0,
        popLocation: 0,
        popSort: 0,
        popEndTime: this.intEndTime,
        popStartTime: this.intStartTime,
        popTimeKeep: 0,
        popUpsImageUrl: '',
        popUpsName: '',
        skipId: 0,
        skipType: 0,
        skipUrl: ''
      },
      allowTimes: [],
      curIndex: 0,
      currentActBarsIndex: -1, //当前活跃的 弹窗
      areaId: this.$store.getters['malls/getCurrentEditAreaId']
    }
  },
  created() {
    if (!this.cptData.data.length) {
      this.cptData.data.push({ ...this.model })
    }
    for (let d of this.cptData.data) {
      this.allowTimes.push([d.popStartTime, d.popEndTime])
    }
  },
  methods: {
    setAllowTIme(index) {
      let currentEditData = this.cptData.data[index]
      currentEditData.popStartTime = this.allowTimes[index][0]
      currentEditData.popEndTime = this.allowTimes[index][1]
    },
    openCurIndex(index) {
      let len = this.cptData.data.length
      if (index > len - 1) {
        this.curIndex = len - 1
      } else {
        this.curIndex = index
      }
      this.activeName = this.cptData.componentName + this.curIndex
    },

    save(index) {
      this.openCurIndex(index)
      this.$refs['form'][index].validate(valid => {
        if (valid) {
          this.loading = true
          let data = this.cptData.data[index]
          let url = data.id ? 'updatePopup' : 'addPopup'
          this.$api.mall
            .mall(url, data, {
              headers: {
                ['Bm-Area-Id']: this.areaId
              }
            })
            .then(res => {
              if (url == 'addPopup') {
                this.cptData.data[index].id = res
              }
              this.$message.success('保存成功')
            })
            .catch(err => {
              this.$message.error(err.msg || '操作失败')
            })
            .finally(() => {
              setTimeout(() => {
                this.loading = false
              }, 200)
            })
        }
      })
    },
    remove(index) {
      let data = this.cptData.data[index]
      this.$confirm('确定删除该内容吗?', '提示', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning'
      })
        .then(() => {
          this.loading = true
          if (!data.id) {
            this.cptData.data.splice(index, 1)
            this.openCurIndex(index)
            setTimeout(() => {
              this.loading = false
            }, 200)
          } else {
            this.$api.mall
              .mall(
                'deletePopup',
                {
                  id: data.id
                },
                {
                  headers: {
                    ['Bm-Area-Id']: this.areaId
                  }
                }
              )
              .then(res => {
                this.cptData.data.splice(index, 1)
                this.openCurIndex(index)
                this.$message.success('删除')
              })
              .catch(err => {
                this.$message.error(err.msg || '操作失败')
              })
              .finally(() => {
                setTimeout(() => {
                  this.loading = false
                }, 200)
              })
          }
        })
        .catch(() => {
          this.$message({
            type: 'info',
            message: '已取消'
          })
        })
    },
    handleAddData() {
      this.cptData.data.push({ ...this.model })
      this.allowTimes.push([this.intStartTime, this.intEndTime])
      let curIndex = this.cptData.data.length - 1
      this.openCurIndex(curIndex)
    }
  },
  watch: {
    'cptData.data': {
      handler(n) {
        if (!this.canEdit) {
          for (let i in n) {
            if (n[i].hideStatus == 0) {
              this.currentActBarsIndex = Number(i)
              return
            }
          }
          this.currentActBarsIndex == -1
        }
      },
      deep: true
    }
  }
}
</script>
<style lang="less">
.home-page-model {
  .el-collapse-item__header {
    font-weight: bold;
    font-size: 14px;
  }
}
</style>
<style lang="less" scoped>
@import './com.less';
.currentselect {
  border: red 1px solid !important;
  .radius(6px);
}
.home-page-model {
  .home-page-dialog {
    border: 1px solid transparent;
    position: absolute;
    top: 30%;
    right: 10%;
    z-index: 1000;

    .dialog-content {
      width: 365px;
      text-align: center;
      margin: 10px 0;
      min-height: 150px;
      .main-img {
        img {
          width: 150px;
          height: 150px;
          .radius(6px);
        }
      }
      .close {
        margin-top: 10px;
        span {
          display: inline-block;
          width: 30px;
          height: 30px;
          line-height: 28px;
          font-size: 25px;
          vertical-align: middle;
          color: @color-background;
          background-color: rgba(0, 0, 0, 0.5);
          .radius(50%);
          cursor: pointer;
        }
      }
      p {
        color: #999999;
        text-align: center;
        span {
          display: inline-block;
          min-height: 150px;
          min-width: 150px;
          line-height: 145px;
          background-color: rgba(0, 0, 0, 0.5);
        }
      }
    }
    .show-img {
      background-color: @color-background;
      .radius(10px);

      span {
        display: inline-block;
        padding: 5px;
        font-size: 30px;
        cursor: pointer;
      }
    }
  }
  .home-page-edit {
    background-color: @color-background;
    padding: 10px 20px;
    & > p {
      padding: 10px;
      margin-bottom: 20px;
      font-weight: 700;
    }
  }
}
</style>
