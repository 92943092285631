<template>
  <el-form-item v-if="$store.getters['user/isAllArea']" label="地区">
    <el-select :value="value" @input="onValueChange" placeholder="请选择城市">
      <el-option
        v-for="item in $store.getters['user/userAreaInfoListWithoutGlobal']"
        :key="item.id"
        :label="item.name"
        :value="item.id"
      >
      </el-option>
    </el-select>
  </el-form-item>
</template>

<script>
import { log } from '@/util/log'
export default {
  props: {
    value: {
      type: [String, Number],
      default: ''
    },
    disabled: {
      type: Boolean,
      default: false,
      require: false
    }
  },
  data: () => ({
    bar: 'bar'
  }),
  methods: {
    onValueChange(val) {
      this.$emit('input', val)
    }
  },
  mounted() {},
  created() {}
}
</script>

<style lang="less" scoped></style>
