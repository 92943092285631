/*
 * @Author: Link
 * @Date: 2020-11-30 13:41:54
 * @LastEditTime: 2021-04-09 10:54:22
 */
import http from '@/plugin/axios'
const statisticsService = 'boom-center-statistics-service/'
const adminService = 'boom-center-admin-service/'
const productService = 'boom-center-product-service/'

let mallAdmin = {
  addBanner({ params }, config) {
    return http.post(adminService + 'sysAdmin/banner/add', params, config)
  },
  getBanner(params) {
    return http.get(adminService + `sysAdmin/banner/list`, params)
  },
  updateBanner({ params }, config) {
    return http.put(adminService + `sysAdmin/banner/update/${params.id}`, params, config)
  },
  deleteBanner({ setting, config }) {
    return http.delete(adminService + `sysAdmin/banner/delete/${setting.id}`, config)
  },

  /**
   * 商城装修
   */
  // =======首页有序已存组件组合
  getModule(config) {
    return http.get(adminService + 'sysAdmin/decorationModule', config)
  },
  postModule(params, config) {
    return http.post(adminService + 'sysAdmin/decorationModule', params, config)
  },
  // =======金刚区=========

  postClassify(params, config) {
    return http.post(adminService + 'sysAdmin/zone/add', params, config)
  },
  getClassifyList(params) {
    return http.get(adminService + 'sysAdmin/zone/list', params)
  },
  putClassify(params, config) {
    return http.put(adminService + 'sysAdmin/zone/update', params, config)
  },
  deleteClassify(id) {
    return http.delete(adminService + 'sysAdmin/zone/' + id)
  },
  //==========================弹窗=-========
  getPopupList(params) {
    return http.get(adminService + 'sysAdmin/popUps/list', params)
  },
  addPopup(params, config) {
    return http.post(adminService + 'sysAdmin/popUps', params, config)
  },
  updatePopup(params, config) {
    return http.put(adminService + 'sysAdmin/popUps', params, config)
  },
  deletePopup({ id = 0 }, config) {
    return http.delete(adminService + 'sysAdmin/popUps?id=' + id, config)
  },
  // ======轮播图===============
  postCarousel(params, config) {
    return http.post(adminService + 'sysAdmin/banner', params, config)
  },
  getCarouselList(params) {
    return http.get(adminService + 'sysAdmin/banner/list', params)
  },
  deleteCarousel(id) {
    return http.delete(adminService + 'sysAdmin/banner/delete/' + id)
  },
  // ======底部栏bottombar===============
  postBottomBar(params, config) {
    return http.post(adminService + 'sysAdmin/decorationBottomBar', params, config)
  },
  getBottomBarList(config) {
    return http.get(adminService + 'sysAdmin/decorationBottomBar', config)
  },
  deleteBottomBar(id) {
    return http.delete(adminService + 'sysAdmin/decorationBottomBar/' + id)
  },

  // =======瀑布流================

  postWaterfall(params, config) {
    return http.post(adminService + 'sysAdmin/waterfall', params, config)
  },
  getWaterfallList(config) {
    return http.get(adminService + 'sysAdmin/waterfall/list', config)
  },
  deleteWaterfall(id) {
    return http.delete(adminService + 'sysAdmin/waterfall/' + id)
  },

  // 获取活动列表
  getActivityList(params) {
    return http.get('boom-center-admin-service/sysAdmin/productGroup/findList', params)
  },
  getActivityDetail(id) {
    return http.get('boom-mix-biz-service/app/productGroup/' + id)
  },
  getActivityProduct() {
    return http.get('boom-mix-biz-service/app/productGroup/list')
  },
  getActivityProduct2(params) {
    return http.get('boom-center-product-service/product/productGroup/list', params)
  },

  // =====商圈/分类菜单=======
  getdhnList(config) {
    return http.get(adminService + 'sysAdmin/decorationHorizontalNavigation', config)
  },
  postdhn(params, config) {
    return http.post(adminService + 'sysAdmin/decorationHorizontalNavigation', params, config)
  },
  dhnDelete(id) {
    return http.delete(adminService + 'sysAdmin/decorationHorizontalNavigation/' + id)
  },

  // =======发现================
  getDiscoveryList(config) {
    return http.get(adminService + 'sysAdmin/decorationDiscovery', config)
  },
  postDiscovery(params, config) {
    return http.post(adminService + 'sysAdmin/decorationDiscovery', params, config)
  },
  deleteDiscovery(id) {
    return http.delete(adminService + 'sysAdmin/decorationDiscovery/' + id)
  },

  // ======魔方================
  getMagicCubeList(config) {
    return http.get(adminService + 'sysAdmin/decorationMagicCube/list', config)
  },
  postMagicCube(params, config) {
    return http.post(adminService + 'sysAdmin/decorationMagicCube', params, config)
  },
  putMagicCube(params, config) {
    return http.put(adminService + 'sysAdmin/decorationMagicCube', params, config)
  },
  deleteMagicCube(id) {
    return http.delete(adminService + 'sysAdmin/decorationMagicCube/' + id)
  },
  //商品id验证
  validProductId(id, config) {
    return http.get('boom-center-product-service/sysAdmin/product/status?id=' + id, config)
  },
  // ======秒杀================
  timeSaleActivity(params) {
    return http.get(productService + 'sysAdmin/limitedTimeSaleActivity/started', params)
  },
  postMainImg(params, config) {
    return http.post(productService + 'sysAdmin/limitedTimeSaleActivity/mainImage', params, config)
  },

  // ========商圈===============

  // ====首页====================

  // 获取首页数据

  getHomeStatistic(params) {
    return http.get(statisticsService + 'sysAdmin/homestatistic/get', params)
  },
  getHomeTender(params) {
    return http.get(statisticsService + 'sysAdmin/homestatistic/getChart', params)
  },

  // 获取商家排行

  getStore(params) {
    return http.get(statisticsService + 'sysAdmin/homestatistic/store/rank/get/v1', params)
  },

  // ========商城排行榜===========

  getTanchiList(params) {
    return http.get(adminService + 'sysAdmin/businessEsurient', params)
  },
  postTanchi(params, config) {
    return http.post(adminService + 'sysAdmin/businessEsurient', params, config)
  },
  putTanchi(params, config) {
    return http.put(adminService + 'sysAdmin/businessEsurient/' + params.id, params, config)
  }
}

export function mall(tag, params, setting) {
  return mallAdmin[tag](params, setting)
}
