<template>
  <div class="tabList">
    <span
      v-for="(i, n) in list"
      :key="n"
      class="tab"
      :class="{ active: current === i.name }"
      @click="handleClick(i)"
    >
      {{ i.name }}
    </span>
  </div>
</template>

<script>
import { mapGetters, mapActions } from 'vuex'

export default {
  props: {
    list: {
      type: Array,
      default: () => []
    },
    current: {
      type: String,
      default: ''
    }
  },
  data: () => ({
    bar: 'bar'
  }),
  computed: {
    ...mapGetters({})
  },
  watch: {},
  methods: {
    handleClick(val) {
      this.$router.push(val.path)
    }
  },
  created() {},
  mounted() {}
}
</script>

<style lang="less" scoped>
.tabList {
  color: @color-primary;
  padding: @container-margin 0 0 @container-margin;
}
.tab {
  margin-right: @container-margin;
  cursor: pointer;
  font-size: 16px;
  vertical-align: baseline;
  &:hover {
    color: @color-primary-select;
  }
  &.active {
    color: @color-primary-select;
    font-size: 20px;
    font-weight: bold;
  }
}
</style>
