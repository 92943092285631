import http from '@/plugin/axios'
const adminService = 'boom-center-admin-service/'

let httpLogin = {
  login(params) {
    return http.post(adminService + 'sysAdmin/sysUser/login', params)
  }
}
export function login(tag, params, setting) {
  return httpLogin[tag](params, setting)
}
