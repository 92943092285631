export function isPhoneNumber(val) {
  return val.length === 11
}
export function verifyAccount(val) {
  if (!val) {
    return { isValidate: false, msg: '账号不能为空' }
  }
  if (val.length < 3) {
    return { isValidate: false, msg: '账号不能小于3个字符' }
  }
  if (val.length > 24) {
    return { isValidate: false, msg: '账号不能大于24个字符' }
  }
  return { isValidate: true }
}
export function verifyPassword(val) {
  if (!val) {
    return { isValidate: false, msg: '密码不能为空' }
  }
  if (val.length < 6) {
    return { isValidate: false, msg: '密码不能小于6个字符' }
  }
  if (val.length > 18) {
    return { isValidate: false, msg: '密码不能大于18个字符' }
  }
  return { isValidate: true }
}
function inputType(value) {
  switch (value) {
    case 'upload':
      return '上传'
      break
    case 'select':
      return '选择'

    default:
      return '输入'
  }
}
export function validatedForm(fieldList) {
  const obj = {}
  // 循环字段列表
  for (const item of fieldList) {
    const type = inputType(item.type)
    if (item.required) {
      if (item.validator) {
        obj[item.value] = {
          required: item.required,
          validator: item.validator,
          trigger: 'blur'
        }
      } else {
        if (item.parent) {
          let val = item.parent + '.' + item.value
          obj[val] = {
            required: item.required,
            message: '请' + type + item.label,
            trigger: 'blur'
          }
        } else {
          obj[item.value] = {
            required: item.required,
            message: '请' + type + item.label,
            trigger: 'blur'
          }
        }
      }
    } else if (item.validator) {
      obj[item.value] = {
        validator: item.validator,
        trigger: 'blur'
      }
    }
  }
  return obj
}
