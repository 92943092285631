<template>
  <div class="uploadBox">
    <div class="multipleUpload">
      <draggable class="dragArea image-uploader list-group" @change="handleDraggable" :list="localImageList">
        <span v-for="(item, index) in localImageList" :key="index" class="imageItem">
          <img class="image" v-if="item" :src="item | thumbMedium" alt="无图片" />
          <span class="imageItemActions">
            <span class="imageAction" @click="handlePictureCardPreview(item)">
              <i class="el-icon-zoom-in"></i>
            </span>
            <span class="imageAction" @click="handleRemove(index)">
              <i class="el-icon-delete"></i>
            </span>
          </span>
        </span>
      </draggable>
      <div class="el-icon-plus uploadIcon" v-if="limit > localImageList.length">
        <input type="file" accept="image/*" @input="handleFileChange" :multiple="limit > 1" />
      </div>
    </div>

    <el-dialog :close-on-click-modal="false" :visible.sync="dialogVisible" append-to-body>
      <img width="100%" :src="dialogImageUrl" alt="预览地址" />
    </el-dialog>
  </div>
</template>

<script>
import draggable from 'vuedraggable'

export default {
  name: 'bm-upload',
  components: {
    draggable
  },
  props: {
    limit: {
      type: Number,
      default: 1
    },
    type: {
      type: String,
      default: 'system'
    },
    value: {
      type: [Array, String],
      default: () => []
    },
    isEncrypt: {
      type: Boolean,
      default: false
    },
    reduceResolution: {
      type: Boolean,
      default: false
    }
  },
  data() {
    return {
      // 图片的地址
      localImageList: [],
      // 弹窗的显示和弹窗图片地址
      dialogVisible: false,
      dialogImageUrl: ''
    }
  },
  watch: {
    value: {
      handler(val) {
        if (typeof val === 'string') {
          if (val) {
            this.localImageList = [val]
          } else {
            this.localImageList = []
          }
        } else {
          this.localImageList = val
        }
      },
      immediate: true
    }
  },
  methods: {
    // 上传图片
    handleFileChange(e) {
      if (e.target.files && e.target.files.length) {
        const loading = this.$loading({
          lock: true,
          text: '上传中，请稍候',
          spinner: 'el-icon-loading',
          background: 'rgba(0, 0, 0, 0.7)'
        })
        for (let i = 0; i < e.target.files.length; i++) {
          this.$store
            .dispatch('upload/image', {
              file: e.target.files[i],
              type: this.type,
              isEncrypt: this.isEncrypt,
              reduceResolution: this.reduceResolution
            })
            .then(res => {
              this.localImageList.push(res)
              this.handleUpdate(this.localImageList)
              this.$emit('addImg')
            })
            .catch(err => {
              this.$message.error(err.msg || '操作失败')
            })
            .finally(() => {
              loading.close()
            })
        }
      }
    },
    // 预览图片，出现弹窗，然后把值赋值给弹窗的img图片
    handlePictureCardPreview(val) {
      this.dialogVisible = true
      this.dialogImageUrl = val
    },
    // 多张图片的时候，删除图片
    handleRemove(index) {
      this.localImageList.splice(index, 1)
      this.handleUpdate(this.localImageList)
      this.$emit('deleteImg')
    },
    handleDraggable() {
      this.handleUpdate(this.localImageList)
    },
    handleUpdate(val) {
      let Boolean = val.length === 1 && this.limit === 1
      this.$emit('input', Boolean ? val[0] : val)
      this.$emit('change', Boolean ? val[0] : val)
    }
  }
}
</script>

<style lang="less" scoped>
.uploadBox {
  .image-uploader {
    max-width: 900px;
    overflow: hidden;
    overflow-x: auto;
    display: inline-flex;
    align-items: center;
    cursor: pointer;
    padding-bottom: 6px;
    position: relative;
    .imageItem {
      position: inherit;
      display: inline-flex;
      margin-right: 10px;
    }

    .image {
      width: 100px;
      height: 100px;
      border-radius: 6px;
    }
    .imageItemActions {
      width: 100px;
      height: 100px;
      position: absolute;

      left: 0;
      top: 0;
      cursor: default;
      line-height: 100px;
      text-align: center;
      color: #fff;
      opacity: 0;
      font-size: 20px;
      border-radius: 6px;
      background-color: rgba(0, 0, 0, 0.5);
      transition: opacity 0.3s;
      &:hover {
        opacity: 1;
      }
      .imageAction {
        margin: 10px;
      }
    }
  }

  .uploadIcon {
    width: 100px;
    height: 100px;
    display: flex;
    align-items: center;
    justify-content: center;
    font-size: 40px;
    border: 1px solid #d9d9d9;
    border-radius: 6px;
    position: relative;
    overflow: hidden;
    input {
      position: absolute;
      top: 0;
      bottom: 0;
      left: 0;
      right: 0;
      opacity: 0;
    }
  }
}
.multipleUpload {
  display: flex;
}
</style>
