import http from '@/plugin/axios'
const statisticsService = 'boom-center-statistics-service/'

let httpStatistics = {
  // 综合统计
  getSynthesize(params) {
    return http.get(statisticsService + 'sysAdmin/synthesize/get', params)
  },
  getSynthesize2(params) {
    return http.get(statisticsService + 'sysAdmin/synthesize/get/v1', params)
  },
  // 获取交易数据
  getDeal(params) {
    return http.get(statisticsService + 'sysAdmin/deal/get', params)
  },
  // 交易图表
  getDealChart(params) {
    return http.get(statisticsService + 'sysAdmin/deal/dealChart', params)
  },

  // 商品排行
  memberList(params) {
    return http.get(statisticsService + 'sysAdmin/rank/member', params)
  },
  // 商家排行
  businessList(params) {
    return http.get(statisticsService + 'sysAdmin/rank/business', params)
  },
  // 商品排行榜
  productList(params) {
    return http.get(statisticsService + 'sysAdmin/rank/product', params)
  },
  trendList(params) {
    return http.get(statisticsService + 'sysAdmin/trend/trend', params)
  },
  orderCountRankList(params) {
    return http.get(statisticsService + 'sysAdmin/trend/orderCountRank', params)
  },
  refundAmountData(params) {
    return http.get(statisticsService + 'sysAdmin/synthesize/refundAmount', params)
  }
}
export function statistics(tag, params) {
  return httpStatistics[tag](params)
}
