import http from '@/plugin/axios'
const productService = 'boom-center-product-service/'
const adminService = 'boom-center-admin-service/'
const mixService = 'boom-mix-biz-service/'
const userService = 'boom-center-user-service/'
// 商品

const httpVendor = {
  // 可以根据商家id或商家名称分页查询商家列表
  businessList(params, config) {
    return http.get(productService + 'sysAdmin/business/list', params, config)
  },
  businessMixList(params, config) {
    return http.get(mixService + 'sysAdmin/business/list', params, config)
  },
  // 查询商家列表
  // 根据id查询商家
  getBusinessDetail(params) {
    return http.get(productService + 'sysAdmin/business/' + params)
  },

  businessListMini(params) {
    return http.get(productService + 'sysAdmin/business/list/mini', params)
  },
  // 新增商家
  postBusiness(params, config) {
    return http.post(productService + 'sysAdmin/business', params, config)
  },
  // 修改商家
  putBusiness(params, config) {
    return http.put(productService + 'sysAdmin/business', params, config)
  },
  // 查询商家的所有门店
  storeList(params) {
    return http.get(productService + 'sysAdmin/store/list/', params)
  },
  // 查询商家相关信息
  getBusinessTitle(params, config) {
    return http.get(productService + 'sysAdmin/business/selectAllByBusinessTitle', params, config)
  },

  // 查询商家的所有门店，包含人员数量
  storeListHasPerson(params) {
    return http.get(mixService + 'sysAdmin/store/list/v1', params)
  },

  // 查询商家的所有门店
  storeListSubMerchant(params) {
    return http.get(productService + 'sysAdmin/store/all/subMerchant', params)
  },

  // 新增门店
  postStore(params) {
    return http.post(productService + 'sysAdmin/store', params)
  },
  // 修改门店
  putStore(params) {
    return http.put(productService + 'sysAdmin/store', params)
  },
  // 检查核销码
  storeCheckExCode(params) {
    return http.get(productService + 'sysAdmin/store/check/excode', params)
  },
  // 获取门店详情
  getStoreDetail(params) {
    return http.get(productService + 'sysAdmin/store/' + params)
  },
  putStoreStatus(params) {
    return http.put(productService + 'sysAdmin/store/list/hideStatus', params)
  },

  // 修改门店状态

  // ===================城市标签==============
  // 新增门店
  postStoreTag(params) {
    return http.post(productService + 'sysAdmin/storeTag', params)
  },
  // 修改门店
  putStoreTag(params) {
    return http.put(productService + 'sysAdmin/storeTag', params)
  },
  // 检查核销码
  getStoreTagList(params) {
    return http.get(productService + 'sysAdmin/storeTag/list', params)
  },
  // =====================商家分类 ====================

  // 添加商家分类

  postBusinessCategory(params) {
    return http.post(productService + 'sysAdmin/businessCategory', params)
  },
  // 更新商家分类
  putBusinessCategory(params) {
    return http.put(productService + 'sysAdmin/businessCategory', params)
  },

  // 商家分类所有分类信息列表

  businessCategoryAll(params) {
    return http.get(productService + 'sysAdmin/businessCategory/all', params)
  },

  // 根据父id查询商家分类信息列表
  businessCategoryAllId(params) {
    return http.get(productService + 'sysAdmin/businessCategory/list/' + params)
  },

  // 商家一级分类查询列表
  businessCategoryFirst(params) {
    return http.get(productService + 'sysAdmin/businessCategory/list', params)
  },
  // =====================商圈分类 ====================

  // 新增商圈
  postBusinessDistrict(params, config) {
    return http.post(productService + 'sysAdmin/businessDistrict', params, config)
  },
  // 更新商圈
  putBusinessDistrict(params, config) {
    return http.put(productService + 'sysAdmin/businessDistrict', params, config)
  },
  // 查询所有商圈
  businessDistrictAll(params) {
    return http.get(productService + 'sysAdmin/businessDistrict/all', params)
  }, // 查询商圈列表
  businessDistrictList(params) {
    return http.get(productService + 'sysAdmin/businessDistrict/list', params)
  },
  // 根据父id查询子商圈
  businessDistrictListId(params, config) {
    return http.get(productService + 'sysAdmin/businessDistrict/list/' + params, config)
  },
  // 根据id查询商圈
  businessDistrictId(params, config) {
    return http.get(productService + 'sysAdmin/businessDistrict/' + params, config)
  },

  // =============门店权限==============

  //添加门店角色
  postStoreSysRoles(params) {
    return http.post(adminService + 'sysAdmin/businessRole', params)
  },
  //修改门店角色
  putStoreSysRoles(params) {
    return http.put(adminService + 'sysAdmin/businessRole/role', params)
  },
  // 获取角色详情
  getStoreSysRoles() {
    return http.get(adminService + 'businessRole' + params)
  },
  // 获取门店权限详情
  getStorePrivilegeList(params) {
    return http.get(adminService + 'sysAdmin/businessPrivilege/role/privilegeList/' + params)
  },
  // 获取门店角色列表
  getStoreRoleList(params) {
    return http.get(adminService + 'sysAdmin/businessRole/business', params)
  },
  // 门店的二级商户号
  getSubMerchant(params) {
    return http.get(productService + 'sysAdmin/subMerchant/all/finish', params)
  },

  // ========商家人员管理===========

  // 添加人员
  postBusinessUser(params) {
    return http.post(mixService + 'sysAdmin/businessUser/insert/business', params)
  },
  // 修改人员
  putBusinessUser(params) {
    return http.put(adminService + 'sysAdmin/businessUser/update/businessUser', params)
  },
  // 搜索单个人员
  getBusinessUserDetail(params) {
    return http.get(mixService + 'sysAdmin/businessUser/' + params)
  },
  // 获取人员列表
  getBusinessUser(params, id) {
    return http.get(mixService + 'sysAdmin/businessUser/business/' + id, params)
  },

  // 根据手机号码获取会员详情信息
  getMemberByPhone(params) {
    return http.get(userService + 'sysAdmin/members/getMemberInfoByPurePhoneNumber/' + params)
  },

  getStoreListBySubMerchantId(params) {
    return http.get(productService + 'sysAdmin/store/list/bySubMerchantId', params)
  }

  // //{phoneNumber}
}

export function vendor(tag, params, setting) {
  return httpVendor[tag](params, setting)
}
