<template>
  <div class="upload-container">
    <el-button
      :style="{ background: color, borderColor: color }"
      icon="el-icon-upload"
      size="mini"
      type="primary"
      @click="dialogVisible = true"
    >
      上传图片
    </el-button>
    <el-dialog :close-on-click-modal="false" :visible.sync="dialogVisible">
      <draggable v-model="fileList">
        <div v-for="(item, index) in fileList" :key="index" class="listitem">
          <img :src="item.url | thumbSmall" width="148" height="148" />
          <i class="deleteImg el-icon-close" @click="handleRemovePic(index)"></i>
        </div>
      </draggable>
      <el-upload
        :multiple="true"
        :file-list="fileList"
        :show-file-list="false"
        class="editor-slide-upload"
        action="http://"
        :on-remove="onRemove"
        :http-request="handleUpload"
        list-type="picture-card"
      >
        <el-button size="small" type="primary">
          点击上传
        </el-button>
      </el-upload>
      <el-button @click="dialogVisible = false">
        取消
      </el-button>
      <el-button type="primary" @click="handleConfirm">
        确定
      </el-button>
    </el-dialog>
  </div>
</template>

<script>
// import { getToken } from 'api/qiniu'
import draggable from 'vuedraggable'
export default {
  components: {
    draggable
  },
  name: 'EditorSlideUpload',
  props: {
    color: {
      type: String,
      default: '#1890ff'
    }
  },
  data() {
    return {
      dialogVisible: false,
      listObj: {},
      fileList: []
    }
  },
  methods: {
    handleUpload({ file }) {
      this.$store.dispatch('upload/image', { file: file, type: 'commodity', reduceResolution: true }).then(res => {
        this.fileList.push({ name: file.name, url: res })
      })
    },
    handleConfirm() {
      this.$emit('successCBK', this.fileList)
      this.dialogVisible = false
      this.fileList = []
    },
    handleRemovePic(index) {
      this.fileList.splice(index, 1)
    },
    onRemove(file, list) {
      this.fileList = list
    }
  }
}
</script>

<style lang="less" scoped>
.editor-slide-upload {
  margin-bottom: 20px;

  ::v-deep .el-upload--picture-card {
    width: 100%;
  }
}
.listitem {
  display: inline-block;
  margin: 30px;
  img {
    border-radius: 10px;
  }
  .deleteImg {
    vertical-align: top;
    font-size: 17px;
    color: red;
  }
}
</style>
