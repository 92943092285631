const state = {
  colorList: ['#e74c3c', '#1abc9c', '#3498db', '#9b59b6', '#2ecc71', '#34495e', '#f1c40f', '#e67e22', '#95a5a6']
}
const getters = {
  colorList: state => state.colorList
}
const mutations = {
  update: (state, { key, value }) => (state[key] = value)
}
const actions = {}

export default {
  namespaced: true,
  state,
  getters,
  mutations,
  actions
}
