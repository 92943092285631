import http from '@/plugin/axios'
const adminService = 'boom-center-admin-service/'
const wxService = 'boom-center-wechat-service/'
let httpBase = {
  // ===============退款原因列表=========================
  // 退款原因列表
  refundReasonList(params) {
    return http.get(adminService + 'sysAdmin/refundReason/page', params)
  },
  // 修改退款原因
  putRefundReason(params, id) {
    return http.put(adminService + 'sysAdmin/refundReason', params)
  },
  // 新增退款原因
  postRefundReason(params) {
    return http.post(adminService + 'sysAdmin/refundReason', params)
  },

  // ===================商城设置========================
  // 获取商城设置
  getMallSetting(params) {
    return http.get(adminService + 'sysAdmin/mallSetting', params)
  },
  // 新增商城设置
  postMallSetting(params) {
    return http.post(adminService + 'sysAdmin/mallSetting', params)
  },
  // 修改商城设置
  putMallSetting(params) {
    return http.put(adminService + 'sysAdmin/mallSetting', params)
  },
  // ==========================内容设置 ======================
  // 新增内容设置

  postSysMallContent(params) {
    return http.post(adminService + 'sysAdmin/sysMallContent', params)
  },

  // 修改内容设置

  putSysMallContent(params) {
    return http.put(adminService + 'sysAdmin/sysMallContent', params)
  },
  // 获取商城内容设置

  getSysMallContent() {
    return http.get(adminService + 'sysAdmin/sysMallContent')
  },
  // =========================================系统用户设置==============
  // 新增系统用户

  postSysUser(params) {
    return http.post(adminService + 'sysAdmin/sysUser', params)
  },
  // 修改系统用户

  putSysUser(params) {
    return http.put(adminService + 'sysAdmin/sysUser/updateInfo', params)
  },
  // 获取系统用户列表

  getSysUserList(params) {
    return http.get(adminService + 'sysAdmin/sysUser/listPage', params)
  },

  // 修改用户状态，是否启用

  changeStatus(params) {
    return http.put(adminService + 'sysAdmin/sysUser/updateDeleteStatus', params)
  },
  // 修改用户状态，是否启用

  updatePwd(params) {
    return http.put(adminService + 'sysAdmin/sysUser/updatePwd', params)
  },

  // =================操作记录 ======================
  getSysUserLoginLog(params) {
    return http.get(adminService + 'sysAdmin/sysUserLoginLog', params)
  },

  // 地区设置 ===========================

  // 地区列表-分页

  areaSettingList(params) {
    return http.get(adminService + 'sysAdmin/area-settings/page', params)
  },

  //地区列表V1
  areaList(params) {
    return http.get(adminService + 'sysAdmin/area-settings/page/v1', params)
  },

  addHotArea(params) {
    return http.post(adminService + 'sysAdmin/area-settings/addHots', params)
  },

  delHotArea({ id }) {
    return http.put(adminService + `sysAdmin/area-settings/disableHot/${id}`)
  },
  changeHotSort({ id, sort }) {
    return http.put(adminService + `sysAdmin/area-settings/updateHotSortNum/${id}?sortNum=${sort}`)
  },

  // 添加地区
  addAreaSetting(params) {
    return http.post(adminService + 'sysAdmin/area-settings', params)
  },
  // 获取地区详情

  areaSettingDetail(params) {
    return http.get(adminService + 'sysAdmin/area-settings/' + params)
  },
  // 编辑地区
  editAreaSetting(params) {
    return http.put(adminService + 'sysAdmin/area-settings', params)
  },

  // 禁用
  disableArea(params) {
    return http.put(adminService + 'sysAdmin/area-settings/disable/' + params)
  },

  //启用
  enableArea(params) {
    return http.put(adminService + 'sysAdmin/area-settings/enable/' + params)
  },
  // 查询所有成功状态的二级商户号列表
  platformSubMerchant() {
    return http.get(wxService + 'sysAdmin/platform/sub-merchant/list')
  },

  //添加系统默认门店角色
  addDefaultStoreSysRoles(params) {
    return http.post(adminService + 'sysAdmin/businessRole/default/role', params)
  },
  //修改系统默认门店角色
  editDefaultStoreSysRoles(params) {
    return http.put(adminService + 'sysAdmin/businessRole/redact/defaultRole', params)
  },
  // 查询所有成功状态的二级商户号列表
  mchSettingList() {
    return http.get(wxService + 'sysAdmin/mchSetting')
  },
  // 查询所有成功状态的二级商户号列表
  mchSettingEdit(id, params) {
    return http.put(wxService + `sysAdmin/mchSetting/${id}`, params)
  },
  // 查询所有成功状态的二级商户号列表
  mchSettingSwitch(id) {
    return http.put(wxService + `sysAdmin/mchSetting/switch/default/${id}`)
  }
}

export function base(tag, params, id) {
  return httpBase[tag](params, id)
}
