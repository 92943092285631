<template>
  <div class="classify">
    <template v-if="!canEdit" id="point_wrapper">
      <template v-for="(d, i) in cptData.data">
        <div class="classify-item" :key="i" v-if="d.hideStatus == 0">
          <img :src="d.bannerImageUrl" alt="" />
          <p>{{ d.zoneName }}</p>
        </div>
      </template>
      <template v-if="!cptData.data.length"> <p class="remindText">金刚区组件</p> </template>
    </template>
    <template v-else>
      <div class="classify-edit">
        <p class="c-e-header">金刚分类区</p>
        <el-button type="primary" class="add_btn" @click="addClassify">添加分类</el-button>
        <div>
          <el-collapse v-model="activeName" :accordion="true">
            <template v-for="(classifyData, i) in cptData.data">
              <el-collapse-item :name="cptData.componentName + i" :key="'classify' + i">
                <template slot="title">
                  <span style="margin-left: 10px; font-weight: 700">{{
                    i + 1 + (classifyData.zoneName ? '-' + classifyData.zoneName : '')
                  }}</span>
                  <span style="font-size: 12px">
                    {{ '（' + (classifyData.hideStatus == 0 ? '√' : '×') + '）' }}
                  </span>
                </template>
                <el-form
                  :ref="'form' + i"
                  :rules="rules"
                  :model="classifyData"
                  class="vendorFrom"
                  label-position="right"
                  label-width="100px"
                >
                  <el-form-item label="金刚区名称" prop="zoneName">
                    <el-input
                      type="string"
                      v-model="classifyData.zoneName"
                      :maxlength="6"
                      placeholder="金刚区名称"
                    ></el-input>
                  </el-form-item>
                  <el-form-item label="状态">
                    <el-switch
                      v-model="classifyData.hideStatus"
                      :active-value="0"
                      :inactive-value="1"
                      @change="handleChangeState"
                    ></el-switch>
                  </el-form-item>
                  <el-form-item label="图标" prop="bannerImageUrl">
                    <bm-upload v-model="classifyData.bannerImageUrl" type="system"></bm-upload>
                    <span class="assist">建议尺寸84*84</span>
                  </el-form-item>

                  <el-form-item label="背景图" prop="typeImageUrl">
                    <bm-upload v-model="classifyData.typeImageUrl" type="system"></bm-upload>
                    <span class="assist">建议尺寸750*364</span>
                  </el-form-item>

                  <el-form-item label="排序" prop="zoneSort">
                    <el-input-number
                      v-model="classifyData.zoneSort"
                      controls-position="right"
                      :min="0"
                      :step="1"
                      :max="9999"
                      placeholder="选择排序"
                    ></el-input-number>
                  </el-form-item>

                  <el-form-item label="跳转到">
                    <el-radio-group v-model="classifyData.type">
                      <el-radio :label="0">商品分类</el-radio>
                      <el-radio :label="1">页面路径</el-radio>
                    </el-radio-group>
                  </el-form-item>
                  <el-form-item label="商品分类" prop="categoryId" v-if="classifyData.type == 0">
                    <activityClassify v-model="classifyData.categoryId"></activityClassify>
                  </el-form-item>
                  <el-form-item label="跳转到页面" prop="linkUrl" v-if="classifyData.type == 1">
                    <el-input v-model="classifyData.linkUrl" placeholder="输入页面路径"></el-input>
                  </el-form-item>

                  <el-form-item v-loading="loading">
                    <!-- <el-button type="primary" @click="list">列表</el-button> -->
                    <el-button type="primary" @click="save(i)">保存</el-button>
                    <el-button type="danger" @click="remove(i)">删除</el-button>
                  </el-form-item>
                </el-form>
              </el-collapse-item>
            </template>
          </el-collapse>
        </div>
      </div>
    </template>
  </div>
</template>

<script>
import { log } from '@/util/log'
import activityClassify from './__com__/activityClassify'
export default {
  name: 'classify',
  components: { activityClassify },
  props: {
    cptData: {
      type: Object,
      default: {
        componentName: 'classify',
        selectStatus: false,
        data: [
          {
            hideStatus: 0
          }
        ]
      },
      require: true
    },
    canEdit: {
      type: Boolean,
      default: false,
      require: false,
      comment: '是否编辑状态， true是，会在装修页面右侧调用'
    }
  },
  data() {
    return {
      loading: false,
      defaultImg: require('@/asset/icon/saoma@2x.png'),
      activeName: this.cptData.componentName + '0',
      rules: {
        zoneName: [{ required: true, message: '请输入金刚区名称', trigger: 'blur' }],
        bannerImageUrl: [{ required: true, message: '请上传图标', trigger: 'change' }],
        typeImageUrl: [{ required: true, message: '请上传背景图', trigger: 'change' }],
        categoryId: [{ required: true, message: '选择分类', trigger: 'change' }],
        linkUrl: [{ required: true, message: '未填写路径', trigger: 'blur' }]
      },
      
      model: {
        bannerImageUrl: '',
        categoryId: '',
        categoryName: '',
        cityId: '',
        cityName: '',
        hideStatus: 0,
        id: '',
        linkUrl: '',
        typeImageUrl: '',
        updateUserId: '',
        zoneName: '',
        zoneSort: 0,
        type: 0
      },
      areaId: this.$store.getters['malls/getCurrentEditAreaId'],
      curIndex: 0
    }
  },
  created() {
    if (!this.cptData.data.length) {
      this.cptData.data.push({ ...this.model })
    }
  },
  methods: {
    openCurIndex(index) {
      let len = this.cptData.data.length
      if (index > len - 1) {
        this.curIndex = len - 1
      } else {
        this.curIndex = index
      }
      this.activeName = this.cptData.componentName + this.curIndex
    },
    getClassifyId(id) {
      // alert(id)
    },

    async list() {
      let res = await this.$api.mall.mall(
        'getClassifyList',
        {},
        {
          headers: {
            ['Bm-Area-Id']: -1
          }
        }
      )
    },
    addClassify() {
      this.cptData.data.push({ ...this.model })
      this.openCurIndex(this.cptData.data.length - 1)
    },
    handleChangeState() {},
    save(index) {
      this.openCurIndex(index)
      // this.$refs['form' + index] &&
      this.$refs['form' + index][0].validate(valid => {
        if (valid) {
          this.loading = true
          let currentEditItem = this.cptData.data[index]
          let tag = ''
          if (currentEditItem.id) {
            tag = 'putClassify'
          } else {
            tag = 'postClassify'
          }
          this.loading = true
          this.$api.mall
            .mall(
              tag,
              { ...this.model, ...currentEditItem },
              {
                headers: {
                  ['Bm-Area-Id']: this.areaId
                }
              }
            )
            .then(res => {
              this.cptData.data[index].id = res.id
              this.$message.success('保存成功')
            })
            .catch(err => {
              this.$message.error(err)
            })
            .finally(() => {
              setTimeout(() => {
                this.loading = false
              }, 200)
            })
        }
      })
    },
    async remove(index) {
      this.curIndex = index
      let res = await this.$confirm('此操作将永久删除该分类, 是否继续?', '提示', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning'
      })
      if (res == 'confirm') {
        this.loading = true
        if (this.cptData.data[index].id) {
          this.$api.mall
            .mall('deleteClassify', this.cptData.data[index].id)
            .then(() => {
              this.$message.success('移除成功')
              this.cptData.data.splice(index, 1)
              this.openCurIndex(index)
            })
            .catch(err => {
              this.$message.error(err)
            })
            .finally(() => {
              setTimeout(() => {
                this.loading = false
              }, 200)
            })
        } else {
          this.cptData.data.splice(index, 1)
          this.openCurIndex(index)
          setTimeout(() => {
            this.loading = false
          }, 200)
        }
      } else {
        this.$message({
          type: 'info',
          message: '已取消删除'
        })
      }
    }
  }
}
</script>

<style lang="less" scoped>
@import './com.less';
.classify {
  min-height: 90px;
  border: 1px solid transparent;
  padding: 10px 5px;
  .radius(6px);

  &:hover {
    cursor: pointer;
    box-shadow: 0 0 1px @color-border;
  }
  .remindText {
    min-height: 90px;
    line-height: 80px;
    text-align: center;
    color: #999999;
    border: 1px dotted #999999;
  }
  .classify-item {
    display: inline-block;
    width: 20%;
    padding: 0;
    text-align: center;
    vertical-align: top;
    img {
      width: 50px;
      height: 50px;
    }
    p {
      height: 21px;
      font-size: 12px;
    }
  }
  .classify-edit {
    padding: 20px;
    margin: -10px -5px;
    background-color: @color-background;
    .c-e-header {
      margin-bottom: 20px;
      font-weight: 700;
    }
  }
}
</style>
