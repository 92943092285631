import http from '@/plugin/axios'
const mixService = 'boom-mix-openapi-homebrew-service/'
const statistics = 'boom-center-statistics-service/'
const adminService = 'boom-center-admin-service/'
let httpOpenapi = {
  sharedProductData() {
    return http.get(statistics + 'sysAdmin/sharedProduct/get')
  },
  partnerList(params) {
    return http.get(mixService + 'sysAdmin/partner', params)
  },
  addPartner(params) {
    return http.post(mixService + `sysAdmin/partner`, params)
  },
  editPartner(params, id) {
    return http.put(mixService + `sysAdmin/partner/${id}`, params)
  },
  getPartnerDetail(id) {
    return http.get(mixService + `sysAdmin/partner/${id}`)
  },
  resetAppSecret(id) {
    return http.put(mixService + `sysAdmin/partner/reset/app_secret/${id}`)
  },
  rechargeConfirm(params) {
    return http.post(mixService + `sysAdmin/partner/recharge/confirm`, params)
  },
  rechargeCreate(params) {
    return http.post(mixService + `sysAdmin/partner/recharge/create`, params)
  },
  rechargeList(params) {
    return http.get(mixService + `sysAdmin/partner/recharge/list`, params)
  },
  tenantList(params) {
    return http.get(adminService + `sysAdmin/sysTenant/enableList`, params)
  }
}
export function openapi(tag, params, setting) {
  return httpOpenapi[tag](params, setting)
}
