import { Message } from 'element-ui'

const state = {
  colorList: ['#e74c3c', '#1abc9c', '#2ecc71', '#3498db', '#9b59b6', '#34495e', '#f1c40f', '#e67e22', '#95a5a6'],
  foo: 'abc',
  // 缓存页面
  cache: [
    'lotteryList',
    'normalProductList',
    'codelessProductList',
    'ImportCodeProductList',
    'businessList',
    'businessStoreList',
    'seckillList'
  ]
}
const getters = {
  isDev: () => process.env.NODE_ENV === 'development',
  randomColor: state => () => state.colorList[Math.floor(Math.random() * state.colorList.length)],
  todayStr: () => new Date().toISOString().substr(0, 10),
  todayInt: () => new Date(new Date().toLocaleDateString()).getTime() + 3600 * 1000 * 24 - 1,
  day7Str: () => new Date(Date.now() - 6.048e8).toISOString().substr(0, 10),
  day7Int: () => new Date(new Date().toLocaleDateString()) - 3600 * 1000 * 24 * 7,
  ua: () => navigator.userAgent.toLowerCase(),
  isIOS: (state, getters) => getters.ua.includes('ios'),
  isEnvDev: () => process.env.VUE_APP_env === 'dev',
  isEnvTest: () => process.env.VUE_APP_env === 'test',
  isEnvNext: () => process.env.VUE_APP_env === 'next',
  isEnvProd: () => process.env.VUE_APP_env === 'prod',
  foo: state => state.foo
}
const mutations = {
  update: (state, { key, value }) => (state[key] = value)
}
const actions = {
  changeTenant(store, payload) {
    sessionStorage.setItem('tenantId', payload)
    store.commit('keyboard/clearCheatKey', {}, { root: true })
    Message({
      type: 'success',
      message: '已切换租户ID: ' + payload
    })
  }
}

export default {
  namespaced: true,
  state,
  getters,
  mutations,
  actions
}
