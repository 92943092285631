<template>
  <div class="activity-classify">
    <div :value="value">
      <el-cascader
        v-model="productCategoryIds"
        :options="productClassify"
        :props="props"
        @input="input"
        clearable
        placeholder="选择分类"
      ></el-cascader>
    </div>
  </div>
</template>

<script>
export default {
  props: {
    value: {
      type: [Number, String],
      require: true,
      default: 0
    }
  },
  data() {
    return {
      productClassify: [],
      productCategoryIds: [],
      props: {
        value: 'id',
        label: 'categoryName',
        children: 'childList',
        checkStrictly: true
      },
      findIdFlag: false
    }
  },
  created() {
    this.getProductCategory()
  },
  methods: {
    getIdsByValue(categoryObject) {
      for (let i of categoryObject) {
        if (this.findIdFlag) {
          return
        }
        if (this.value == i.id) {
          this.findIdFlag = true
          this.productCategoryIds.unshift(i.id)
          return
        } else if (i.childList && i.childList.length) {
          this.getIdsByValue(i.childList)
          if (this.findIdFlag) {
            this.productCategoryIds.unshift(i.id)
          }
        }
      }
    },
    input(v) {
      // console.log('11111>', this.productCategoryIds)
      let id = v[v.length - 1]
      this.$emit('input', id)
    },

    treeData(data) {
      for (let i = 0; i < data.length; i++) {
        if (data[i].childList.length === 0) {
          data[i].childList = undefined
        } else {
          this.treeData(data[i].childList)
        }
      }
      return data
    },
    getProductCategory() {
      this.$api.product
        .product('productCategoryAll')
        .then(res => {
          let data = this.treeData(res)
          try {
            for (let i = 0; i < data.length; i++) {
              if (data[i].childList) {
                for (let j = 0; j < data[i].childList.length; j++) {
                  data[i].childList[j].childList = undefined
                }
              }
            }
          } catch (err) {
            console.log(err)
          }
          this.productClassify = data
          //childList
          if (this.value) this.getIdsByValue(this.productClassify)
        })
        .catch(err => {
          this.$message.error(err.msg || '获取商品分类失败')
        })

      return
    },
    getCategoryName(value) {
      let back = ''
      this.productClassify.forEach(item => {
        if (value === item.id) {
          back = item.categoryName
        }
      })
      if (back) {
        return back
      } else {
        this.productClassify.forEach(item => {
          if (item.childList) {
            item.childList.forEach(childItem => {
              if (value === childItem.id) {
                back = childItem.categoryName
              }
            })
          }
        })
        return back
      }
    }
  }
}
</script>

<style>
</style>