const state = {
  currentType: 'search',
  isDrag: false, //确保是真正的拖拽元素
  currentEditAreaId: 1096 //商城装修当前编辑 的区域
}

const getters = {
  getCurrentEditAreaId(state) {
    return state.currentEditAreaId
  }
}

const mutations = {
  update: (state, value) => {
    state.currentType = value
  },
  setCurrentEditAreaId: (state, value) => {
    state.currentEditAreaId = value
  },
  updateDrag: (state, value) => {
    state.isDrag = value
  }
}

export default {
  namespaced: true,
  state,
  getters,
  mutations
}
