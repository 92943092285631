<template>
  <div>
    <el-dialog
      :close-on-click-modal="false"
      title="确认删除该记录后，便无法恢复该记录"
      :visible.sync="dialogVisible"
      append-to-body
      width="30%"
    >
      <span class="assist"> 您确定要删除该记录吗？</span>
      <span slot="footer" class="dialog-footer">
        <el-button @click="dialogVisible = false">取 消</el-button>
        <el-button type="primary" @click="deleteItem">确 定</el-button>
      </span>
    </el-dialog>
  </div>
</template>

<script>
export default {
  name: 'bm-deleteItem',
  props: {
    timestamp: Number,
    url: String
  },
  data() {
    return { dialogVisible: false }
  },

  watch: {
    timestamp(n, o) {
      //有值改变就把把值改成true，显示弹窗
      this.dialogVisible = true
    }
  },

  methods: {
    deleteItem() {
      // 获取要删除的ID
      const id = sessionStorage.getItem('deleteItemId')
      this.$http
        .delete(this.url + id)
        .then(value => {
          // 删除之后的回调函数，可以用在删除之后重新请求列表
          this.$emit('successDelete')
          // 隐藏弹窗
          this.dialogVisible = false
        })
        .catch(err => {
          this.$message.error(err.msg || '操作失败')
        })
    }
  }
}
</script>

<style lang="less" scoped></style>
