import Vue from 'vue'
import Router from 'vue-router'
import routeList from 'vue-auto-routing'
import { createRouterLayout } from 'vue-router-layout'
import store from '@/plugin/store'
import { Message } from 'element-ui'

Vue.use(Router)

const routerLayout = createRouterLayout(layout => {
  return import('@/layout/' + layout + '.vue')
})

const routerPush = Router.prototype.push
Router.prototype.push = function push(location) {
  return routerPush.call(this, location).catch(error => error)
}

const router = new Router({
  mode: 'history',
  routes: [
    {
      path: '/',
      component: routerLayout,
      children: [
        ...routeList,
        {
          path: '/',
          redirect: '/malls/overview'
        }
      ]
    },
    {
      path: '*',
      redirect: '/redirect/404'
    }
  ]
})

router.beforeEach((to, from, next) => {
  if (to.meta && !to.meta.allowGuest && !store.getters['user/isLogin']) {
    next({
      path: '/account/login',
      query: {
        c: to.path
      }
    })
  }
  if (to.meta && to.meta.auth) {
    const authList = store.getters['user/authList']
    const authCodeList = to.meta.auth.split(',')
    if (!authList.some(a => authCodeList.includes(a))) {
      Message.error('权限不足')
      next({
        path: '/'
      })
    } else {
      next()
    }
  } else {
    next()
  }
})

router.afterEach(to => {
  if (to.meta && to.meta.title) {
    document.title = to.meta.title + ' - Powered By Boomsj-Software'
  }
})

export default router
