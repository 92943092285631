const state = {
  cheatKeyList: [],
  cheatKeyOrigin: [
    'ArrowUp',
    'ArrowUp',
    'ArrowDown',
    'ArrowDown',
    'ArrowLeft',
    'ArrowRight',
    'ArrowLeft',
    'ArrowRight',
    'b',
    'a'
  ]
}
const getters = {
  isCheatCodeMatch(state) {
    if (state.cheatKeyList.length !== state.cheatKeyOrigin.length) {
      return false
    }
    let isMatch = true
    for (let i = 0; i <= state.cheatKeyList.length; i++) {
      if (state.cheatKeyList[i] !== state.cheatKeyOrigin[i]) {
        isMatch = false
      }
    }
    return isMatch
  },
  cheatKeyOrigin: state => state.cheatKeyOrigin
}
const mutations = {
  pushCheatKey(state, payload) {
    if (state.cheatKeyList.length > state.cheatKeyOrigin.length) {
      state.cheatKeyList = []
    } else {
      const n = state.cheatKeyList.length
      if (payload === state.cheatKeyOrigin[n]) {
        state.cheatKeyList.push(payload)
      }
    }
  },
  clearCheatKey(state) {
    state.cheatKeyList = []
  }
}
const actions = {
  initKeyboardListener(store) {
    window.addEventListener('keydown', e => {
      if (e.key && store.getters.cheatKeyOrigin.includes(e.key)) {
        store.commit('pushCheatKey', e.key)
      }
    })
  }
}

export default {
  namespaced: true,
  state,
  getters,
  mutations,
  actions
}
