import http from '@/plugin/axios'
const orderService = 'boom-center-order-service/'
const searchServie = 'boom-center-search-service/'
let orderProduct = {
  // 获取订单列表
  orderList(params, config) {
    return http.get(searchServie + 'sysAdmin/order/page', params, config)
  },
  // 申请退款

  refundAudit(params) {
    return http.post(orderService + 'sysAdmin/refund/audit', params)
  },

  // 获取订单详情

  getOrderDetail(params, id) {
    return http.get(orderService + 'sysAdmin/order/' + id, params)
  },

  // 平台发起退款
  platformRefund(params) {
    return http.post(orderService + 'sysAdmin/refund/apply', params)
  }
}
export function order(tag, params, setting) {
  return orderProduct[tag](params, setting)
}
