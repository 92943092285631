const state = {
  page: {}
}
const getters = {
  colorList: state => state.colorList
}
const mutations = {
  update: (state, { key, value }) => (state[key] = value)
}
const actions = {}

export default {
  namespaced: true,
  state,
  getters,
  mutations,
  actions
}
