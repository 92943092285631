const state = {
  currentId: 0
}
const mutations = {
  updateCurId(state, payload) {
    state.currentId = payload
  }
}

export default {
  namespaced: true,
  state,
  mutations
}
