import Vue from 'vue'
import store from '@/plugin/store'

Vue.directive('bg', {
  inserted: (el, bind) => {
    el.style.backgroundImage = `url(${bind.value})`
    el.style.backgroundSize = `contain`
    el.style.backgroundRepeat = `no-repeat`
    el.style.backgroundPosition = `center`
    if (bind.modifiers.cover) {
      el.style.backgroundSize = `cover`
    }
    if (bind.modifiers.contain) {
      el.style.backgroundSize = `contain`
    }
    if (bind.modifiers.center) {
      el.style.backgroundSize = `center`
    }
  },
  update: (el, bind) => {
    el.style.backgroundImage = `url(${bind.value})`
    el.style.backgroundSize = `contain`
    el.style.backgroundRepeat = `no-repeat`
    el.style.backgroundPosition = `center`
    if (bind.modifiers.cover) {
      el.style.backgroundSize = `cover`
    }
  }
})

Vue.directive('auth', {
  inserted: (el, bind) => {
    if (bind && bind.value) {
      const authList = store.getters['user/authList']
      const authCodeList = bind.value.split(',')
      if (!authList.some(a => authCodeList.includes(a))) {
        if (el instanceof HTMLElement) {
          el.remove()
        }
      }
    }
  }
})

Vue.directive('move', {
  inserted: (el, bind) => {
    el.onmousedown = e => {
      let disX = e.clientX - el.offsetLeft
      let disY = e.clientY - el.offsetTop
      document.onmousemove = function(e) {
        //通过事件委托，计算移动的距离
        let l = e.clientX - disX
        let t = e.clientY - disY
        //移动当前元素
        if (bind.arg === 'bound') {
          let boundRect = el.getBoundingClientRect()
          l = l < 0 ? 0 : l
          l = l + boundRect.width > document.body.clientWidth ? document.body.clientWidth - boundRect.width : l
          t = t < 0 ? 0 : t
          t = t + boundRect.height > document.body.clientHeight ? document.body.clientHeight - boundRect.height : t
        }

        el.style.left = l + 'px'
        el.style.top = t + 'px'
      }
      document.onmouseup = function(e) {
        document.onmousemove = null
        document.onmouseup = null
      }
    }
  }
})
