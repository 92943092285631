<template>
  <div class="area-classify">
    <div>
      <template v-if="!canEdit">
        <template v-if="!cptData.data.length"> <p class="remindText">商圈分类组件</p> </template>
        <template v-for="(d, i) in cptData.data">
          <template v-if="d.hideStatus == 0">
            <div :key="i" class="ac-item" :class="{ acActlve: activeBtnIndex == i }" @click="selectArea(i)">
              <div>{{ d.name }}</div>
              <div></div>
            </div>
          </template>
        </template>
      </template>
      <template v-else>
        <div class="ac-edit">
          <p class="c-e-header">商圈/商品分类菜单组件</p>
          <el-button type="primary" class="add_btn" @click="addActiveItem">添加商圈/商品分类</el-button>
          <div>
            <el-collapse v-model="activeName" :accordion="true">
              <drag v-for="(classifyData, i) in cptData.data" :key="i" :index="i" :data="cptData.data">
                <el-collapse-item :name="cptData.componentName + i" :key="'classifyData' + i">
                  <template slot="title">
                    <span style="margin-left: 10px; font-weight: 700"
                      >{{ i + 1 + (classifyData.name ? '-' + classifyData.name : '') }}
                    </span>
                    <span style="font-size: 12px">
                      {{ '（' + (classifyData.hideStatus == 0 ? '√' : '×') + '）' }}
                    </span>
                  </template>
                  <el-form
                    ref="classifyData"
                    :rules="rules"
                    :model="classifyData"
                    class="vendorFrom"
                    label-position="right"
                    label-width="100px"
                  >
                    <el-form-item label="状态">
                      <el-switch
                        v-model="classifyData.hideStatus"
                        :active-value="0"
                        :inactive-value="1"
                        @change="handleChangeState"
                      ></el-switch>
                    </el-form-item>
                    <el-form-item label="分类类型">
                      <el-radio-group v-model="classifyData.linkType" @change="clRadioChange(i)">
                        <el-radio vlaue="" :label="0">商品分类</el-radio>
                        <el-radio vlaue="" :label="1">商圈分类</el-radio>
                        <el-radio vlaue="" :label="2">活动列表</el-radio>
                      </el-radio-group>
                    </el-form-item>
                    <el-form-item prop="name" :label="(classifyData.linkType == 0 ? '分类' : '商圈') + '名称'">
                      <el-input v-model="classifyData.name" placeholder="输入自定义名称"></el-input>
                    </el-form-item>
                    <el-form-item prop="linkId" label="跳转分类" v-if="classifyData.linkType == 0">
                      <activityClassify v-model="classifyData.linkId"></activityClassify>
                    </el-form-item>
                    <el-form-item prop="linkId" label="跳转商圈" v-else-if="classifyData.linkType == 1">
                      <el-select v-model="classifyData.linkId">
                        <template v-for="(d, ii) in businessList">
                          <el-option :value="d.id" :label="d.districtTitle" :key="'business' + ii"> </el-option>
                        </template>
                      </el-select>
                    </el-form-item>
                    <el-form-item prop="linkId" label="跳转活动" v-else-if="classifyData.linkType == 2">
                      <activitySelect v-model="classifyData.linkId"></activitySelect>
                    </el-form-item>
                    <!-- <el-form-item label="排序" prop="sort">
                      <el-input-number
                        v-model="classifyData.sort"
                        controls-position="right"
                        :min="0"
                        :step="1"
                        :max="9999"
                      ></el-input-number>
                    </el-form-item> -->
                    <el-form-item v-loading="loading">
                      <el-button type="primary" @click="save(i)">保存</el-button>
                      <el-button type="danger" @click="remove(i)">删除</el-button>
                    </el-form-item>
                  </el-form>
                </el-collapse-item>
              </drag>
            </el-collapse>
          </div>
        </div>
      </template>
    </div>
  </div>
</template>

<script>
import { log } from '@/util/log'
import activityClassify from './__com__/activityClassify'
import activitySelect from './__com__/activitySelect'
import drag from '@/component/adorn/__com__/drag'
export default {
  name: 'area-classify',
  components: { activityClassify, activitySelect, drag },

  props: {
    cptData: {
      type: Object,
      default: {
        componentName: 'areaAndClassify',
        selectStatus: false,
        data: [
          {
            hideStatus: 0
          }
        ]
      },
      require: true
    },
    canEdit: {
      type: Boolean,
      default: false,
      require: false,
      comment: '是否编辑状态， true是，会在装修页面右侧调用'
    }
  },
  data() {
    return {
      loading: false,
      activeBtnIndex: 0,
      areaData: [],
      activeName: this.cptData.componentName + '0',
      productClassify: [],
      businessList: [],
      rules: {
        linkId: [
          {
            required: true,
            trigger: 'cahnge',
            validator: (rule, value, callback) => {
              let type = this.cptData.data[this.curIndex].linkType
              let msg = ''
              if (type == 0) {
                msg = '选择商品分类'
              } else if (type == 1) {
                msg = '选择商圈分类'
              } else if (type == 2) {
                msg = '选择活动分类'
              }
              if (!value) {
                return callback(new Error(msg))
              } else {
                let data = this.cptData.data
                for (let i in data) {
                  if (value == data[i].linkId && i != this.curIndex) {
                    return callback(new Error(`在第${Number(i) + 1}个分类里已选此项`))
                  } else {
                    callback()
                  }
                }
              }
            }
          }
        ],
        name: [{ required: true, message: '请输入自定义名称', trigger: 'change' }]
      },
      model: {
        areaId: 0,
        hideStatus: 0,
        id: '',
        linkId: '',
        linkType: 0,
        linkUrl: '',
        name: ''
        // sort: 0
      },
      areaId: this.$store.getters['malls/getCurrentEditAreaId'],
      curIndex: 0
    }
  },
  created() {
    this.businessDistrictList()
  },
  methods: {
    openCurIndex(index) {
      let len = this.cptData.data.length
      if (index > len - 1) {
        this.curIndex = len - 1
      } else {
        this.curIndex = index
      }
      this.activeName = this.cptData.componentName + this.curIndex
    },
    selectArea(index) {
      this.activeBtnIndex = index
    },

    addActiveItem() {
      this.cptData.data.push({ ...this.model })
      this.openCurIndex(this.cptData.data.length - 1)
    },

    //获取商圈
    businessDistrictList() {
      this.$api.vendor
        .vendor('businessDistrictList', {
          headers: {
            ['Bm-Area-Id']: this.areaId
          }
        })
        .then(res => {
          this.businessList = [...res.list]
        })
      // this.$api.vendor
      //   .vendor('businessDistrictAll', {
      //     // this.$api.mall.mall('getClassifyList')
      //   })
      //   .then(res => {
      //     console.log('所有》》》', res)
      //   })
    },
    save(index) {
      this.openCurIndex(index)
      this.$refs['classifyData'][index].validate(valid => {
        if (valid) {
          this.loading = true
          this.$api.mall
            .mall('postdhn', this.cptData.data, {
              headers: {
                ['Bm-Area-Id']: this.areaId
              }
            })
            .then(res => {
              this.cptData.data = [...res]
              this.$message.success('保存成功')
            })
            .catch(err => {
              this.$message.error(err.msg || '操作失败')
            })
            .finally(() => {
              setTimeout(() => {
                this.loading = false
              }, 200)
            })
        } else {
          this.$message.info('请补全信息')
          return false
        }
      })
    },
    async remove(index) {
      this.curIndex = index
      let res = await this.$confirm('此操作将永久删除该分类, 是否继续?', '提示', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning'
      })
      if (res == 'confirm') {
        this.loading = true
        if (this.cptData.data[index].id) {
          this.$api.mall
            .mall('dhnDelete', this.cptData.data[index].id, {
              headers: {
                ['Bm-Area-Id']: this.areaId
              }
            })
            .then(res => {
              this.cptData.data.splice(index, 1)
              this.openCurIndex(index)
            })
            .catch(err => {
              this.$message.error(err.msg || '操作失败')
            })
            .finally(() => {
              setTimeout(() => {
                this.loading = false
              }, 200)
            })
        } else {
          this.cptData.data.splice(index, 1)
          this.openCurIndex(index)
          setTimeout(() => {
            this.loading = false
          }, 200)
        }
      } else {
        this.$message({
          type: 'info',
          message: '已取消删除'
        })
      }
    },
    handleChangeState() {},
    clRadioChange(i) {
      this.cptData.data[i].linkId = ''
    },
    classifyChange(index) {
      // this.cptData.data[index].name=
    }
  }
}
</script>

<style lang="less" scoped>
@import './com.less';
.currentselect {
  border: red 1px solid !important;
}
.acActlve {
  div {
    &:nth-child(1) {
      font-size: 18px !important;
      font-weight: bold !important;
      color: @color-primary !important;
    }
    &:nth-child(2) {
      background-color: @color-primary-select !important;
    }
  }
}
.area-classify {
  height: 45px;
  background: rgba(231, 20, 26, 0);
  border: 1px solid transparent;
  .radius(10px);
  .remindText {
    text-align: center;
    padding: 10px;
    color: #999999;
  }
  & > div {
    max-width: 100%;
    overflow-x: auto;
    white-space: nowrap;
    &::-webkit-scrollbar {
      display: none !important;
    }

    .ac-item {
      display: inline-block;
      margin: 0 10px;
      padding: 6px 0;
      cursor: pointer;
      div {
        &:nth-child(1) {
          font-size: 15px;
          font-weight: 400;
          color: @color-assist;
          padding: 2px 0;
        }
        &:nth-child(2) {
          height: 3px;
          background-color: transparent;
          border-radius: 2px;
        }
      }
    }
    .ac-edit {
      padding: 25px;
      background-color: @color-background;
      .c-e-header {
        margin-bottom: 20px;
        font-weight: 700;
      }
    }
  }
}
</style>
