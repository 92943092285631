<template>
  <div class="carousel">
    <template v-if="!canEdit">
      <template v-if="cptData.data.length">
        <el-carousel trigger="click" height="150px" style="pointer-events: none">
          <template v-for="(item, i) in cptData.data">
            <el-carousel-item :key="i" v-if="item.hideStatus == 0">
              <h3 class="small"><img :src="item.bannerImageUrl" alt="" class="banner_img" /></h3>
            </el-carousel-item>
          </template>
        </el-carousel>
      </template>
      <template v-else>
        <p class="remindText">轮播图组件</p>
      </template>
    </template>
    <template v-else>
      <div class="carousel-edit">
        <p class="c-e-header">顶部banner</p>
        <el-button type="primary" class="add_btn" @click="addCarousel">添加轮播图</el-button>
        <div>
          <el-collapse v-model="activeName" :accordion="true">
            <drag v-for="(classifyData, i) in cptData.data" :key="i" :index="i" :data="cptData.data">
              <el-collapse-item :name="cptData.componentName + i" :key="'carousel' + i">
                <template slot="title">
                  <span style="margin-left: 10px; font-weight: 700">{{
                    i + 1 + (classifyData.bannerName ? '-' + classifyData.bannerName : '')
                  }}</span>
                  <span style="font-size: 12px">
                    {{ '（' + (classifyData.hideStatus == 0 ? '√' : '×') + '）' }}
                  </span>
                </template>
                <!-- :rules="rules" -->
                <el-form
                  ref="classifyRef"
                  :model="classifyData"
                  :rules="rules"
                  class="vendorFrom"
                  label-position="right"
                  label-width="100px"
                >
                  <el-form-item label="状态">
                    <el-switch
                      v-model="classifyData.hideStatus"
                      :active-value="0"
                      :inactive-value="1"
                      @change="handleChangeState"
                    ></el-switch>
                  </el-form-item>
                  <el-form-item label="banner" prop="bannerImageUrl">
                    <bm-upload
                      v-model="classifyData.bannerImageUrl"
                      type="system"
                      @change="handleCheckImg(i)"
                    ></bm-upload>
                    <span class="assist">建议尺寸750*364</span>
                  </el-form-item>

                  <el-form-item label="banner名称" prop="bannerName">
                    <el-input v-model="classifyData.bannerName" :maxlength="50" placeholder="输入自定义名称"></el-input>
                  </el-form-item>
                  <el-form-item label="跳转到">
                    <el-radio-group v-model="classifyData.bannerType" @change="handleTypeChange(i)">
                      <el-radio :label="0">商品页面</el-radio>
                      <el-radio :label="1">其他页面</el-radio>
                    </el-radio-group>
                  </el-form-item>
                  <el-form-item label="跳转到商品" v-if="classifyData.bannerType == 0" prop="productId">
                    <el-input
                      v-model="classifyData.productId"
                      @blur="validProductId(i)"
                      placeholder="输入商品id"
                    ></el-input>
                  </el-form-item>
                  <el-form-item label="跳转到页面" v-if="classifyData.bannerType == 1" prop="linkUrl">
                    <el-input v-model="classifyData.linkUrl" placeholder="输入页面路径"></el-input>
                  </el-form-item>
                  <el-form-item label="显示时间" prop="dateRange">
                    <el-date-picker
                      v-model="allowTimes[i]"
                      type="datetimerange"
                      :default-time="['00:00:00', '23:59:59']"
                      value-format="timestamp"
                      range-separator="至"
                      start-placeholder="开始日期"
                      end-placeholder="结束日期"
                      @change="setAllowTIme(i)"
                    />
                  </el-form-item>
                  <el-form-item v-loading="loading">
                    <!-- <el-button type="primary" @click="list">列表</el-button> -->
                    <el-button type="primary" @click="save(i)">保存</el-button>
                    <el-button type="danger" @click="remove(i)">删除</el-button>
                  </el-form-item>
                </el-form>
              </el-collapse-item>
            </drag>
          </el-collapse>
        </div>
      </div>
    </template>
  </div>
</template>

<script>
import drag from '@/component/adorn/__com__/drag'
export default {
  name: 'carousel',
  components: { drag },
  props: {
    cptData: {
      type: Object,
      default: {
        componentName: 'carousel',
        selectStatus: false,
        data: []
      }
    },
    canEdit: {
      type: Boolean,
      default: false,
      comment: '是否编辑状态， true是，会在装修页面右侧调用'
    }
  },
  data() {
    return {
      loading: false,
      activeName: this.cptData.componentName + '0',
      rules: {
        bannerImageUrl: [{ required: true, message: '请上传banner图片', trigger: 'change' }],
        bannerName: [{ required: true, message: '请输入banner名称', trigger: 'blur' }],
        productId: [
          {
            required: true,
            trigger: 'blur',
            validator: async (rule, value, callback) => {
              if (!value) {
                return callback('输入商品id')
              }
              try {
                let res = await this.$api.mall.mall('validProductId', value, {
                  headers: {
                    ['Bm-Area-Id']: this.areaId
                  }
                })
                callback()
              } catch (err) {
                return callback('该地区没有此商品或者商品已经下架')
              }
            }
          }
        ],
        linkUrl: [
          {
            required: true,
            trigger: 'change',
            message: '输入页面url'
          }
        ],
        dateRange: [
          {
            required: true,
            trigger: 'blur',
            validator: (rule, value, callback) => {
              let curValue = this.allowTimes[this.curIndex]
              if (!curValue) {
                return callback(new Error('选择显示时间'))
              } else {
                callback()
              }
            }
          }
        ]
      },
      intStartTime: Date.parse(new Date()) + 10 * 60 * 1000,
      intEndTime: Date.parse(new Date()) + 24 * 60 * 60 * 1000,
      model: {
        bannerImageUrl: '',
        bannerName: '',
        bannerSort: 0,
        bannerType: 0,
        cityId: 0,
        cityName: '',
        hideStatus: 0,
        id: 0,
        linkUrl: '',
        productId: '',
        showEndTime: this.intEndTime,
        showStartTime: this.intStartTime
      },
      allowTimes: [],
      areaId: this.$store.getters['malls/getCurrentEditAreaId'],
      curIndex: 0
    }
  },
  created() {
    if (!this.cptData.data.length) {
      this.cptData.data.push({ ...this.model })
    }
    for (let d of this.cptData.data) {
      this.allowTimes.push([d.showStartTime, d.showEndTime])
    }
  },
  methods: {
    setAllowTIme(index) {
      let currentEditData = this.cptData.data[index]
      currentEditData.showStartTime = this.allowTimes[index][0]
      currentEditData.showEndTime = this.allowTimes[index][1]
    },
    openCurIndex(index) {
      let len = this.cptData.data.length
      if (index > len - 1) {
        this.curIndex = len - 1
      } else {
        this.curIndex = index
      }
      this.activeName = this.cptData.componentName + this.curIndex
    },
    handleCheckImg(i) {
      this.$refs['classifyRef'][i].validateField('bannerImageUrl')
    },
    // 清除提示
    handleTypeChange(index) {
      let productRef = this.$refs['productRef' + index]
      let linkRef = this.$refs['linkRef' + index]
      productRef && productRef.length && (productRef[0].validateState = '')
      linkRef && linkRef.length && (linkRef[0].validateState = '')
    },
    addCarousel() {
      this.cptData.data.push({ ...this.model })
      this.allowTimes.push([this.intStartTime, this.intEndTime])
      this.openCurIndex(this.cptData.data.length - 1)
    },
    validProductId(index) {
      this.$api.mall.mall('validProductId', this.cptData.data[index].productId, {
        headers: {
          ['Bm-Area-Id']: this.areaId
        }
      })
    },
    async remove(index) {
      let res = await this.$confirm('此操作将永久删除该图, 是否继续?', '提示', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning'
      })
      if (res == 'confirm') {
        this.loading = true
        if (!this.cptData.data[index].id) {
          this.cptData.data.splice(index, 1)
          this.openCurIndex(index)
        } else {
          this.$api.mall
            .mall('deleteCarousel', this.cptData.data[index].id, {
              headers: {
                ['Bm-Area-Id']: this.areaId
              }
            })
            .then(res => {
              this.cptData.data.splice(index, 1)
              this.openCurIndex(index)
              this.$message.success('删除成功')
            })
            .catch(err => {
              this.$message.error(err.msg || '操作失败')
            })
            .finally(() => {
              setTimeout(() => {
                this.loading = false
              }, 200)
            })
        }
      } else {
        this.$message({
          type: 'info',
          message: '已取消删除'
        })
      }
    },

    save(index) {
      this.$refs['classifyRef'][index].validate(valid => {
        // console.log(valid)
        if (valid) {
          this.loading = true
          const cloneData = JSON.parse(JSON.stringify(this.cptData.data)).map(item => {
            if (item.bannerType === 0) {
              item.linkUrl = ''
            } else {
              item.productId = 0
            }
            return item
          })
          this.$api.mall
            .mall('postCarousel', cloneData, {
              headers: {
                ['Bm-Area-Id']: this.areaId
              }
            })
            .then(res => {
              this.cptData.data = [...res]
              this.$message.success('保存成功')
            })
            .catch(err => {
              this.$message.error(err.msg || '操作失败')
            })
            .finally(() => {
              setTimeout(() => {
                this.loading = false
              }, 200)
            })
        }
      })
    },
    handleChangeState() {},
    handleChangeState() {},
    selectComponent() {
      this.$emit('selectComponent')
    }
  }
}
</script>
<style lang="less" scoped>
@import './com.less';
.carousel {
  border: 1px solid transparent;
  padding: 3px 5px;
  .radius(6px);

  &:hover {
    cursor: pointer;
    box-shadow: 0 0 2px @color-border;
  }
  .remindText {
    min-height: 150px;
    line-height: 140px;
    text-align: center;
    color: #999999;
    border: 1px dotted #999999;
  }
  .carousel-edit {
    padding: 25px;
    background-color: @color-background;
    .c-e-header {
      margin-bottom: 20px;
      font-weight: 700;
    }
  }
  .banner_img {
    display: block;
    width: 100%;
    height: 100%;
    object-fit: contain;
  }
}
</style>
