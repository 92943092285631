<template>
  <div class="popoverContainer">
    <el-popover class="popoverContent" title="提示" placement="top-start" width="200" trigger="hover">
      <slot></slot>
      <i class="questionIcon el-icon-question" slot="reference"></i>
    </el-popover>
  </div>
</template>

<script>
export default {
  mounted() {}
}
</script>

<style lang="less" scoped>
.popoverContent {
  display: inline-block;
  margin: 0 10px;
}
.popoverContainer {
  display: inline-block;
}
.questionIcon {
  font-size: 20px;
  color: #2980b9;
  vertical-align: middle;
}
</style>
