import http from '@/plugin/axios'
const userService = 'boom-center-user-service/'
const adminService = 'boom-center-admin-service/'
let httpUser = {
  // 用户列表
  userList(params) {
    return http.get(userService + 'sysAdmin/members', params)
  },
  // 用户拉黑
  userPullBlack(params, setting) {
    return http.put(userService + 'sysAdmin/members/pullBlack', params, setting)
  }, // 用户取消拉黑
  userCancelBlock(params, setting) {
    return http.put(userService + 'sysAdmin/members/thaw', params, setting)
  },
  // 用户详情
  userDetail(params) {
    return http.get(userService + 'sysAdmin/members/' + params)
  },
  // 用户详情根据用户ID查询评论
  userReview(params, id) {
    return http.get(adminService + 'sysAdmin/userReview/member/review/' + id, params)
  },
  // 新增商务合作
  addBusinessCooperation(params, setting) {
    return http.post(adminService + 'sysAdmin/businessCooperation', params, setting)
  },
  // 编辑商务合作
  editBusinessCooperation(params, setting) {
    return http.put(adminService + 'sysAdmin/businessCooperation', params, setting)
  },
  // 删除商务合作
  delBusinessCooperation(id, setting) {
    return http.delete(adminService + `sysAdmin/businessCooperation/${id}`, {}, setting)
  },
  // 商务合作列表
  businessCooperationList(params) {
    return http.get(adminService + 'sysAdmin/businessCooperation/findPage', params)
  },
  // 商务合作地区
  listForBusinessCooperation(params) {
    return http.get(adminService + 'sysAdmin/area-settings/listForBusinessCooperation', params)
  }
}
export function user(tag, params, setting) {
  return httpUser[tag](params, setting)
}
