<template>
  <div :class="{ fullscreen: fullscreen }" class="tinymce-container" :style="{ width: containerWidth }">
    <textarea :id="tinymceId" class="tinymce-textarea" />
    <div class="editor-custom-btn-container">
      <el-button v-if="xiumi" @click="xiumiDialog = true" style="margin-right: 10px" type="success "
        >秀米编辑器</el-button
      >
      <editorImage color="#1890ff" class="editor-upload-btn" @successCBK="imageSuccessCBK" />
      <el-button v-if="xiumi" @click="handleTransfer" style="margin-left: 10px" type="warning">一键转存</el-button>
    </div>
    <el-dialog :visible.sync="xiumiDialog" @close="xiumiDialog = false" fullscreen append-to-body>
      <iframe
        src="/xiumi-ue-dialog-v5.html"
        frameborder="0"
        style="width: 100%; height: calc(100vh - 100px)"
        ref="xiumi"
      ></iframe>
    </el-dialog>
    <el-dialog
      :visible="transferDialog.show"
      :title="`转存图片中（${transferDialog.current}/${transferDialog.total}）`"
      @close="transferDialog = { show: false, total: 0, current: 0, error: 0 }"
    >
      <el-progress
        :text-inside="true"
        :stroke-width="24"
        :percentage="(transferDialog.current / transferDialog.total).toFixed(2) * 100 || 0"
        status="success"
      ></el-progress>
      <div class="tip" style="margin-top: 30px">共 {{ transferDialog.total }} 个外链图片</div>
      <div class="tip" v-if="transferDialog.total - transferDialog.error">
        成功 <span style="color: #4caf50">{{ transferDialog.total - transferDialog.error }}</span> 个
      </div>
      <div class="tip" v-if="transferDialog.error">
        失败 <span style="color: #ff5252">{{ transferDialog.error }}</span> 个
      </div>
    </el-dialog>
  </div>
</template>

<script>
/**
 * docs:
 * https://panjiachen.github.io/vue-element-admin-site/feature/component/rich-editor.html#tinymce
 */
import editorImage from './Tinymce/components/EditorImage'
import plugins from './Tinymce/plugins'
import toolbar from './Tinymce/toolbar'
import load from './Tinymce/dynamicLoadScript'
import Axios from 'axios'
// import tinymceCDN from './tinymce'
// why use this cdn, detail see https://github.com/PanJiaChen/tinymce-all-in-one
const tinymceCDN = 'https://cdn.jsdelivr.net/npm/tinymce-all-in-one@4.9.5/tinymce.min.js'

export default {
  name: 'Tinymce',
  components: {
    editorImage
  },
  props: {
    id: {
      type: String,
      default: function () {
        return 'vue-tinymce-' + +new Date() + ((Math.random() * 1000).toFixed(0) + '')
      }
    },
    value: {
      type: String,
      default: ''
    },
    toolbar: {
      type: Array,
      required: false,
      default() {
        return []
      }
    },
    menubar: {
      type: String,
      default: 'file edit insert view format table'
    },
    height: {
      type: [Number, String],
      required: false,
      default: 360
    },
    width: {
      type: [Number, String],
      required: false,
      default: 'auto'
    },
    xiumi: {
      type: Boolean,
      default: false
    }
  },
  data() {
    return {
      hasChange: false,
      hasInit: false,
      tinymceId: this.id,
      fullscreen: false,
      languageTypeList: {
        zh: 'zh_CN',
        es: 'es_MX',
        ja: 'ja'
      },

      xiumiDialog: false,
      transferDialog: {
        show: false,
        total: 0,
        current: 0,
        error: 0
      }
    }
  },
  computed: {
    containerWidth() {
      const width = this.width
      if (/^[\d]+(\.[\d]+)?$/.test(width)) {
        // matches `100`, `'100'`
        return `${width}px`
      }
      return width
    }
  },
  watch: {
    value(val) {
      // 原判断条件，      if (!this.hasChange && this.hasInit)，经过调查，如果使用这个判断，那么选择模板将不会更新数据。现在判断条件f (this.hasInit)
      if (this.hasInit) {
        this.$nextTick(() => window.tinymce.get(this.tinymceId).setContent(val || ''))
      }
    }
  },
  mounted() {
    window.addEventListener('message', this.onMessageEvent)
    this.init()
  },
  activated() {
    if (window.tinymce) {
      this.initTinymce()
    }
  },
  deactivated() {
    this.destroyTinymce()
  },
  destroyed() {
    this.destroyTinymce()
    window.removeEventListener('message', this.onMessageEvent)
  },
  methods: {
    onMessageEvent(e) {
      if (e.data.type === 'xiumi') {
        if (this.xiumi) {
          this.xiumiDialog = false
          this.$emit('input', e.data.data)
          // this.handleTransfer(e.data.data)
        }
      }
    },
    handleTransfer(data) {
      this.transfer(this.value).then(res => {
        this.$emit('input', res)
      })
    },
    transfer(source) {
      //转存图片
      return new Promise(async (resolve, reject) => {
        let srcReg = /src=[\'\"]?([^\'\"]*)[\'\"]?/i
        let imgReg = /<img.*?(?:>|\/>)/gi
        let arr = source.match(imgReg)
        if (!arr || !arr.length) {
          reject()
          return
        }
        let srcArr = []
        console.log(arr)
        arr.map(item => {
          let src = item.match(srcReg)[1]
          if (!src.includes('file.cnd.tanchi.shop') && !src.includes('bm-mall-file.oss-cn-shenzhen')) {
            srcArr.push({ origin: src })
          }
        })
        console.log(srcArr)
        if (!srcArr || !srcArr.length) {
          reject()
          return
        }
        this.transferDialog.show = true
        this.transferDialog.total = srcArr.length
        let downloadQueue = []
        console.log(srcArr)
        srcArr.map(item => {
          console.log(item)
          downloadQueue.push(
            new Promise(resolve => {
              this.$http
                .post('/boom-center-file-service/sysAdmin/uploadOss/uploading/change', [item.origin])
                .then(res => {
                  this.transferDialog.current = this.transferDialog.current + 1
                  resolve({
                    origin: item.origin,
                    new: res
                  })
                })
            })
          )
        })

        // srcArr.map((item, index) => {

        //   // downloadQueue.push(
        //   //   new Promise(resolve => {
        //   //     Axios({
        //   //       method: 'get',

        //   //       // url: 'https://images.weserv.nl/?url=' + item.origin,
        //   //       url: item.origin,
        //   //       responseType: 'blob'
        //   //     })
        //   //       .then(response => {
        //   //         console.log(response)
        //   //         let res = new window.File([response.data], 'response.data.name', { type: response.data.type })
        //   //         res.uid = new Date().getTime()
        //   //         console.log(res)
        //   //         // const a = document.createElement('a')
        //   //         // a.href = URL.createObjectURL(response.data)
        //   //         // a.download = 'fileName' // 这里填保存成的文件名
        //   //         // a.click()
        //   //         // let res = response.data
        //   //         this.$store
        //   //           .dispatch('upload/image', {
        //   //             file: res,
        //   //             type: 'commodity',
        //   //             reduceResolution: true
        //   //           })
        //   //           .then(res => {
        //   //             this.transferDialog.current = this.transferDialog.current + 1
        //   //             resolve({ origin: item.origin, new: res })
        //   //           })
        //   //           .catch(err => {
        //   //             this.transferDialog.error = this.transferDialog.error + 1
        //   //             this.$message.error('图片转存失败，步骤2')
        //   //             resolve({ origin: item.origin, new: item.origin })
        //   //           })
        //   //       })

        //   //       .catch(() => {
        //   //         this.transferDialog.error = this.transferDialog.error + 1
        //   //         this.$message.error('图片转存失败，步骤1')
        //   //         resolve({ origin: item.origin, new: item.origin })
        //   //       })
        //   //   })
        //   )
        // })
        Promise.all(downloadQueue)
          .then(res => {
            res.map(item => {
              let str = item.origin.replace(/\?/, '\\?')
              source = source.replace(new RegExp(str, 'g'), item.new)
            })
            this.$message({
              message: '图片转存成功',
              type: 'success'
            })
            resolve(source)
            this.transferDialog.show = false
          })
          .catch(() => {
            this.$message.error('图片转存失败，建议重试')
            resolve(source)
          })
      })
    },
    init() {
      // dynamic load tinymce from cdn

      load(tinymceCDN, err => {
        if (err) {
          this.$message.error(err.message)
          return
        }
        this.initTinymce()
      })
    },
    initTinymce() {
      const _this = this
      window.tinymce.init({
        valid_elements: '*[*]',
        cleanup: false,
        paste_auto_cleanup_on_paste: false,
        paste_remove_styles: false,
        paste_remove_styles_if_webkit: false,
        paste_strip_class_attributes: false,
        font_formats: "微软雅黑='微软雅黑';宋体='宋体';黑体='黑体';仿宋='仿宋';楷体='楷体';隶书='隶书';幼圆='幼圆';",
        fontsize_formats: '8pt 10pt 12pt 14pt 18pt 24pt 36pt', // 第二步
        selector: `#${this.tinymceId}`,
        language: this.languageTypeList['zh'],
        height: this.height,
        body_class: 'panel-body ',
        object_resizing: true,
        toolbar:
          'undo redo | bold italic underline strikethrough | fontselect fontsizeselect formatselect | alignleft aligncenter alignright alignjustify | outdent indent |  numlist bullist checklist | forecolor backcolor casechange permanentpen formatpainter removeformat | pagebreak | charmap emoticons | fullscreen  preview save print | insertfile image media pageembed template link anchor codesample | a11ycheck ltr rtl | showcomments addcomment ',
        menubar: this.menubar,
        plugins: plugins,
        end_container_on_empty_block: true,
        powerpaste_word_import: 'clean',
        paste_data_images: true,
        code_dialog_height: 450,
        code_dialog_width: 1000,
        advlist_bullet_styles: 'square',
        advlist_number_styles: 'default',
        imagetools_cors_hosts: ['www.tinymce.com', 'codepen.io'],
        default_link_target: '_blank',
        link_title: false,
        nonbreaking_force_tab: true, // inserting nonbreaking space &nbsp; need Nonbreaking Space Plugin
        init_instance_callback: editor => {
          if (_this.value) {
            editor.setContent(_this.value)
          }
          _this.hasInit = true
          // editor.on('NodeChange Change KeyUp SetContent', () => {
          //   this.hasChange = true
          //   let content = editor.getContent()
          // })
          editor.on('blur', () => {
            let content = editor.getContent()
            if (!this.xiumi) {
              let srcReg = /src=[\'\"]?([^\'\"]*)[\'\"]?/i
              let imgReg = /<img.*?(?:>|\/>)/gi
              let arr = content.match(imgReg)
              if (arr && arr.length) {
                arr.map(item => {
                  let src = item.match(srcReg)[1]
                  content = content.replace(item, `<img src='${src}' style='max-width:100%'/>`)
                })
              }
            }

            this.$emit('input', content)
          })
        },
        setup(editor) {
          editor.on('FullscreenStateChanged', e => {
            _this.fullscreen = e.state
          })
        },
        // it will try to keep these URLs intact
        // https://www.tiny.cloud/docs-3x/reference/configuration/Configuration3x@convert_urls/
        // https://stackoverflow.com/questions/5196205/disable-tinymce-absolute-to-relative-url-conversions
        convert_urls: false,
        images_upload_handler: (blobInfo, succFun, failFun) => {
          let file = blobInfo.blob()
          const reader = new FileReader()
          reader.readAsDataURL(file)
          reader.onload = e => {
            let blob = this.dataURLtoBlob(e.currentTarget.result)
            this.$store
              .dispatch('upload/image', { file: blob, type: 'commodity' })
              .then(res => {
                console.log(res)
                succFun(res)
              })
              .catch(() => {
                failFun('上传失败')
              })
          }
        }
      })
    },
    dataURLtoBlob(dataurl) {
      var arr = dataurl.split(','),
        mime = arr[0].match(/:(.*?);/)[1],
        bstr = atob(arr[1]),
        n = bstr.length,
        u8arr = new Uint8Array(n)
      while (n--) {
        u8arr[n] = bstr.charCodeAt(n)
      }
      return new Blob([u8arr], { type: mime })
    },
    destroyTinymce() {
      if (window.tinymce) {
        const tinymce = window.tinymce.get(this.tinymceId)
        if (tinymce) {
          if (this.fullscreen) {
            tinymce.execCommand('mceFullScreen')
          }
          tinymce.destroy()
        }
      }
    },
    setContent(value) {
      window.tinymce.get(this.tinymceId).setContent(value)
    },
    getContent() {
      window.tinymce.get(this.tinymceId).getContent()
    },
    imageSuccessCBK(arr) {
      console.log(arr)
      arr.forEach(v => window.tinymce.get(this.tinymceId).insertContent(`<img class="wscnph" src="${v.url}" >`))
    }
  }
}
</script>

<style lang="less" scoped>
.tinymce-container {
  position: relative;
  line-height: normal;
}

.tinymce-container {
  ::v-deep {
    .mce-fullscreen {
      z-index: 10000;
    }
  }
}

.tinymce-textarea {
  visibility: hidden;
  z-index: -1;
}

.editor-custom-btn-container {
  position: absolute;
  right: 4px;
  top: 4px;
  /*z-index: 2005;*/
}

.fullscreen .editor-custom-btn-container {
  z-index: 10000;
  position: fixed;
}

.editor-upload-btn {
  display: inline-block;
}
/deep/.wscnph {
  max-width: 100%;
}
/deep/.el-dialog__wrapper {
  z-index: 20001 !important;
}
.tip {
  text-align: center;
  margin: 3px 0;
}
</style>
