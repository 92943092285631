<template>
  <div class="discover-block">
    <div>
      <template v-if="!canEdit">
        <div class="db-header-tag">
          <template v-if="!cptData.data.length">
            <p class="reminder-message">请在右侧编辑组件</p>
          </template>
          <template v-else>
            <template v-for="(d, i) in cptData.data">
              <span :key="'discover-block' + i" v-if="d.hideStatus == 0">{{ d.name }}</span>
            </template>
          </template>
        </div>
        <div></div>
      </template>
      <template v-else>
        <div class="db-edit">
          <p class="c-e-header">发现板块组件</p>
          <el-button type="primary" class="add_btn" @click="addActiveItem">添加组件</el-button>
          <div>
            <el-collapse v-model="activeName" :accordion="true">
              <template v-for="(classifyData, i) in cptData.data">
                <el-collapse-item :key="'classify' + i" :name="cptData.componentName + i">
                  <template slot="title">
                    <span style="margin-left: 10px; font-weight: 700"
                      >{{ i + 1 + (classifyData.name ? '-' + classifyData.name : '') }}
                    </span>
                    <span style="font-size: 12px">
                      {{ '（' + (classifyData.hideStatus == 0 ? '√' : '×') + '）' }}
                    </span>
                  </template>
                  <!-- :rules="rules" -->
                  <el-form
                    ref="form"
                    :model="classifyData"
                    :rules="rules"
                    class="vendorFrom"
                    label-position="right"
                    label-width="100px"
                  >
                    <el-form-item label="状态" prop="hideStatus">
                      <el-switch
                        v-model="classifyData.hideStatus"
                        :active-value="0"
                        :inactive-value="1"
                        @change="handleChangeState"
                      ></el-switch>
                    </el-form-item>
                    <el-form-item label="标题名称" prop="name">
                      <el-input
                        v-model="classifyData.name"
                        controls-position="right"
                        :min="0"
                        :max="99"
                        placeholder="自定义标题"
                      ></el-input
                    ></el-form-item>
                    <el-form-item label="跳转路径" prop="linkUrl">
                      <el-input
                        v-model="classifyData.linkUrl"
                        controls-position="right"
                        :min="0"
                        :max="99"
                        placeholder="输入跳转路径"
                      ></el-input
                    ></el-form-item>
                    <el-form-item v-loading="loading">
                      <el-button type="primary" @click="save(i)">保存</el-button>
                      <el-button type="danger" @click="remove(i)">删除</el-button>
                    </el-form-item>
                  </el-form>
                </el-collapse-item>
              </template>
            </el-collapse>
          </div>
        </div>
      </template>
    </div>
  </div>
</template>
<script>
export default {
  name: 'discover-block',
  props: {
    cptData: {
      type: Object,
      default: {
        componentName: 'discover',
        selectStatus: false,
        data: [
          {
            hideStatus: 0
          }
        ]
      },
      require: true
    },
    canEdit: {
      type: Boolean,
      default: false,
      require: false,
      comment: '是否编辑状态， true是，会在装修页面右侧调用'
    }
  },
  data() {
    return {
      loading: false,
      activeName: this.cptData.componentName + '0',
      rules: {
        name: [{ required: true, message: '名字不能为空', trigger: 'blur' }],
        linkUrl: [{ required: true, message: '跳转链接不能为空', trigger: 'change' }]
      },
      model: {
        areaId: '',
        hideStatus: 0,
        id: '',
        linkUrl: '',
        name: '',
        sort: 0
      },
      areaId: this.$store.getters['malls/getCurrentEditAreaId'],
      curIndex: 0
    }
  },
  created() {},
  methods: {
    openCurIndex(index) {
      let len = this.cptData.data.length
      if (index > len - 1) {
        this.curIndex = len - 1
      } else {
        this.curIndex = index
      }
      this.activeName = this.cptData.componentName + this.curIndex
    },
    activityChange(index) {
      for (let v of this.activityList) {
        if (v.id == this.cptData.data[index].productGroupId) {
          this.cptData.data[index].waterfallTitle = v.activityName
          break
        }
      }
    },
    addActiveItem() {
      this.cptData.data.push({ ...this.model })
      this.openCurIndex(this.cptData.data.length - 1)
    },
    save(index) {
      this.openCurIndex(index)
      this.$refs['form'][index].validate(valid => {
        if (valid) {
          this.loading = true
          this.$api.mall
            .mall('postDiscovery', this.cptData.data, {
              headers: {
                ['Bm-Area-Id']: this.areaId
              }
            })
            .then(res => {
              this.cptData.data = [...res]
              this.$message.success('保存成功')
            })
            .catch(err => {
              this.$message.error(err)
            })
            .finally(() => {
              setTimeout(() => {
                this.loading = false
              }, 200)
            })
        }
      })
    },
    async remove(index) {
      let res = await this.$confirm('此操作将永久删除该图, 是否继续?', '提示', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning'
      })
      if (res == 'confirm') {
        this.loading = true

        if (this.cptData.data[index].id) {
          this.$api.mall
            .mall('deleteDiscovery', this.cptData.data[index].id, {
              headers: {
                ['Bm-Area-Id']: this.areaId
              }
            })
            .then(res => {
              this.cptData.data.splice(index, 1)
              this.openCurIndex(index)
              this.$message.success('删除成功')
            })
            .catch(err => {
              this.$message.error(err.msg || '操作失败')
            })
            .finally(() => {
              setTimeout(() => {
                this.loading = false
              }, 200)
            })
        } else {
          this.cptData.data.splice(index, 1)
          this.openCurIndex(index)
        }
      } else {
        this.$message({
          type: 'info',
          message: '已取消删除'
        })
      }
    },
    handleChangeState() {}
  }
}
</script>
<style lang='less' scoped>
@import './com.less';
.discover-block {
  .db-header-tag {
    border: 1px solid @color-border;
    min-height: 46px;

    .radius(6px);
    span {
      display: inline-block;
      padding: 10px;
      cursor: pointer;
    }
  }
  .db-edit {
    padding: 25px;
    background-color: @color-background;
    .c-e-header {
      margin-bottom: 20px;
      font-weight: 700;
    }
  }
}
</style>