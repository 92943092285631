import http from '@/plugin/axios'
const searchService = 'boom-center-search-service/'
const statisticsService = 'boom-center-statistics-service/'
const orderService = 'boom-center-order-service/'
let httpFinancial = {
  // 获取申请退款列表
  orderRefundList(params, config) {
    return http.get(searchService + 'sysAdmin/orderRefund/page?' + params, config)
  },
  // 批量退款审核
  batchRefund(params) {
    return http.post(orderService + 'sysAdmin/refund/audit/batch', params)
  },

  // 财务管理

  getFinance(params) {
    return http.get(statisticsService + 'sysAdmin/finance/get', params)
  },
  //商家提现列表

  getWithdrawalList(params, id) {
    return http.get(statisticsService + 'sysAdmin/finance/withdrawal/' + id, params)
  },
  // 提现明细页面

  getWithdrawalDetail(params, id) {
    return http.get(statisticsService + 'sysAdmin/finance/withdrawal/detail/' + id, params)
  },

  // 核销记录页面

  getCheckRecordList(params, id) {
    return http.get(statisticsService + 'sysAdmin/finance/checkRecord/' + id, params)
  },
  // 核销明细页面
  getCheckRecordDetail(params, id) {
    return http.get(statisticsService + 'sysAdmin/finance/checkRecord/detail/' + id, params)
  },
  // 普通支付总计
  getAggregate(params) {
    return http.get(statisticsService + 'sysAdmin/finance/general/aggregate', params)
  }
}
export function financial(tag, params, setting) {
  return httpFinancial[tag](params, setting)
}
