import ImageCompressor from 'compressorjs'
import SparkMD5 from 'spark-md5'
import $http from '@/plugin/axios'
import { Loading } from 'element-ui'
import { log } from '@/util/log'
import { sleep } from '@/plugin/func'

const $loading = Loading.service

const state = {}
const getters = {
  fileAccept: () => ({
    image: 'image/*',
    excel: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet'
  })
}
const mutations = {
  update: (state, { key, value }) => (state[key] = value)
}
const actions = {
  uploadWxFile({ dispatch }, file) {
    if (!file instanceof File) {
      return Promise.reject({ msg: '文件类型错误' })
    }
    let targetFile = file
    let fileHash
    let url = ''
    return dispatch('compressImage', { file })
      .then(newFile => {
        console.log(newFile)
        targetFile = newFile
        return dispatch('getFileHash', newFile)
      })
      .then(hash => {
        fileHash = hash
        return $http.get('/boom-center-file-service/uploadOss/disk/signature', {
          params: {
            type: 'wx',
            key: fileHash
          }
        })
      })
      .then(res => {
        if (res.url) {
          log('文件已存在', res.url)
          return res.url
        }
        let formData = new FormData()
        formData.append('key', res.dir + fileHash)
        formData.append('policy', res.policy)
        formData.append('OSSAccessKeyId', res.accessId)
        formData.append('success_action_status', '200')

        formData.append('signature', res.signature)
        formData.append('file', targetFile)
        url = res.dir + fileHash

        console.log(targetFile)
        return $http.post(res.host.replace('http:', window.location.protocol), formData).then(async () => {
          await sleep(0.5)
          return $http.get('/boom-center-file-service/uploadOss/uploadingFile', {
            params: {
              fileUrl: url
            }
          })
        })
      })
      .then((res = url) => {
        return $http
          .post('/boom-center-wechat-service/sysAdmin/ecommerce/imageUploadWX/url', {
            url: res
          })
          .then(wxCode => {
            return {
              wxCode,
              url: res
            }
          })
      })
  },
  /**
   * @description: 批量上传文件
   * @param {Context} store
   * @param {Number} maxCount 最大可选数量
   * @return {Promise} Array<String> 图片的url
   */
  pickImageList({ dispatch }, { maxCount = 9, type, isCompress = true }) {
    return new Promise((resolve, reject) => {
      let input = document.querySelector('#imageUploader')
      if (!input) {
        input = document.createElement('input')
        input.setAttribute('id', 'imageUploader')
        input.setAttribute('type', 'file')
        input.setAttribute('accept', 'image/*')
        input.setAttribute('multiple', true)
        input.style = 'width:0;height:0;display:none;'
        document.body.appendChild(input)
        input.addEventListener('input', e => {
          if (e.target.files.length > maxCount) {
            input.remove()
            reject({ msg: `不能选择超过${maxCount}个文件` })
          } else {
            const loading = $loading({
              lock: true,
              text: '上传中，请稍候',
              spinner: 'el-icon-loading',
              background: 'rgba(0, 0, 0, 0.7)'
            })
            Promise.all(
              [...e.target.files].map(file => {
                return dispatch('image', { file, type, isCompress })
              })
            )
              .then(res => {
                input.remove()
                log('批量上传成功', res)
                resolve(res)
              })
              .finally(() => {
                loading.close()
              })
          }
        })
      }
      input.click()
    })
  },
  async image({ dispatch }, { file, type = 'review', isCompress = true, isEncrypt = false, reduceResolution = false }) {
    console.log(reduceResolution)
    if (!file instanceof File) {
      return Promise.reject({ msg: '文件类型错误' })
    }
    let targetFile
    return dispatch('compressImage', { file, reduceResolution })
      .then(newFile => {
        targetFile = newFile
        return dispatch('getFileHash', newFile)
      })
      .then(fileHash => {
        let url = ''
        // 商家资质，直传后加水印
        if (type === 'businessLicense') {
          let formData = new FormData()
          formData.append('photoFile', targetFile)
          formData.append('fileName', fileHash)
          formData.append('md5Name', fileHash)
          formData.append('isEncrypt', isEncrypt ? 1 : 0)
          return $http.post('/boom-center-file-service/uploadOss/watermark/photo', formData).then(res => {
            if (isEncrypt) {
              return dispatch('getFileUrlWidthSignature', res)
            } else {
              return res
            }
          })
        } else {
          return $http
            .get('/boom-center-file-service/uploadOss/signature', {
              params: {
                type,
                key: fileHash
              }
            })
            .then(res => {
              if (res.url) {
                log('文件已存在', res.url)
                return res.url.replace('https://file.mall.tanchi.shop/', 'https://file.cdn.tanchi.shop/')
              } else {
                let formData = new FormData()
                formData.append('key', res.dir + fileHash)
                formData.append('policy', res.policy)
                formData.append('OSSAccessKeyId', res.accessId)
                formData.append('success_action_status', '200')
                formData.append('signature', res.signature)
                formData.append('file', targetFile)
                console.log(targetFile)
                url += 'https://file.cdn.tanchi.shop/' + res.dir + fileHash
                return $http.post(res.host.replace('http:', window.location.protocol), formData).then(() => {
                  if (targetFile.type === 'image/gif') {
                    url += '?x-oss-process=style/gif_600'
                  }
                  return url
                })
              }
            })
        }
      })
  },
  getFileHash({ }, file) {
    return new Promise((resolve, reject) => {
      const reader = new FileReader()
      reader.onload = e => {
        const hash = SparkMD5.hashBinary(e.target.result)
        resolve(hash)
      }
      reader.onerror = err => {
        reject(err)
      }
      reader.readAsBinaryString(file)
    })
  },
  compressImage({ }, { file, reduceResolution = false }) {

    console.log(file)
    console.log(file.type)
    if (file) {
      return new Promise((resolve, reject) => {
        if (file.type === 'image/gif') {
          resolve(file)
          console.log(333)
          return
        }

        let ImageCompressorConfig = {
          checkOrientation: true
        }
        if (reduceResolution) {
          ImageCompressorConfig.maxWidth = 1080
        }
        new ImageCompressor(file, {
          ...ImageCompressorConfig,
          success: res => {
            const beforeSize =
              file.size > 1048576
                ? Number(file.size / 1024 / 1024).toFixed(2) + 'Mb'
                : Number(file.size / 1024).toFixed(2) + 'Kb'
            const afterSize =
              res.size > 1048576
                ? Number(res.size / 1024 / 1024).toFixed(2) + 'Mb'
                : Number(res.size / 1024).toFixed(2) + 'Kb'
            log(`压缩前：${beforeSize}`)
            const r = Number(((file.size - res.size) / file.size) * 100).toFixed(2) + '%'
            log(`压缩后：${afterSize}，压缩率${r}`)
            resolve(res)
          },
          error: err => {
            log('图片压缩出现错误', err)
            reject(err)
          }
        })
      })
    }
  },
  getFileUrlWidthSignature({ }, url) {
    url = url.replace('http://bm-mall-disk.oss-cn-shenzhen.aliyuncs.com/', '')
    url = url.replace('https://disk.mall.tanchi.shop/', '')
    url = url.split('?')[0]
    return $http.get('/boom-center-file-service/uploadOss/uploadingFile', {
      params: {
        fileUrl: url
      }
    })
  }
}

export default {
  namespaced: true,
  state,
  getters,
  mutations,
  actions
}
