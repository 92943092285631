import Vue from 'vue'
import axios from './axios'
import './element'
import './component'
import './directive'
import './filter'
import './sentry'
import api from './api'
import { log } from '@/util/log'
import { sleep } from './func'
import { validatedForm } from '@/util/validate'

Vue.prototype.$api = api
Vue.prototype.$initRules = validatedForm
Vue.prototype.$http = axios
Vue.prototype.$log = log
Vue.prototype.$sleep = sleep
